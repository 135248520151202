import React, { Component } from "react";
import PropTypes from "prop-types";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { tableOptions } from "Utils/tableOptions";
import { Link } from "react-router-dom";
import Toolbar from "Utils/Toolbar/Toolbar";
import { RenderMoneda } from "Utils/renderField/renderTableField";
import { icons } from "icons";
import LoadMask from "Utils/LoadMask/LoadMask";
import { api } from "api";

// const imgDefault = require("../../../../../assets/img/icons/uploading-img.png")
function formatoAvatar(cell, row) {
    if (cell !== "") {
        return (
            <img
                className="imagen-sm"
                src={cell}
                alt=""
                style={{ width: "38px", height: "38px" }}
            />
        );
    } else {
        return (
            <img
                className="imagen-sm"
                src={icons.img_default}
                alt=""
                style={{ width: "38px", height: "38px" }}
            />
        );
    }
}

const formatoVistaImprimir = (id) => {
    return (
        <Link to={`/productos/imprimir_codigos/${id}/`}>
            {/* <img className="action-img" title="Imprimir códigos" src={icons.imprimir} alt="Imprimir códigos" style={{maxWidth: '22px'}}/> */}
            <em
                className="fa fa-ticket"
                title="Imprimir códigos"
                style={{ fontSize: "1.6em" }}
            />
        </Link>
    );
};

class Detalle extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool,
    };

    static defaultProps = {
        loader: false,
    };

    constructor(props) {
        super(props);
    }

    state = {
        familiaSeleccionada: null,
    };

    setFamilia = (familia) => {
        console.log("familia seleccionada: ", familia);
        this.props.setFamiliaSeleccionada(familia);
        this.setState({ familiaSeleccionada: familia }, () => {
            const { listarProductos, page } = this.props;
            listarProductos(page, familia && familia.id ? familia.id : null);
        });
    };

    componentDidMount() {
        this.props.listarProductos(this.props.page);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.familiaSeleccionada !== this.state.familiaSeleccionada) {
            const { listarProductos, page } = this.props;
            const familiaId =
                this.state.familiaSeleccionada &&
                this.state.familiaSeleccionada.id
                    ? this.state.familiaSeleccionada.id
                    : null;
            listarProductos(page, familiaId);
        }
    }

    isExpandableRow(row) {
        return true;
    }

    // function priceFormatter(cell, row) {
    //     console.log("DATOS FRACCIONES: ", cell)
    //     return (
    //         <div className="text-right">${cell}</div>
    //     )
    //     // return `<i class='glyphicon glyphicon-usd'></i> ${cell}`;
    // }

    expandComponent(row) {
        return (
            // pagination={data.sucursales.length > 10}
            <BootstrapTable data={row.fracciones} remote={false}>
                <TableHeaderColumn dataField="nombre" dataSort={false}>
                    Presentación
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="codigo_barras"
                    isKey={true}
                    dataSort={false}
                >
                    Código de barras
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="precio"
                    dataSort={false}
                    dataFormat={(cell) => <RenderMoneda monto={cell} />}
                >
                    Precio
                </TableHeaderColumn>
                {/* <TableHeaderColumn width="20%" dataField="id" dataAlign="center"
                                dataFormat={activeFormatter({ver: '/sucursal/detalle', editar: '/sucursal/editar', eliminar})}>Acciones</TableHeaderColumn> */}
            </BootstrapTable>
            // <BSSucursal data={row} />
        );
    }

    expandColumnComponent({ isExpandableRow, isExpanded }) {
        let content = "";

        if (isExpandableRow) {
            if (isExpanded) {
                return (
                    <div className="text-center">
                        <em className="fa fa-caret-down" />
                    </div>
                );
            } else {
                return (
                    <div className="text-center">
                        <em className="fa fa-caret-right" />
                    </div>
                );
            }
        } else {
            content = " ";
        }
        return <div> {content} </div>;
    }

    render() {
        // state
        const { data, loader, page, estado_descarga, conf_tipo_negocio } =
            this.props;

        // bind
        const { listarProductos, eliminar, sortChange, descargarListado } =
            this.props;

        // local
        const options = {
            ...tableOptions,
            noDataText: loader ? "Cargando..." : tableOptions.noDataText,
            onPageChange: listarProductos,
            onSortChange: sortChange,
            page,
        };

        const getFamilias = (search) => {
            return api
                .get("familia", { search })
                .then((data) => {
                    return { options: data.results };
                })
                .catch(() => ({ options: [] }));
        };

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="mt">
                        <Link
                            className="btn btn-primary mb-sm-10"
                            to="/productos/crear/"
                        >
                            <em className="fa fa-plus"></em> Agregar Producto
                        </Link>
                        &nbsp;&nbsp;
                        <button
                            className="btn btn-secondary mb-sm-10 align-self-center"
                            disabled={estado_descarga}
                            onClick={() => {
                                descargarListado();
                            }}
                        >
                            <em
                                className={`fa ${
                                    estado_descarga
                                        ? "fa-spinner fa-pulse"
                                        : "fa-download"
                                }`}
                            ></em>
                            &nbsp;Descargar Listado
                        </button>
                    </div>
                    <div className="grid-container">
                        <div className="grid-titulo">
                            <Toolbar
                                titulo={"Productos"}
                                familias={getFamilias()}
                                buscarFamilia={this.props.buscarFamilia}
                                search={this.props.search}
                                buscar={this.props.buscar}
                                searchFamilia={this.props.searchFamilia}
                                filtroFamilia={true}
                                getFamilias={getFamilias}
                                setFamilia={this.setFamilia}
                                familiaSeleccionada={
                                    this.state.familiaSeleccionada
                                }
                            />
                        </div>
                        <LoadMask loading={loader} dark blur>
                            <BootstrapTable
                                data={loader ? [] : data.results}
                                fetchInfo={{ dataTotalSize: data.count }}
                                options={options}
                                expandableRow={this.isExpandableRow}
                                expandComponent={this.expandComponent}
                                expandColumnOptions={{
                                    expandColumnVisible: true,
                                    expandColumnComponent:
                                        this.expandColumnComponent,
                                    columnWidth: 50,
                                }}
                                striped
                                hover
                                remote
                                pagination
                            >
                                <TableHeaderColumn
                                    dataField="img_cropped"
                                    width="10%"
                                    dataFormat={formatoAvatar}
                                    dataAlign="center"
                                />
                                {/* <TableHeaderColumn isKey dataSort dataField='id'>ID</TableHeaderColumn> */}
                                <TableHeaderColumn dataField="nombre" dataSort>
                                    Nombre
                                </TableHeaderColumn>
                                <TableHeaderColumn dataField="marca" dataSort>
                                    Marca
                                </TableHeaderColumn>
                                {conf_tipo_negocio === "Joyeria" && (
                                    <TableHeaderColumn
                                        dataField="kilataje"
                                        dataSort
                                    >
                                        Kilataje
                                    </TableHeaderColumn>
                                )}

                                {conf_tipo_negocio === "Joyeria" && (
                                    <TableHeaderColumn
                                        dataField="peso"
                                        dataSort
                                    >
                                        Peso
                                    </TableHeaderColumn>
                                )}
                                <TableHeaderColumn
                                    dataField="id"
                                    isKey
                                    dataAlign="center"
                                    width="20%"
                                    dataFormat={activeFormatter({
                                        ver: "/productos/detalle",
                                        editar: "/productos/editar",
                                        eliminar,
                                        adicional: formatoVistaImprimir,
                                    })}
                                >
                                    Acciones
                                </TableHeaderColumn>
                                {/* <TableHeaderColumn dataField='id' dataAlign="center" width="20%"
                            dataFormat={activeFormatter({ver:'/productos/detalle', editar: '/productos/editar/', eliminar: eliminar })}>Acciones</TableHeaderColumn> */}
                                {/* <TableHeaderColumn dataField='id' dataAlign="center" width="20%"
                            dataFormat={activeFormatter({ editar: '/productos/editar', eliminar: eliminar, ver:'/productos/detalle' })}>Acciones</TableHeaderColumn> */}
                            </BootstrapTable>
                        </LoadMask>
                    </div>
                </div>
            </div>
        );
    }
}

export default Detalle;

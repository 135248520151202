import { connect } from 'react-redux';
import { crear } from "../../../../redux/modules/familia/familia";
import Crear from './FamiliaCrear';


const ms2p = (state) => {
    return {
        ...state.familia,
    };
};

const md2p = { crear };

export default connect(ms2p, md2p)(Crear);
import React from "react";
import { RenderMoneda, RenderDateTime } from "Utils/renderField/renderTableField";

const BodegaListadoPDF = ({ data, conf_tipo_negocio }) => {
  return (
    <div className="card shadow-sm">
      {/* Encabezado del reporte */}
      <div className="card-header bg-primary text-white p-3">
        <h4 className="mb-0">Reporte de Inventario actual</h4>
        <small className="d-block">
          Fecha de generación: <RenderDateTime fecha={new Date()} />
        </small>
      </div>

      <div className="card-body p-3">
        {/* Tabla principal */}
        <div className="table-responsive">
          <table className="table table-bordered mb-4">
            <thead className="table-info">
              <tr>
                <th className="text-center" style={{ width: "5%" }}>ID</th>
                <th className="text-wrap" style={{ width: "25%" }}>Producto</th>
                {conf_tipo_negocio === "Joyeria" && (
                  <th className="text-nowrap" style={{ width: "8%" }}>Kilataje</th>
                )}
                {conf_tipo_negocio === "Joyeria" && (
                  <th className="text-nowrap" style={{ width: "8%" }}>Peso</th>
                )}
                <th className="text-nowrap text-center" style={{ width: "8%" }}>Inventario</th>
                <th className="text-nowrap text-center" style={{ width: "10%" }}>En Tránsito</th>
                <th className="text-nowrap text-center" style={{ width: "10%" }}>Por Recibir</th>
                <th className="text-nowrap text-center" style={{ width: "12%" }}>Costo</th>
                <th className="text-nowrap text-center" style={{ width: "12%" }}>Precio</th>
              </tr>
            </thead>
            
            <tbody>
              {(data && data.listado) ? data.listado.map((item) => (
                <tr key={item.id}>
                  <td className="text-center">{item.id}</td>
                  <td className="text-wrap">{(item.producto && item.producto.nombre) ? item.producto.nombre : "-"}</td>
                  
                  {conf_tipo_negocio === "Joyeria" && (
                    <td className="text-center">{(item.producto && item.producto.kilataje) ? item.producto.kilataje : "-"}</td>
                  )}
                  
                  {conf_tipo_negocio === "Joyeria" && (
                    <td className="text-center">{(item.producto && item.producto.peso) ? item.producto.peso : "-"}</td>
                  )}
                  
                  <td className="text-center">{item.total_inventario}</td>
                  <td className="text-center">{item.inventario_transito}</td>
                  <td className="text-center">{item.inventario_a_recibir}</td>
                  
                  <td className="text-right">
                    <RenderMoneda value={item.ultimo_costo} />
                  </td>
                  
                  <td className="text-right">
                    <RenderMoneda value={item.precio} />
                  </td>
                </tr>
              )) : null}
            </tbody>
          </table>
        </div>

        {/* Sección de totales */}
        <div className="row bg-light rounded p-3 mx-1">
          <div className="col-md-3 mb-2 mb-md-0">
            <div className="border p-2 text-center">
              <strong>Total Productos</strong><br/>
              {(data && data.totales && data.totales.total_productos) || 0}
            </div>
          </div>
          
          <div className="col-md-3 mb-2 mb-md-0">
            <div className="border p-2 text-center">
              <strong>Inventario Total</strong><br/>
              {(data && data.totales && data.totales.total_inventario) || 0}
            </div>
          </div>
          
          <div className="col-md-3 mb-2 mb-md-0">
            <div className="border p-2 text-center">
              <strong>Total Costo</strong><br/>
              <RenderMoneda value={(data && data.totales && data.totales.total_costo) || 0} />
            </div>
          </div>
          
          <div className="col-md-3">
            <div className="border p-2 text-center">
              <strong>Peso Total</strong><br/>
              {(data && data.totales && data.totales.total_peso) || 0} gr
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodegaListadoPDF;
import React from "react";
import { icons } from "icons";
import { connect } from "react-redux";
import FileUploader from "Utils/FileUploader/FileUploader";
import FileUploaderCrop from "Utils/FileUploader/FileUploaderCrop";
import Select from "react-select";

import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import {
    renderNumber,
    renderSelectField,
    renderField,
    renderCurrency,
} from "Utils/renderField/renderField";
import {
    renderCheckBoxEntrega,
    renderCheckBoxPago,
    renderCheckBoxTiposFactura,
} from "./CamposConfiguracion";

// VALIDACIONES CUSTOMS
const validate = (values, state) => {
    const errors = {};
    if (!values.tipo_punto_venta) errors.tipo_punto_venta = "Campo requerido";
    if (values.conf_cotizacion) {
        if (!values.plazo_cotizacion)
            errors.plazo_cotizacion = "Campo requerido";
    }

    //  Validacion para los metodos de entrega
    if (values.metodos_entrega) {
        if (values.metodos_entrega.length > 0) {
            const MetodosEntregaArrayErrors = [];
            values.metodos_entrega.forEach((metodo_entrega, index) => {
                const metodosEntregaErrors = {};
                if (metodo_entrega[Object.keys(metodo_entrega)[0]] == true) {
                    if (
                        metodo_entrega["precio"] != "0" &&
                        !metodo_entrega["precio"]
                    ) {
                        metodosEntregaErrors.precio = "Campo requerido";
                        MetodosEntregaArrayErrors[index] = metodosEntregaErrors;
                    }
                }
            });

            if (MetodosEntregaArrayErrors.length)
                errors.metodos_entrega = MetodosEntregaArrayErrors;
        }
    } else {
        errors.metodos_entrega = "Campo requerido";
    }

    if (values.metodos_pago.length > 0) {
        const MetodosPagoArrayErrors = [];
        values.metodos_pago.forEach((metodo_pago, index) => {
            if (
                "prefijo" in metodo_pago &&
                metodo_pago.prefijo === "DEP" &&
                metodo_pago[Object.keys(metodo_pago)[0]] === true
            ) {
                const metodosErrors = {};
                if (!metodo_pago.no_cuenta)
                    metodosErrors.no_cuenta = "Campo requerido";
                MetodosPagoArrayErrors[index] = metodosErrors;
                if (!metodo_pago.banco) metodosErrors.banco = "Campo requerido";
                MetodosPagoArrayErrors[index] = metodosErrors;
                if (!metodo_pago.nombre)
                    metodosErrors.nombre = "Campo requerido";
                MetodosPagoArrayErrors[index] = metodosErrors;
            }
        });
        if (MetodosPagoArrayErrors.length)
            errors.metodos_pago = MetodosPagoArrayErrors;
    }

    return errors;
};

const Form = (props) => {
    const {
        handleSubmit,
        imCortada,
        metodos_entrega,
        metodos_pago,
        logo,
        banner,
        ver_deposito,
        tienda,
        slug,
        conf_tienda_online,
        editando_logo,
        editando_banner,
        data,
        utiliza_fel,
        selectedMenuItem,
        conf_tipo_negocio,
    } = props;
    //  Valores del formulario
    const {
        conf_cotizacionValue,
        setCortada,
        onFileChange,
        bannerCortado,
        changeEdicionImagen,
        onLogoTicketChange,
    } = props;

    //  Tipo de cotizacion
    const tipos_punto_venta = [
        {
            id: "PVN",
            value: "PVN",
            label: "Normal",
        },
        {
            id: "PVMCE",
            value: "PVMCE",
            label: "Mostrador-Caja-Entrega",
        },
    ];

    const tipos_facturas = [
        {
            id: "FACT",
            value: "FACT",
            label: "Factura normal",
        },
        {
            id: "FCAM",
            value: "FCAM",
            label: "Factura cambiaria",
        },
    ];

    const SaveButton = () => {
        return (
            <button
                type="submit"
                className="save-button-configuration btn btn-primary"
            >
                <img src={icons.save} alt="guardar" />
                <p>Guardar</p>
            </button>
        );
    };

    const help_configuration = () =>
        selectedMenuItem === "Banner"
            ? "Esta imagen aparecerá en la cabecera de su tienda en linea"
            : selectedMenuItem === "Logotipo"
            ? "Logotipo"
            : selectedMenuItem === "Caja"
            ? "Forzar el cuadre de efectivo al cierre de cajas garantiza que el total de dinero en la caja coincida con el registro del sistema antes de finalizar el turno. Esto ayuda a prevenir errores y mantener un control preciso de las transacciones"
            : selectedMenuItem === "Bodega"
            ? "Configura opciones clave para la gestión de inventario y facturación. Permite controlar el stock negativo, mostrar precios en documentos de baja y despacho, visualizar el costo promedio de productos y habilitar el número de factura para un mejor seguimiento de transacciones."
            : selectedMenuItem === "Ventas"
            ? "Personaliza la configuración del punto de venta según tus necesidades. Habilita descuentos en productos, permite editar precios, gestiona ventas al crédito y pedidos entregados, ajusta el redondeo del total, muestra referencias en facturas y facilita la búsqueda por cliente para una mejor experiencia de venta."
            : selectedMenuItem === "Cotizaciòn"
            ? "Activa la opción de generar cotizaciones y define el plazo de validez en días, asegurando una gestión eficiente de ofertas y presupuestos para tus clientes."
            : selectedMenuItem === "General"
            ? "Permite visualizar y manejar precios con tres decimales, brindando mayor precisión en los cálculos y evitando redondeos que puedan afectar los montos finales."
            : selectedMenuItem === "Productos"
            ? "Configura distintos niveles de precios y asigna nombres personalizados. Permite definir precios por porcentaje y gestionar hasta cinco opciones de precio para mayor flexibilidad en la venta."
            : selectedMenuItem === "FEL"
            ? "FEL"
            : selectedMenuItem === "Tienda en linea"
            ? "Configura opciones de entrega y pago para tus clientes. Define métodos de envío, retiro en tienda y formas de pago como tarjeta, contra entrega o depósito bancario. Además, proporciona los datos de contacto de tu tienda."
            : "";

    const TitleSection = () => {
        return (
            <div className="col-sm-12">
                <div className="title-container-configuration">
                    <div className="d-flex align-items-center">
                        <p>{selectedMenuItem}</p>

                        <div
                            title={help_configuration()}
                            className="btn btn-ayuda btn-ayuda-flotante fnt-16 d-flex align-items-center justify-content-center"
                            style={{ width: "1.5rem", height: "1.5rem" }}
                        >
                            <img
                                src={icons.ayuda}
                                alt="ayuda"
                                style={{ margin: "0", height: "0.7rem" }}
                            />
                        </div>
                    </div>
                    <SaveButton />
                </div>
                <hr className="mb" />
            </div>
        );
    };

    const RenderSection = () => {
        switch (selectedMenuItem) {
            case "Banner":
                return (
                    <div className="row">
                        <TitleSection />
                        <div className="col-12">
                            <div className="container-banner-configuration">
                                {editando_banner ? (
                                    <FileUploaderCrop
                                        name="banner"
                                        onFileChange={onFileChange}
                                        clave="banner"
                                        aspect={18 / 4}
                                        setCortada={setCortada}
                                        cropShape="rect"
                                        source={banner ? banner : null}
                                        changeEdicionImagen={
                                            changeEdicionImagen
                                        }
                                    />
                                ) : (
                                    <div className="container-img-configuracion">
                                        <img
                                            src={data.banner}
                                            alt="Banner"
                                            className="img-configuracion"
                                        />
                                        <div className="container-options">
                                            <div
                                                className="bnt-imagen-configuracion"
                                                onClick={() =>
                                                    changeEdicionImagen(
                                                        "banner"
                                                    )
                                                }
                                                title="Editar banner"
                                            >
                                                <img
                                                    src={icons.editar_blanco}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            case "Logotipo":
                return (
                    <div className="row">
                        <div className="container-banner-configuration">
                            <div className="col-12">
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div className="d-flex justify-content-center align-items-center">
                                        <h5 className="m-0">
                                            Logotipo para tickets
                                        </h5>
                                        <div
                                            title={
                                                "Sube el logotipo que se imprimirá en los tickets de venta. Arrastra la imagen o haz clic en el icono para cargar el archivo"
                                            }
                                            className="btn btn-ayuda btn-ayuda-flotante fnt-16 d-flex align-items-center justify-content-center"
                                            style={{
                                                width: "1.5rem",
                                                height: "1.5rem",
                                            }}
                                        >
                                            <img
                                                src={icons.ayuda}
                                                alt="ayuda"
                                                style={{
                                                    margin: "0",
                                                    height: "0.7rem",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <SaveButton />
                                </div>
                                <hr className="mb" />
                                <FileUploader
                                    onFileChange={onLogoTicketChange}
                                    source={
                                        data.logo_ticket
                                            ? data.logo_ticket
                                            : null
                                    }
                                    icono={"add-img"}
                                    clave={"logo_ticket"}
                                    width="100%"
                                    height="100%"
                                    texto="Arrastra o has click en el icono para cargar el logo para los tickets."
                                />
                            </div>
                        </div>

                        {conf_tienda_online && (
                            <div className="container-logotipo-configuration mt-3">
                                <div className="col-12 ">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div className="d-flex justify-content-center align-items-center">
                                            <h5 className="m-0">Logotipo</h5>
                                            <div
                                                title={
                                                    "Sube el logotipo que se utilizará en la plataforma. Haz clic en el icono o arrastra el archivo para cargarlo."
                                                }
                                                className="btn btn-ayuda btn-ayuda-flotante fnt-16 d-flex align-items-center justify-content-center"
                                                style={{
                                                    width: "1.5rem",
                                                    height: "1.5rem",
                                                }}
                                            >
                                                <img
                                                    src={icons.ayuda}
                                                    alt="ayuda"
                                                    style={{
                                                        margin: "0",
                                                        height: "0.7rem",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="mb" />

                                    {editando_logo ? (
                                        <FileUploaderCrop
                                            name="logo"
                                            onFileChange={onFileChange}
                                            setCortada={setCortada}
                                            aspect={1}
                                            cropShape="round"
                                            clave="logo"
                                            source={logo ? logo : null}
                                            changeEdicionImagen={
                                                changeEdicionImagen
                                            }
                                        />
                                    ) : (
                                        <div className="container-img-configuracion">
                                            <img
                                                src={data.logo}
                                                alt="Logo"
                                                className="img-configuracion"
                                            />
                                            <div className="container-options">
                                                <div
                                                    className="bnt-imagen-configuracion"
                                                    onClick={() =>
                                                        changeEdicionImagen(
                                                            "logo"
                                                        )
                                                    }
                                                    title="Editar logo"
                                                >
                                                    <img
                                                        src={
                                                            icons.editar_blanco
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                );
            case "Caja":
                return (
                    <div className="row">
                        <TitleSection />
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <label htmlFor="conf_caja_forzar_cierre">
                                Forzar siempre el cuadre de efectivo al cierre
                                de cajas
                            </label>
                            <br />
                            <label className="switch">
                                <Field
                                    name="conf_caja_forzar_cierre"
                                    component="input"
                                    type="checkbox"
                                />
                                <span className="slider round" />
                            </label>
                        </div>
                    </div>
                );
                case "Bodega":
                    return (
                        <div className="row">
                            <TitleSection />
                            <div className="col-md-6">
                                <label htmlFor="conf_inventario_negativo">
                                    Permitir inventario negativo.
                                </label>
                                <br />
                                <label className="switch">
                                    <Field
                                        name="conf_inventario_negativo"
                                        component="input"
                                        type="checkbox"
                                    />
                                    <span className="slider round" />
                                </label>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="conf_mostrar_precio_impresion">
                                    Desplegar precios en impresión de baja y despacho
                                </label>
                                <br />
                                <label className="switch">
                                    <Field
                                        name="conf_mostrar_precio_impresion"
                                        component="input"
                                        type="checkbox"
                                    />
                                    <span className="slider round" />
                                </label>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="conf_costo_promedio">
                                    Visualizar costo promedio
                                </label>
                                <br />
                                <label className="switch">
                                    <Field
                                        name="conf_costo_promedio"
                                        component="input"
                                        type="checkbox"
                                    />
                                    <span className="slider round" />
                                </label>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="conf_habilitar_no_factura">
                                    Habilitar No. Factura
                                </label>
                                <br />
                                <label className="switch">
                                    <Field
                                        name="conf_habilitar_no_factura"
                                        component="input"
                                        type="checkbox"
                                    />
                                    <span className="slider round" />
                                </label>
                            </div>
                
                            {/* Nueva sección de imagen y título */}
                            <div className="col-md-12 text-left" style={{ marginTop: '20px', fontSize:'22px',fontWeight:'400px' }}>
                                <p>Inventario</p>
                                <hr />
                            </div>
                
                            <div className="col-md-6">
                                <label htmlFor="conteo_inventario_completo">
                                    Finalizar conteo de inventario solo si se completó al 100%
                                </label>
                                <br />
                                <label className="switch">
                                    <Field
                                        name="conteo_inventario_completo"
                                        component="input"
                                        type="checkbox"
                                    />
                                    <span className="slider round" />
                                </label>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="conteo_inventario_cero">
                                    Inventariar productos con saldo 0
                                </label>
                                <br />
                                <label className="switch">
                                    <Field
                                        name="conteo_inventario_cero"
                                        component="input"
                                        type="checkbox"
                                    />
                                    <span className="slider round" />
                                </label>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="conteo_inventario_negativo">
                                    Inventariar productos con saldo negativo
                                </label>
                                <br />
                                <label className="switch">
                                    <Field
                                        name="conteo_inventario_negativo"
                                        component="input"
                                        type="checkbox"
                                    />
                                    <span className="slider round" />
                                </label>
                            </div>
                        </div>
                    );
            case "Ventas":
                return (
                    <div className="row">
                        <TitleSection />
                        <div className="col-md-12 form-group">
                            <label htmlFor="tipo_punto_venta">
                                Tipo punto de venta
                            </label>
                            <Field
                                name="tipo_punto_venta"
                                component={renderSelectField}
                                options={tipos_punto_venta}
                                className="form-control"
                                item_vacio={false}
                            />
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="conf_venta_permitir_descuentos_items">
                                Descuentos de productos.
                            </label>
                            <br />
                            <label className="switch">
                                <Field
                                    name="conf_venta_permitir_descuentos_items"
                                    component="input"
                                    type="checkbox"
                                />
                                <span className="slider round" />
                            </label>
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="conf_venta_credito_marcar_entregado">
                                Marcar pedidos entregados de ventas al crédito.
                            </label>
                            <br />
                            <label className="switch">
                                <Field
                                    name="conf_venta_credito_marcar_entregado"
                                    component="input"
                                    type="checkbox"
                                />
                                <span className="slider round" />
                            </label>
                        </div>
                        <div className="col-md-4 form-group">
                            <label htmlFor="conf_venta_redondeo_total">
                                Redondeo del total de venta
                            </label>
                            <br />
                            <label className="switch">
                                <Field
                                    name="conf_venta_redondeo_total"
                                    component="input"
                                    type="checkbox"
                                />
                                <span className="slider round" />
                            </label>
                        </div>
                        <div className="col-md-4 form-group">
                            <label htmlFor="conf_venta_precio_editable">
                                Precio editable
                            </label>
                            <br />
                            <label className="switch">
                                <Field
                                    name="conf_venta_precio_editable"
                                    component="input"
                                    type="checkbox"
                                />
                                <span className="slider round" />
                            </label>
                        </div>
                        <div className="col-md-4 form-group">
                            <label htmlFor="conf_mostrar_referencia">
                                Mostrar opción para imprimir referencia en
                                factura
                            </label>
                            <br />
                            <label className="switch">
                                <Field
                                    name="conf_mostrar_referencia"
                                    component="input"
                                    type="checkbox"
                                />
                                <span className="slider round" />
                            </label>
                        </div>
                        <div className="col-md-4 form-group">
                            <label htmlFor="conf_ventas_al_credito">
                                Venta al credito
                            </label>
                            <br />
                            <label className="switch">
                                <Field
                                    name="conf_ventas_al_credito"
                                    component="input"
                                    type="checkbox"
                                />
                                <span className="slider round" />
                            </label>
                        </div>
                        <div className="col-md-4 form-group">
                            <label htmlFor="conf_habilitar_campo_cliente">
                                CF / Nombre editable
                            </label>
                            <br />
                            <label className="switch">
                                <Field
                                    name="conf_habilitar_campo_cliente"
                                    component="input"
                                    type="checkbox"
                                />
                                <span className="slider round" />
                            </label>
                        </div>

                        <div className="col-md-4 form-group">
                            <label htmlFor="buscar_nombre">
                                Buscar por cliente
                            </label>
                            <br />
                            <label className="switch">
                                <Field
                                    name="buscar_nombre"
                                    component="input"
                                    type="checkbox"
                                />
                                <span className="slider round" />
                            </label>
                        </div>
                    </div>
                );
            case "Cotizaciòn":
                return (
                    <div className="row">
                        <TitleSection />
                        <div className="col-md-6">
                            <label htmlFor="conf_cotizacion">
                                Habilitar cotizaciones
                            </label>
                            <br />
                            <label className="switch">
                                <Field
                                    name="conf_cotizacion"
                                    component="input"
                                    type="checkbox"
                                />
                                <span className="slider round" />
                            </label>
                        </div>
                        {conf_cotizacionValue ? (
                            <div className="col-sm-12 col-md-6 col-lg-6 form-group">
                                <label htmlFor="plazo_cotizacion">
                                    Plazo cotización (días){" "}
                                </label>
                                <Field
                                    name="plazo_cotizacion"
                                    component={renderNumber}
                                    decimalScale={0}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                );
            case "General":
                return (
                    <div className="row">
                        <TitleSection />
                        <div className="col-md-6">
                            <label htmlFor="conf_modena_con_3_decimales">
                                Utilizar 3 decimales para precios
                            </label>
                            <br />
                            <label className="switch">
                                <Field
                                    name="conf_modena_con_3_decimales"
                                    component="input"
                                    type="checkbox"
                                />
                                <span className="slider round" />
                            </label>
                        </div>

                        <div className="col-md-6">
                            <label htmlFor="conf_tipo_negocio">
                                Tipo de negocio
                            </label>
                            <br />

                            <Field
                                name="conf_tipo_negocio"
                                component={renderSelectField}
                                options={[
                                    { label: "Joyeria", value: "Joyeria" },
                                    { label: "Normal", value: "Normal" },
                                ]}
                                className="form-control"
                                item_vacio={false}
                            />
                        </div>
                    </div>
                );
            case "Productos":
                return (
                    <div className="row">
                        <TitleSection />
                        <div className="col-12 col-md-6">
                            <label htmlFor="conf_precios_porcentaje">
                                Precios por porcentaje
                            </label>
                            <br />
                            <label className="switch">
                                <Field
                                    name="conf_precios_porcentaje"
                                    component="input"
                                    type="checkbox"
                                />
                                <span className="slider round" />
                            </label>
                        </div>
                        <div className="col-12 mt-3">
                            <label htmlFor="">Nombre de precios</label>
                            <br />
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4">
                                    <label htmlFor="nombres_precios.nombre_precio">
                                        Precio
                                    </label>
                                    <br />
                                    <Field
                                        name="nombres_precios.nombre_precio"
                                        component={renderField}
                                        decimalScale={2}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <label htmlFor="nombres_precios.nombre_precio2">
                                        Precio 2
                                    </label>
                                    <br />
                                    <Field
                                        name="nombres_precios.nombre_precio2"
                                        component={renderField}
                                        decimalScale={2}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <label htmlFor="nombres_precios.nombre_precio3">
                                        Precio 3
                                    </label>
                                    <br />
                                    <Field
                                        name="nombres_precios.nombre_precio3"
                                        component={renderField}
                                        decimalScale={2}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <label htmlFor="nombres_precios.nombre_precio4">
                                        Precio 4
                                    </label>
                                    <br />
                                    <Field
                                        name="nombres_precios.nombre_precio4"
                                        component={renderField}
                                        decimalScale={2}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <label htmlFor="nombres_precios.nombre_precio5">
                                        Precio 5
                                    </label>
                                    <br />
                                    <Field
                                        name="nombres_precios.nombre_precio5"
                                        component={renderField}
                                        decimalScale={2}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case "FEL":
                return (
                    <div className="row">
                        <TitleSection />
                        <div className="col-12 p-0">
                            <div className="row">
                                <div className="col-12 p-0">
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-6 form-group">
                                            <label>Tipos de facturas</label>
                                            <FieldArray
                                                name="tipos_facturas"
                                                component={
                                                    renderCheckBoxTiposFactura
                                                }
                                                items={tipos_facturas}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="mb" />
                    </div>
                );
            case "Tienda en linea":
                return (
                    <div className="row">
                        <TitleSection />

                        {/* CONTAINER DATOS TIENDA ONLINE */}
                        <div className="col-12 p-0">
                            <div className="row">
                                <div className="col-12 p-0">
                                    <div className="row">
                                        <div className="col-12 col-md-12 col-lg-6 form-group">
                                            <label>
                                                Indique los métodos de entrega
                                            </label>
                                            <FieldArray
                                                name="metodos_entrega"
                                                component={
                                                    renderCheckBoxEntrega
                                                }
                                                items={metodos_entrega}
                                            />
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-6 form-group">
                                            <label>
                                                Indique los métodos de pago
                                            </label>
                                            <FieldArray
                                                name="metodos_pago"
                                                component={renderCheckBoxPago}
                                                items={metodos_pago}
                                                ver_deposito={ver_deposito}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-12 col-12 p-0">
                                    <div className="row">
                                        <div className="col-12">
                                            <label>
                                                {" "}
                                                Indique los datos de contacto de
                                                su tienda{" "}
                                            </label>
                                        </div>
                                        <div className="mt-2 col-12 col-md-12 col-lg-5 form-group">
                                            <label>Teléfono</label>
                                            <Field
                                                name="telefono_tienda"
                                                component={renderField}
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="col-12 mt-2 col-md-12 col-lg-5 form-group">
                                            <label>Correo</label>
                                            <Field
                                                name="correo_tienda"
                                                component={renderField}
                                                type="email"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className=" d-flex align-items-center justify-content-center col-12 mt-4 col-md-12 col-lg-2 form-group">
                                            <a
                                                href={`${tienda}/tienda/${slug}`}
                                                target="_blank"
                                                className="btn btn-outline-secondary"
                                            >
                                                Ver tienda
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="mb w-100" />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <RenderSection />
            </div>
        </form>
    );
};

let ConfiguracionForm = reduxForm({
    // a unique name for the form
    form: "configuracion",
    validate,
})(Form);

//  Decorador para leer los valores del form
const selector = formValueSelector("configuracion");

ConfiguracionForm = connect((state) => {
    //  Valores del formulario
    const form = state.form.configuracion;
    const conf_cotizacionValue = selector(state, "conf_cotizacion");
    const conf_tipo_negocio = selector(state, "conf_tipo_negocio");

    const metodos_pago = selector(state, "metodos_pago");
    let ver_deposito = { ver: false, id: -1 };
    if (state.configuracion && metodos_pago) {
        try {
            metodos_pago.forEach((metodo, index) => {
                const partes = Object.keys(metodo)[0].split("metodo_pago-");
                const id = partes[1];
                const obj_metodo = _.find(state.configuracion.metodos_pago, {
                    id: parseInt(id),
                });
                //Mutar datos de formulario para que tengan prefijo
                form.values.metodos_pago[index]["prefijo"] = obj_metodo.prefijo;
                if (obj_metodo && obj_metodo.prefijo == "DEP") {
                    ver_deposito = {
                        ver: metodo[Object.keys(metodo)[0]],
                        id: id,
                    };
                }
            });
        } catch (e) {}
    }

    return {
        conf_cotizacionValue,
        ver_deposito,
        conf_tipo_negocio,
        initialValues: {
            metodos_entrega: [],
            metodos_pago: [],
            tipos_facturas: [],
        },
    };
})(ConfiguracionForm);

export default ConfiguracionForm;

import { useEffect } from "react";

const ZohoSalesIQ = ({ usuario }) => {

useEffect(() => {
    if (!usuario || !usuario.usuario){
        return;
    }

    window.$zoho = window.$zoho || {};
    window.$zoho.salesiq = window.$zoho.salesiq || { ready: function () {} };

    if (!document.getElementById("zsiqscript")) {
        const script = document.createElement("script");
        script.id = "zsiqscript";
        script.src = "https://salesiq.zohopublic.com/widget?wc=siq8654a589bcd700cafc5971e13ce5c921ef3e2a6bce0f581e8a394bb0cd64530d";
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => {
            window.$zoho.salesiq.ready = function () {
                if (window.$zoho.salesiq.visitor) {
                    // 🔹 Se usa `?.` para evitar errores si las propiedades no existen
                    window.$zoho.salesiq.visitor.email(usuario.email );
                    window.$zoho.salesiq.visitor.name(`${usuario.first_name} ${usuario.last_name}`);
                    window.$zoho.salesiq.visitor.info({
                        Empresa: usuario.empresa || "Sin empresa",
                    });
                } else {
                }
            };
        };
    }

    return () => {
        const existingScript = document.getElementById("zsiqscript");
        if (existingScript && existingScript.parentNode === document.body) {
            document.body.removeChild(existingScript); 
        } else {
        }
    };
}, [usuario]);

return null;
};

export default ZohoSalesIQ;







import { connect } from 'react-redux';
import ListadoInventario from "./Listado";
import { actions } from "../../../../redux/modules/inventario/inventario"

const ms2p = (state) => {
    return {
        ...state.inventario,
        print_state: state.print,
        config: state.configuracion.config,
        conf_tipo_negocio:
        state.configuracion && state.configuracion.config
            ? state.configuracion.config.conf_tipo_negocio
            : false,
    }
}

const md2p = {...actions};

export default connect(ms2p, md2p)(ListadoInventario);

import React from "react";
import {
    RenderDateTime,
    RenderMoneda,
} from "Utils/renderField/renderTableField";
const ListadoPDF = ({ data, conf_tipo_negocio, hideCabecera }) => {
    return (
        <div className="table-responsive p-3">
            {data.results &&
                data.results.map(
                    (
                        {
                            concepto,
                            codigo_venta,
                            tipo_movimiento,
                            creado,
                            cliente_proveedor,
                            detalles,
                            total_cobrar_pagar,
                        },
                        index
                    ) => (
                        <div className="card shadow-sm mb-4">
                            <div className="card-header bg-primary text-white">
                                <h5 className="card-title mb-0">
                                    Detalles de la {tipo_movimiento}
                                </h5>
                            </div>
                            <div className="card-body">
                                {/* Cabecera en dos columnas */}
                                {hideCabecera ? null : <div className="row mb-3">
                                    <div className="col-md-6">
                                        {codigo_venta && (
                                            <p className="mb-1">
                                                <strong>
                                                    Código de venta:
                                                </strong>{" "}
                                                {codigo_venta}
                                            </p>
                                        )}
                                        <p className="mb-1">
                                            <strong>Fecha y hora:</strong>{" "}
                                            <RenderDateTime fecha={creado} />
                                        </p>
                                        <p className="mb-1">
                                            <strong>Concepto:</strong>{" "}
                                            {concepto}
                                        </p>
                                    </div>
                                    <div className="col-md-6">
                                        <p className="mb-1">
                                            <strong>Nombre:</strong>{" "}
                                            {cliente_proveedor
                                                ? cliente_proveedor.nombre
                                                : "CONSUMIDOR FINAL"}
                                        </p>
                                        <p className="mb-1">
                                            <strong>Tipo movimiento:</strong>{" "}
                                            {tipo_movimiento === "Reajuste" ? "Inventario" : tipo_movimiento}
                                        </p>
                                        <p className="mb-1">
                                            <strong>Subtotal:</strong> Q{" "}
                                            {total_cobrar_pagar
                                                ? total_cobrar_pagar.toFixed(3)
                                                : "0.000"}
                                        </p>
                                    </div>
                                </div>}

                                {/* Tabla de detalles */}
                                <table className="table table-bordered">
                                    <thead className="table-info">
                                        <tr>
                                            <th className="text-nowrap">ID</th>
                                            <th className="text-wrap">
                                                Producto
                                            </th>
                                            {conf_tipo_negocio ===
                                                "Joyeria" && (
                                                    <th className="text-nowrap">
                                                        Kilataje
                                                    </th>
                                                )}
                                            {conf_tipo_negocio ===
                                                "Joyeria" && (
                                                    <th className="text-nowrap">
                                                        Peso
                                                    </th>
                                                )}

                                            <th className="text-nowrap">
                                                Precio
                                            </th>
                                            <th className="text-nowrap">
                                                Cantidad
                                            </th>
                                            <th className="text-nowrap">
                                                Monto Descuento
                                            </th>
                                            <th className="text-nowrap">
                                                Descuento
                                            </th>
                                            <th className="text-nowrap">
                                                Sub Total
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {detalles &&
                                            detalles.map(
                                                ({
                                                    fraccion,
                                                    cantidad,
                                                    precio,
                                                    desc_monto,
                                                    desc_porcentaje,
                                                    sub_total,
                                                }) => {
                                                    const productoNombre =
                                                        fraccion &&
                                                            fraccion.producto
                                                            ? fraccion.producto
                                                                .nombre
                                                            : "";
                                                    const productoKilataje =
                                                        fraccion &&
                                                            fraccion.producto
                                                            ? fraccion.producto
                                                                .kilataje
                                                            : "";
                                                    const productoPeso =
                                                        fraccion &&
                                                            fraccion.producto
                                                            ? fraccion.producto
                                                                .peso
                                                            : "";
                                                    const fraccionId = fraccion
                                                        ? fraccion.id
                                                        : "";
                                                    return (
                                                        <tr>
                                                            <td className="text-nowrap">
                                                                {fraccionId}
                                                            </td>
                                                            <td className="text-wrap">
                                                                {productoNombre}
                                                            </td>
                                                            {conf_tipo_negocio ===
                                                                "Joyeria" && (
                                                                    <td className="text-nowrap">
                                                                        {
                                                                            productoKilataje
                                                                        }
                                                                    </td>
                                                                )}

                                                            {conf_tipo_negocio ===
                                                                "Joyeria" && (
                                                                    <td className="text-nowrap">
                                                                        {
                                                                            productoPeso
                                                                        }
                                                                    </td>
                                                                )}

                                                            <td className="text-nowrap">
                                                                Q{" "}
                                                                {precio
                                                                    ? precio.toFixed(
                                                                        3
                                                                    )
                                                                    : "0.000"}
                                                            </td>
                                                            <td className="text-nowrap">
                                                                {cantidad}
                                                            </td>
                                                            <td className="text-nowrap">
                                                                Q{" "}
                                                                {desc_monto
                                                                    ? desc_monto.toFixed(
                                                                        3
                                                                    )
                                                                    : "0.000"}
                                                            </td>
                                                            <td className="text-nowrap">
                                                                {desc_porcentaje
                                                                    ? desc_porcentaje +
                                                                    " %"
                                                                    : "0"}
                                                            </td>
                                                            <td className="text-nowrap">
                                                                Q{" "}
                                                                {sub_total
                                                                    ? sub_total.toFixed(
                                                                        3
                                                                    )
                                                                    : "0.000"}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                )}
        </div>
    );
};

export default ListadoPDF;

import React, { Component } from "react";
import PropTypes from "prop-types";
import Form from "./FamiliaCrearForm";
import LoadMask from "Utils/LoadMask/LoadMask";


class Crear extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        crear: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // refrescar form desde el backend
    }

    render() {
        // state
        const { loader } = this.props;

        // bind
        const { crear } = this.props;

        return (
            <div className="row">
                <div className="col-sm-12">
                    <LoadMask loading={loader} light blur radius>
                        <div className="form-group grid-container">
                            <div className="grid-titulo padding-15">
                                Nueva Familia
                            </div>
                            <div className="">
                                <Form
                                    onSubmit={crear}
                                />
                            </div>
                        </div>
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default Crear;

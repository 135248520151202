import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { activeFormatter } from "Utils/Acciones/Acciones";
import { Link } from 'react-router-dom';
import { tableOptions } from "Utils/tableOptions";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";

class ListadoFamilias extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        eliminar: PropTypes.func.isRequired,
        buscar: PropTypes.func.isRequired,
        sortChange: PropTypes.func.isRequired,
        listar: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props)
    }
    componentWillMount() {
        this.props.listar(this.props.page);
    }

    render() {
        // state
        const { data, loader, estado_descarga } = this.props;

        // bind
        const { eliminar, descargarListado } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? 'Cargando...' : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;
        return (
            <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="mt">
                    <Link className="btn btn-primary mb-sm-10" to="/configuracion/familias/crear/">
                        <em className="fa fa-plus"></em> Agregar Familia
                    </Link>
                    &nbsp;&nbsp;
                    {/* <button className="btn btn-secondary align-self-center mb-sm-10" disabled={estado_descarga} onClick={() => {
                        descargarListado();
                    }}>
                        <em className={`fa ${estado_descarga ? "fa-spinner fa-pulse" :  "fa-download"}`}></em>&nbsp;Descargar Listado
                    </button> */}
                </div>
                <div className="grid-container">
                    <div className="grid-titulo">
                        <Toolbar titulo="Familias" buscar={this.props.buscar} search={this.props.search} />
                    </div>
                    <LoadMask loading={loader} dark blur>
                        <BootstrapTable
                            data={loader ? [] : data.results}
                            remote pagination hover
                            fetchInfo={{ dataTotalSize: data.count }}
                            options={options}
                        >
                            <TableHeaderColumn dataField='nombre' dataSort>Nombre</TableHeaderColumn>
                            <TableHeaderColumn dataField='descripcion'>Descripción</TableHeaderColumn>
                            <TableHeaderColumn dataField='activo' dataFormat={(cell) => cell ? 'Activo' : 'Inactivo'}>Estado</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='id' isKey dataAlign="center" width="20%"
                                dataFormat={(cell, row) => activeFormatter({ editar: '/configuracion/familias/editar', eliminar })(cell, row)}
                            >
                                Acciones
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </LoadMask>
                </div>
            </div>
        </div>
        )
    }
}

export default ListadoFamilias

import React from "react";
import PropTypes from "prop-types";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { RenderCurrency } from "Utils/renderField/renderReadField";

const TablaSinContar = ({
  datos = [],
  totalCount = 0,
  loading = false,
  currentPage = 1,
  onPageChange = () => { },
  onSort = () => { },
  onAsignarExistencia = () => { },
  config = {},
}) => {
  const options = {
    page: currentPage,
    sizePerPage: 10,
    onPageChange: (page) => onPageChange(page),
    onSortChange: onSort,
    remote: true,
    paginationSize: 3,
    hideSizePerPage: true,
    noDataText: loading ? "Cargando..." : "No hay productos sin contar",
  };

  return (
    <BootstrapTable
      data={datos}
      remote
      pagination
      fetchInfo={{ dataTotalSize: totalCount }}
      options={options}
      hover
      keyField="id"
    >
      <TableHeaderColumn dataField="codigo_barras" dataSort>
        Código Barras
      </TableHeaderColumn>

      <TableHeaderColumn
        dataField="producto__nombre"  // se actualiza para ordenar por nombre de producto
        dataSort
        dataFormat={(cell, row) =>
          row.producto && row.producto.nombre ? row.producto.nombre : "---"
        }
      >
        Producto
      </TableHeaderColumn>

      {config && config.conf_tipo_negocio === "Joyeria" && (
        
          <TableHeaderColumn
            dataField="producto__kilataje"
            dataAlign="center"
            dataSort
            dataFormat={(cell, row) =>
              row.producto && row.producto.kilataje ? row.producto.kilataje : "---"
            }
          >
            Kilataje
          </TableHeaderColumn>
      )}
        {config && config.conf_tipo_negocio === "Joyeria" && (
          <TableHeaderColumn
            dataField="producto__peso"
            dataAlign="center"
            dataSort
            dataFormat={(cell, row) =>
              row.producto && row.producto.peso ? row.producto.peso : "---"
            }
          >
            Peso
          </TableHeaderColumn>
      )}

      <TableHeaderColumn
        dataField="total_inventario"
        dataSort
        dataFormat={(cell) => (cell !== null ? cell : 0)}
      >
        Exist. Sistema
      </TableHeaderColumn>

      <TableHeaderColumn
        dataField="precio"
        dataSort
        dataFormat={(cell) => <RenderCurrency value={cell || 0} />}
      >
        Precio Unit.
      </TableHeaderColumn>

      <TableHeaderColumn
        dataField="acciones"
        dataFormat={(_, row) => (
          <button
            className="btn btn-link p-0"
            onClick={() => onAsignarExistencia(row)}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15" cy="15" r="14.5" fill="white" stroke="#212529" />
              <path
                d="M21.75 13.0208V18.5C21.75 20.7091 19.9591 22.5 17.75 22.5H12.25C10.0409 22.5 8.25 20.7091 8.25 18.5V11.5C8.25 9.29086 10.0409 7.5 12.25 7.5H16.0848"
                stroke="#212529"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 11.25H15"
                stroke="#212529"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M12 15H18"
                stroke="#212529"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M12 18.75H18"
                stroke="#212529"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M19.5 7.5V12"
                stroke="#212529"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M21.75 9.75L17.25 9.75"
                stroke="#212529"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </button>
        )}
      >
        Acciones
      </TableHeaderColumn>
    </BootstrapTable>
  );
};

TablaSinContar.propTypes = {
  datos: PropTypes.array.isRequired,
  totalCount: PropTypes.number,
  loading: PropTypes.bool,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  onSort: PropTypes.func,
  onAsignarExistencia: PropTypes.func,
  config: PropTypes.object,
};

export default TablaSinContar;

import { connect } from "react-redux";
import ProductoListado from "./ProductoListado";
import { actions } from "../../../../redux/modules/producto/producto";

const ms2p = (state) => ({
    ...state.producto,
    data: state.producto.data,
    estado_descarga: state.producto.estado_descarga,
    conf_tipo_negocio:
        state.configuracion && state.configuracion.config
            ? state.configuracion.config.conf_tipo_negocio
            : false, // Verificación añadida
});

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(ProductoListado);

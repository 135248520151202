import { connect } from "react-redux";
import HistorialBajasSalidas from "./HistorialBajasSalidas";
import { actions } from "../../../../redux/modules/reportes/historial_baja_salida";

const mapStateToProps = (state) => ({
    ...state.reporte_historial_baja_salida,
    conf_tipo_negocio:
        state.configuracion && state.configuracion.config
            ? state.configuracion.config.conf_tipo_negocio
            : false, // Verificación añadida
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HistorialBajasSalidas);

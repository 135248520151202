import { handleActions } from "redux-actions";
import { api } from "api";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { ToastStore } from "react-toasts";
import { actions as printActions } from "../print/print";

const endpoint = "bodegas/reporte_reajustes";

export const DATA = "HISTORIAL_BODEGA_DATA";
export const FECHA_INICIAL = "HISTORIAL_BODEGA_FECHA_INICIAL";
export const FECHA_FINAL = "HISTORIAL_BODEGA_FECHA_FINAL";
export const LOADER = "HISTORIAL_BODEGA_LOADER";
export const SORT = "HISTORIAL_BODEGA_SORT";
export const ID_USUARIO = "HISTORIAL_BODEGA_ID_USUARIO";
export const ID_PROVEEDOR = "HISTORIAL_BODEGA_ID_PROVEEDOR";
export const TIPO_MOVIMIENTO = "HISTORIAL_BODEGA_TIPO_MOVIMIENTO";
export const SET_MOVIMIENTO = "HISTORIAL_BODEGA_SET_MOVIMIENTO";
export const PAGE = "HISTORIAL_BODEGA_INVERSION_PAGE";
export const ESTADO_DESCARGA = "HISTORIAL_BODEGA_ESTADO_DESCARGA";
export const DETALLE = "HISTORIAL_BODEGA_DETALLE";
export const DATA_PRINT = "HISTORIAL_DATA_PRINT";

export const SET_IMPRESION = "CAJA_SET_IMPRESION";

// Agregar la constante para totales
export const TOTALES = "HISTORIAL_BODEGA_TOTALES";

export const getReporteBodega =
    () =>
        (dispatch, getStore) => {
            console.log('entro')

            dispatch(setLoader(true));
            const store = getStore();
            const fecha_inicial = store.historial_bodega.fecha_inicial;
            const fecha_final = store.historial_bodega.fecha_final;
            const sort = store.historial_bodega.sort;
            const id_usuario = store.historial_bodega.id_usuario;
            const tipo = 60;
            const id_proveedor = "";
            const page = store.historial_bodega.page;
-
            api.get(`${endpoint}`, {
                fecha_inicial,
                fecha_final,
                sort,
                id_usuario,
                id_proveedor,
                tipo,
                page,
            })
                .catch((err) => {
                    Swal("ERROR", "Ha ocurrido un error.", "error");
                })
                .then((resp) => {
                    if (resp) {
                        dispatch(setData(resp));
                        dispatch(setPageHistorial(page));
                    }
                })
                .finally(() => {
                    dispatch(setLoader(false));
                });
        };



export const impresionGeneral = (id, seccionImpresion) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setVentaImpresion(null));

    dispatch(printActions.print(seccionImpresion));
    dispatch(setLoader(false));
};

export const getMovimiento = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.get("bodegas/resumen", { id: id })
        .catch((err) => { })
        .then((response) => {
            if (response) {
                dispatch(setMovimiento(response));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const getDetalle = (id, page = 1, sort, order) => (dispatch) => {
    dispatch(setLoader(true));
    const params = { id, page}
    if (sort) {
        params.sort = sort
    }
    if (order) {
        params.order = order
    }
    api.get(`bodegas/detalles`, params)
        .then((resp) => {
            if (resp) {
                dispatch(setDetalle(resp));
                dispatch(getTotalesDetalles(id));
            }
        })
        .finally(() => dispatch(setLoader(false)));
};

// Action para realizar la petición y guardar los totales
export const getTotalesDetalles = (id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get("bodegas/totales_detalles", { id })
        .catch((err) => {
            Swal("ERROR", "Ocurrió un error al obtener los totales.", "error");
        })
        .then((resp) => {
            if (resp) {
                dispatch(setTotales(resp));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const sortChangeHistorial = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    dispatch(getReporteBodega());
};

export const idUsuarioChange = (id_usuario) => (dispatch) => {
    dispatch(setIdUsuario(id_usuario));
    dispatch(getReporteBodega());
};

export const idProveedorChange = (id_proveedor) => (dispatch) => {
    dispatch(setIdProveedor(id_proveedor));
    dispatch(getReporteBodega());
};

export const tipoMovimientoChange = (tipo) => (dispatch) => {
    dispatch(setTipoMovimiento(tipo));
    dispatch(getReporteBodega());
};

export const setFecha = (key, value) => (dispatch, getStore) => {
    const store = getStore();
    const historial_bodega = store.historial_bodega;
    if (key === "Inicial") {
        const fecha_inicial = new Date(value);
        const fecha_final = new Date(historial_bodega.fecha_final);
        if (fecha_final >= fecha_inicial) dispatch(setFechaInicial(value));
    } else {
        const fecha_inicial = new Date(historial_bodega.fecha_inicial);
        const fecha_final = new Date(value);
        if (fecha_final >= fecha_inicial) dispatch(setFechaFinal(value));
    }
    dispatch(getReporteBodega(historial_bodega.page));
};

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const fecha_inicial = store.historial_bodega.fecha_inicial;
    const fecha_final = store.historial_bodega.fecha_final;
    const sort = store.historial_bodega.sort;
    const id_usuario = store.historial_bodega.id_usuario;
    const tipo = store.historial_bodega.tipo_movimiento;
    const id_proveedor = tipo === 20 ? store.historial_bodega.id_proveedor : "";

    const params = {
        fecha_inicial,
        fecha_final,
        sort,
        id_usuario,
        id_proveedor,
        tipo,
    };

    dispatch(setEstadoDescarga(true));
    api.get(`bodegas/descargar_excel_movimiento_bodega`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Bajas_salidas_por_producto.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};


export const imprimirListado = (id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get('bodegas/detalles_all', { id })
        .catch((err) => { })
        .then((data) => {
            if (data) {
                dispatch(setDataPrint(data.results));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
            dispatch(printActions.print('HistorialPDF'));
        });
};    
// ------------------------------------
// PureActions
// ------------------------------------

export const setVentaImpresion = (venta_impresion) => ({
    type: SET_IMPRESION,
    venta_impresion,
});

export const setFechaInicial = (fecha_inicial) => ({
    type: FECHA_INICIAL,
    fecha_inicial,
});

export const setFechaFinal = (fecha_final) => ({
    type: FECHA_FINAL,
    fecha_final,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setSort = (sort) => ({
    type: SORT,
    sort,
});

export const setIdUsuario = (id_usuario) => ({
    type: ID_USUARIO,
    id_usuario,
});

export const setIdProveedor = (id_proveedor) => ({
    type: ID_PROVEEDOR,
    id_proveedor,
});

export const setTipoMovimiento = (tipo_movimiento) => ({
    type: TIPO_MOVIMIENTO,
    tipo_movimiento,
});

export const setMovimiento = (movimiento) => ({
    type: SET_MOVIMIENTO,
    movimiento,
});
export const setPageHistorial = (page) => ({
    type: PAGE,
    page,
});
const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});
export const setDetalle = (detalle) => ({
    type: DETALLE,
    detalle,
});
export const setDataPrint = (data_print) => ({
    type: DATA_PRINT,
    data_print,
});

// Acción pura para guardar los totales en el state
export const setTotales = (totales) => ({
    type: TOTALES,
    totales,
});

export const actions = {
    getReporteBodega,
    setFecha,
    sortChangeHistorial,
    tipoMovimientoChange,
    idUsuarioChange,
    idProveedorChange,
    getMovimiento,
    getDetalle,
    descargarListado,
    impresionGeneral,
    getTotalesDetalles,
    imprimirListado,
    setPageHistorial
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [DATA]: (state, { data }) => ({
        ...state,
        data,
    }),
    [FECHA_INICIAL]: (state, { fecha_inicial }) => ({
        ...state,
        fecha_inicial,
    }),
    [FECHA_FINAL]: (state, { fecha_final }) => ({
        ...state,
        fecha_final,
    }),
    [SET_IMPRESION]: (state, { venta_impresion }) => ({
        ...state,
        venta_impresion,
    }),
    [LOADER]: (state, { loader }) => ({
        ...state,
        loader,
    }),
    [SORT]: (state, { sort }) => ({
        ...state,
        sort,
    }),
    [ID_USUARIO]: (state, { id_usuario }) => ({
        ...state,
        id_usuario,
    }),
    [ID_PROVEEDOR]: (state, { id_proveedor }) => ({
        ...state,
        id_proveedor,
    }),
    [TIPO_MOVIMIENTO]: (state, { tipo_movimiento }) => ({
        ...state,
        tipo_movimiento,
    }),
    [SET_MOVIMIENTO]: (state, { movimiento }) => ({
        ...state,
        movimiento,
    }),
    [PAGE]: (state, { page }) => ({
        ...state,
        page,
    }),
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => ({
        ...state,
        estado_descarga,
    }),
    [DETALLE]: (state, { detalle }) => ({
        ...state,
        detalle,
    }),
    [DATA_PRINT]: (state, { data_print }) => ({
        ...state,
        data_print,
    }),
    // Agregar reducer para totales
    [TOTALES]: (state, { totales }) => ({
        ...state,
        totales,
    }),
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    fecha_inicial: moment().format("YYYY-MM-DD"),
    fecha_final: moment().format("YYYY-MM-DD"),
    loader: false,
    sort: "-creado",
    id_usuario: "",
    id_proveedor: "",
    tipo_movimiento: "",
    movimiento: null,
    page: 1,
    estado_descarga: false,
    detalle: {},
    data_print: [],
    totales: {}, // Aquí se guardarán los totales de los detalles
};

export default handleActions(reducers, initialState);

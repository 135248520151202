import React from "react";
import { RenderMoneda, RenderDateTime } from "Utils/renderField/renderTableField";

const formatCurrencyQ = (value) => {
    if (!value || isNaN(value)) {
        return "Q0.00"; // Si el valor es null, undefined o NaN
    }
    return `Q${parseFloat(value).toFixed(2)}`; // Formato con 2 decimales
};

const InventarioPDF = ({ data, fecha }) => {
    return (
        <div className="card shadow-sm">
            {/* Encabezado del reporte */}
            <div className="card-header bg-primary text-white p-3">
                <h4 className="mb-0">Reporte de Inventario</h4>
                <small className="d-block">
                    Fecha: {fecha}
                </small>
            </div>

            <div className="card-body p-3">
                {/* Tabla principal */}
                <div className="table-responsive">
                    <table className="table table-bordered mb-4">
                        <thead className="table-info">
                            <tr>
                                <th className="text-center" style={{ width: "15%" }}>
                                    Codigo Barras
                                </th>
                                <th style={{ width: "40%" }}>Producto</th>
                                <th className="text-center" style={{ width: "15%" }}>
                                    P. Costo
                                </th>
                                <th className="text-center" style={{ width: "15%" }}>
                                    P. Venta
                                </th>
                                <th className="text-center" style={{ width: "15%" }}>
                                    Inventario
                                </th>           
                            </tr>
                        </thead>
                        <tbody>
                            {data ? (
                                data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.codigo_barras || "-"}</td>
                                        <td>{item.nombre || "-"}</td>
                                        <td>{item.pc ? formatCurrencyQ(item.pc) : "-"}</td>
                                        <td>{item.pv ? formatCurrencyQ(item.pv) : "-"}</td>
                                        <td className="text-center">{item.inventario || 0}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        No hay datos
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default InventarioPDF;
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import numeral from "numeral";
import {
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";

class ProductoDetalleRender extends Component {
    static propTypes = {
        producto: PropTypes.object.isRequired,
    };
    constructor(props) {
        super(props);
    }
    render() {
        //  state
        const { producto } = this.props;
        //  bind

        return (
            <div className="col-sm-12">
                <div className="grid-container padding-15">
                    <div className="grid-titulo padding-15">
                        {producto.nombre}
                    </div>
                    <div className="grid-cuerpo padding-15">
                        {/* IMAGEN */}
                        <div className="row">
                            <div className="col-sm-6 content-drop-zone">
                                <img
                                    src={producto.imagen}
                                    width="200"
                                    height="200"
                                />
                            </div>
                            <div className="col-sm-6 contentainer-prices-cost secondary">
                                <div>
                                    <strong>Costo: </strong>
                                    <RenderCurrency value={producto.costo} />
                                </div>
                                <div>
                                    <strong>Precio: </strong>
                                    <RenderCurrency value={producto.precio} />
                                </div>
                            </div>
                        </div>
                        {/* INFO */}
                        <div className="row row-fields">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">Nombre</label>
                                <div className="input-view">
                                    {producto.nombre}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">Marca</label>
                                <div className="input-view">
                                    {producto.marca}
                                </div>
                            </div>
                        </div>
                        <div className="row row-fields">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">
                                    Presentación
                                </label>
                                <div className="input-view">
                                    {producto.presentacion}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">
                                    Código de barras (UPC)
                                </label>
                                <div className="input-view">
                                    {producto.codigo_barras}
                                </div>
                            </div>
                        </div>
                        <div className="row row-fields">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">Modelo</label>
                                <div className="input-view">
                                    {producto.modelo}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">
                                    Mínimo existencias
                                </label>
                                <div className="input-view">
                                    <RenderNumber
                                        value={producto.minimo_existencias}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row row-fields">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">
                                    Inventariable
                                </label>
                                <div className="input-view">
                                    {producto.invetariable ? "Si" : "No"}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="label-view">Familia</label>
                                <div className="input-view">
                                    {producto.familia &&
                                        producto.familia.nombre}
                                </div>
                            </div>
                        </div>
                        <div className="row row-fields">
                            {producto.peso && (
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <label className="label-view">Peso</label>
                                    <div className="input-view">
                                        {producto.peso} g
                                    </div>
                                </div>
                            )}

                            {producto.kilataje && (
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <label className="label-view">
                                        Kilataje
                                    </label>
                                    <div className="input-view">
                                        {producto.kilataje} kilates
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="row row-fields">
                            <div className="col-sm-12">
                                <label className="label-view">
                                    Descripción
                                </label>
                                <div className="producto-descripcion">
                                    {producto.descripcion}
                                </div>
                            </div>
                        </div>
                        {/* FOOTER */}
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <Link
                                    className="btn btn-tertiary mr"
                                    to="/productos/"
                                >
                                    <i
                                        className="fa fa-arrow-left"
                                        aria-hidden="true"
                                    >
                                        {" "}
                                    </i>{" "}
                                    Regresar
                                </Link>
                                <Link
                                    className="btn btn-primary ml"
                                    to={"/productos/editar/" + producto.id}
                                >
                                    <i
                                        className="fa fa-pencil"
                                        aria-hidden="true"
                                    >
                                        {" "}
                                    </i>{" "}
                                    Editar
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductoDetalleRender;

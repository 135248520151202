import { connect } from "react-redux";
import { actions as bodegaActions } from "../../../../redux/modules/bodega/bodega";
import { actions as reporteBodegaActions } from "../../../../redux/modules/reportes/historial_bodega";
import Apertura from "./ListadoProductos";

const ms2p = (state) => ({
  // Estado de BODEGA (Inventario Actual)
  bodegaData: state.bodega.data,
  loaderBodega: state.bodega.loader,
  pageBodega: state.bodega.page,
  filtroBodega: state.bodega.filtro,
  estadoDescargaBodega: state.bodega.estado_descarga,
  searchBodega: state.bodega.search,
  print_state: state.print,
  familias: state.bodega.familias,
  totales_bodega: state.bodega.totales,
  data_print: state.bodega.data_print,
  // Estado de REPORTE_BODEGA (Historial)
  reporteData: state.historial_bodega.data,
  reporteLoader: state.historial_bodega.loader,
  pageReporte: state.historial_bodega.page,
  fechaInicialReporte: state.historial_bodega.fecha_inicial,
  fechaFinalReporte: state.historial_bodega.fecha_final,
  idUsuarioReporte: state.historial_bodega.id_usuario,
  idProveedorReporte: state.historial_bodega.id_proveedor,
  descargarHistorial: state.historial_bodega.descargar_listado,
  usuario: state.usuario.me,
  detalle: state.historial_bodega.detalle,
  config: state.configuracion.config,
  data_print_historial: state.historial_bodega.data_print,
  totales_historial: state.historial_bodega.totales
});

const md2p = {
  ...bodegaActions,
  ...reporteBodegaActions,
};

export default connect(ms2p, md2p)(Apertura);
import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "Utils/LoadMask/LoadMask";
import Select, { Async } from "react-select";
import { api } from "api";
import {
    RenderMoneda,
    RenderNumero,
    RenderNumeroDecimales,
    RenderCurrency,
} from "Utils/renderField/renderTableField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import DatePicker from "Utils/DatePicker";
import moment from "moment";
import _ from "lodash";
import ListadoPDF from "Utils/InventarioPDF/InventarioPDF";
import {PrintContainer, PrintComponent} from "Utils/Print";
import InventarioPDF from "../../Utils/InventarioPDF/InventarioPDF";

class ListadoInventario extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        data: PropTypes.object.isRequired,
        fecha: PropTypes.string,
        sucursal: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
        descargarListado: PropTypes.func.isRequired,
        estado_descarga: PropTypes.bool.isRequired,
    };

    onChangeSucursal = (sucursal) => {
        this.props.setSucursal(sucursal);
    };

    render() {
        const {
            loader,
            sucursal,
            data,
            fecha,
            descargarListado,
            estado_descarga,
        } = this.props;
        const options = _.cloneDeep(tableOptions);

        const getSucursales = (search) => {
            return api.get("sucursales", { search })
                .catch(() => { })
                .then((data) => ({ options: data.results }));
        };
        
        const formatCurrencyQ = (cell, row) => {
            // Verifica si el valor es null, undefined o NaN
            if (!cell || isNaN(cell)) {
                return 'Q0.00';  // Si no hay valor, muestra Q0.00
            }
            // Formatea el valor como moneda
            return `Q${parseFloat(cell).toFixed(2)}`;
        };

        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;

        return (
            <div>
                {this.props.print_state.flag && <PrintComponent />}
                <div className="row mb-2">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="mb-1 mt-1">
                                <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                    <h3>
                                        <b>Reporte de Inventario</b>
                                    </h3>
                                    <div
                                        className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                        style={{ flex: "3" }}
                                    >
                                        <div style={{ flex: "0.2" }}>
                                            <label>Fecha</label>
                                            <DatePicker
                                                dateFormat="yyyy-MM-dd"
                                                value={fecha}
                                                onChange={(date) => {
                                                    const formatted = date ? moment(date).format("YYYY-MM-DD") : null;
                                                    this.props.setFecha(formatted);
                                                }}
                                            />
                                        </div>
                                        &nbsp; &nbsp;
                                        <div style={{ flex: "0.2" }}>
                                            <label>Sucursal</label>
                                            <Async
                                                className="form-control"
                                                placeholder="Seleccionar sucursal"
                                                onChange={this.onChangeSucursal}
                                                searchPromptText="Escriba para buscar"
                                                valueKey={"id"}
                                                labelKey={"nombre"}
                                                loadOptions={getSucursales}
                                                value={sucursal}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Botón para imprimir listado */}
                                <div className="row d-flex justify-content-end mt-3 mr-2">
                                    <div className="col-12 col-md-3 col-lg-3">
                                        <button
                                            className="btn btn-primary btn-block"
                                            onClick={() => this.props.imprimirListado()}
                                        >
                                            Imprimir Listado
                                        </button>
                                    </div>
                                    <button
                                        className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                        disabled={estado_descarga}
                                        onClick={() => {
                                            descargarListado();
                                        }}
                                    >
                                        <em className={`fa ${estado_descarga ? "fa-spinner fa-pulse" : "fa-download"}`}/>
                                        &nbsp;Descargar Listado
                                    </button>
                                </div>
                                <div className="row d-flex align-items-center justify-content-end mt-3">
                                    {/* <div className="col-12 col-md-3 col-lg-3 pl-3 pl-md-0 pl-lg-0">
                                        <h5 className="text-right">
                                            Total importe:
                                            <b className="ml-2">
                                                <RenderMoneda monto={data.total_importe || 0} />
                                            </b>
                                        </h5>
                                    </div> */}
                                </div>
                                <LoadMask loading={loader} light blur>
                                    <BootstrapTable
                                        data={loader ? [] : data.results}
                                        remote
                                        pagination
                                        hover
                                        keyField="id"
                                        fetchInfo={{ dataTotalSize: data.count }}
                                        options={options}
                                    >
                                        <TableHeaderColumn dataSort dataField="codigo_barras">Codigo</TableHeaderColumn>
                                        <TableHeaderColumn dataSort dataField="nombre">Producto</TableHeaderColumn>
                                        <TableHeaderColumn dataSort dataField="pc" dataFormat={formatCurrencyQ}>Costo</TableHeaderColumn>
                                        <TableHeaderColumn dataSort dataField="pv" dataFormat={formatCurrencyQ}>Precio</TableHeaderColumn>
                                        <TableHeaderColumn dataSort dataField="inventario">Inventario</TableHeaderColumn>

                                        {/* <TableHeaderColumn dataField="precio_venta">P. Venta</TableHeaderColumn>
                                        <TableHeaderColumn dataField="precio_compra">P. Compra</TableHeaderColumn> 
                                        <TableHeaderColumn dataField="importe">Importe</TableHeaderColumn> */}
                                    </BootstrapTable>
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w100 print-only">

                    <PrintContainer
                        name="InventarioPDF"
                        className="impresion-venta d-table w-100"
                    >
                        <InventarioPDF
                            data={this.props.data_print ? this.props.data_print : []}
                            conf_tipo_negocio={this.props.conf_tipo_negocio}
                            fecha={this.props.fecha}
                        />
                    </PrintContainer>
                </div>
            </div>
        );
    }
}

export default ListadoInventario;
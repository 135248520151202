import _ from "lodash";
import { connect } from "react-redux";
import Crear from "./ProductoCrear";
import { goBack } from "react-router-redux";
import { crear, actions } from "../../../../redux/modules/producto/producto";

const ms2p = (state) => {
    const { sucursales } = state.usuario.me;
    const values = state.form.ProductoCrear
        ? state.form.ProductoCrear.values
        : {};
    let lista_sucursales = [];
    if (sucursales) {
        sucursales.forEach((sucursal) => {
            if (sucursal.id != "empresa") {
                const precios_sucursal = values.precios_sucursal
                    ? values.precios_sucursal
                    : [];
                const encontrado = _.find(precios_sucursal, function (o) {
                    return o.sucursal == sucursal.id;
                });
                if (!encontrado && values.tipo_producto != "100") {
                    lista_sucursales.push({
                        id: sucursal.id,
                        nombre: sucursal.nombre,
                    });
                }
            }
        });
    }
    console.log(state);
    return {
        forms: state.form,
        unidades_de_medida_empresa: state.usuario.unidades_de_medida_empresa,
        // unidades_medidas_seleccionadas: state.producto.unidades_medidas,
        ...state.producto,
        conf_tienda_online: state.usuario.conf_tienda_online,
        lista_sucursales,
        nombres_precios: state.usuario.nombres_precios,
        conf_porcentaje_precio: state.usuario.conf_porcentaje_precio,
        conf_tipo_negocio:
            state.configuracion && state.configuracion.config
                ? state.configuracion.config.conf_tipo_negocio
                : "Normal", // Verificación añadida
    };
};

const md2p = {
    submit: (fotos, deBodega) => (dispatch) => dispatch(crear(fotos, deBodega)),
    regresar: () => (dispatch) => dispatch(goBack()),
    ...actions,
};

export default connect(ms2p, md2p)(Crear);

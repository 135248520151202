import { handleActions } from "redux-actions";
import { api } from "api";
import Swal from "sweetalert2";
import moment from "moment";
import { actions as printActions } from "../print/print";  // Importa acciones de impresión

const endpoint = "historial_inventario";

export const DATA = "REP_INV_DATA";
export const LOADER = "REP_INV_LOADER";
export const PAGE = "REP_INV_PAGE";
export const SUCURSAL = "REP_INV_SUCURSAL";
export const SORT = "REP_INV_SORT";
export const FECHA= "REP_INV_FECHA";
export const DATA_PRINT = "REP_DATA_PRINT";
export const ESTADO_DESCARGA = "REP_INV_ESTADO_DESCARGA";

// ------------------------------------
// Actions
// ------------------------------------
export const listar = (page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const fecha = store.reporte_inventario.fecha;    
    const params = { page, fecha };
    const sucursal = store.reporte_inventario.sucursal;
    params.ordering = store.reporte_inventario.ordering;
    if (sucursal) params.sucursal_rep = sucursal.id;    
    if (sucursal) {
        api.get(`${endpoint}/productos`, params)
            .catch((err) => {
                Swal.fire("ERROR", "Ha ocurrido un error.", "error");
            })
            .then((resp) => {
                if (resp) {
                    dispatch(setData(resp));
                    dispatch(setPage(page));
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    } else {
        dispatch(setData({}));
        dispatch(setLoader(false));
    }
};

export const setSucursal = (value) => (dispatch, getStore) => {
    dispatch(_setSucursal(value));
    const store = getStore();
    if (store.reporte_inventario.sucursal) {
        dispatch(listar());
    }
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const page = getStore().reporte_inventario.page;
    dispatch(listar(page));
};

export const setFecha = (fecha) => (dispatch, getStore) => {
    dispatch({
        type: FECHA,
        fecha,
    });
    const store = getStore();
    if (store.reporte_inventario.sucursal) {
        dispatch(listar());
    }
};

// Nueva acción para imprimir el listado usando el formato InventarioPDF
export const imprimirListado = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const fecha = store.reporte_inventario.fecha;    
    const params = { fecha };
    const sucursal = store.reporte_inventario.sucursal;
    params.ordering = store.reporte_inventario.ordering;
    if (sucursal) params.sucursal_rep = sucursal.id; 
    
    api.get(`${endpoint}/productos_all`, params)
        .catch((err) => { })
        .then((data) => {
            if (data) {
                console.log(data)
                dispatch(setDataPrint(data));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
            dispatch(printActions.print('InventarioPDF'));
        });
};    

export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const fecha = store.reporte_inventario.fecha;    
    const params = { fecha };
    const sucursal = store.reporte_inventario.sucursal;
    params.ordering = store.reporte_inventario.ordering;
    if (sucursal) params.sucursal_rep = sucursal.id; 
    dispatch(setEstadoDescarga(true));
    api.get(`${endpoint}/descargar_excel_inventario`, params)
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;
    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA
    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "reporte_inventario.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }
    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

// ------------------------------------
// PureActions
// ------------------------------------
export const setData = (data) => ({
    type: DATA,
    data,
});
export const setDataPrint = (data_print) => ({
    type: DATA_PRINT,
    data_print,
});
export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setPage = (page) => ({
    type: PAGE,
    page,
});

export const _setSucursal = (sucursal) => ({
    type: SUCURSAL,
    sucursal,
});

export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});

const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const actions = {
    setSucursal,
    listar,
    sortChange,
    setFecha,
    imprimirListado,
    descargarListado,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [DATA]: (state, { data }) => ({ ...state, data }),
    [LOADER]: (state, { loader }) => ({ ...state, loader }),
    [PAGE]: (state, { page }) => ({ ...state, page }),
    [SUCURSAL]: (state, { sucursal }) => ({ ...state, sucursal }),
    [SORT]: (state, { ordering }) => ({ ...state, ordering }),
    [FECHA]: (state, { fecha }) => ({ ...state, fecha }),
    [DATA_PRINT]: (state, { data_print }) => ({ ...state, data_print }),
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => ({ ...state, estado_descarga }),
};

// ------------------------------------
// InitialState
// ------------------------------------
export const initialState = {
    data: {},
    ordering: "",
    loader: false,
    page: 1,
    sucursal: 0,
    data_print: [],
    fecha: moment().format("YYYY-MM-DD"),
    estado_descarga: false,
};

export default handleActions(reducers, initialState);

import React from "react";
import { icons } from "icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PVMCE, PVN } from "utility/variables";
import {
    validate,
    validators,
    validatorFromFunction,
} from "validate-redux-form";
import { renderCheckBox } from "Utils/renderField/renderFieldMultiSelect";
import { Field, FieldArray, reduxForm, formValueSelector } from "redux-form";
import {
    renderField,
    renderSelectField,
    renderSearchSelect,
} from "Utils/renderField/renderField";

const required = (value) => (value ? undefined : "Campo requerido");
const maxLength = (max) => (value) =>
    value && value.length > max
        ? `Debe tener ${max} caracteres o menos`
        : undefined;
const maxLength100 = maxLength(100);

const Form = (props) => {
    const {
        handleSubmit,
        changeValue,
        reportes,
        configuracion,
        despacho,
        bodega,
        sucursales,
        changePermisos,
        rolValue,
        esGlobal,
        tipo_punto_venta,
        utiliza_fel,
        punto_venta_mostrador_caja,
    } = props;

    //  Valores de checkbox de permisos
    const {
        dashboardValue,
        bodegaValue,
        punto_ventaValue,
        productoValue,
        gastoValue,
        bitacoraValue,
        reporte_balance_generalValue,
        reporte_apertura_cierreValue,
        reporte_bodegaValue,
        reporte_ventaValue,
        reporte_devolucionValue,
        reporte_cuenta_cobrarValue,
        reporte_gastoValue,
        reporte_productos_mas_vendidosValue,
        reporte_mejores_clientesValue,
        reporte_total_invertidoValue,
        reporte_ganancia_brutaValue,
        conf_tiendaValue,
        conf_usuarioValue,
        conf_sucursalValue,
        conf_clienteValue,
        conf_proveedorValue,
        conf_cajaValue,
        conf_destino_salidaValue,
        conf_adicionales_nota_felValue,
        perfilValue,
        despacho_despachosValue,
        despacho_recepcionesValue,
        despacho_recepciones_orden_compraValue,
        reporte_despachosValue,
        punto_venta_cajaValue,
        punto_venta_mostradorValue,
        orden_compraValue,
        orden_onlineValue,
        reporte_movimientosValue,
        reporte_historial_preciosValue,
        reporte_historial_bajas_salidasValue,
        reporte_descuentos_recargosValue,
        igreso_mercaderiaValue,
        baja_mercaderiaValue,
        salida_mercaderiaValue,
        reajuste_mercaderiaValue,
        permiso_grafica_ventasValue
    } = props;

    let roles = [
        {
            id: "es_admin",
            value: "es_admin",
            label: "Administrador",
        },
        {
            id: "es_cajero",
            value: "es_cajero",
            label: "Cajero",
        },
        {
            id: "es_bodeguero",
            value: "es_bodeguero",
            label: "Bodeguero",
        },
        {
            id: "personalizado",
            value: "personalizado",
            label: "Personalizado",
        },
    ];

    if (tipo_punto_venta == PVMCE) {
        roles.splice(1, 0, {
            id: "es_vendedor_mostrador",
            value: "es_vendedor_mostrador",
            label: "Vendedor Mostrador",
        });
    }

    const lectura =
        rolValue === "es_admin" ||
        rolValue === "es_cajero" ||
        rolValue === "es_bodeguero" ||
        rolValue === "es_vendedor_mostrador";

    return (
        <form onSubmit={handleSubmit} className="row mt">
            <div className="col-sm-12">
                <div className="form-group">
                    <div className="padding-15 p-sm-0 pt-sm-1">
                        <div className="row">
                            <div className="col-sm-12">
                                <h3>Datos del usuario</h3>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="first_name">
                                    Nombre Completo*
                                </label>
                                <Field
                                    name="first_name"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                    validate={[required, maxLength100]}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="username">
                                    Nombre de usuario para ingreso a sistema*
                                </label>
                                <Field
                                    name="username"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="password">
                                    Contraseña para ingreso a sistema*
                                </label>
                                <Field
                                    name="password"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="rol">Asignar rol*</label>
                                <Field
                                    name="rol"
                                    component={renderSelectField}
                                    options={roles}
                                    className="form-control"
                                    item_vacio={true}
                                    onChange={(e) => {
                                        changePermisos(
                                            e.target.value,
                                            "UsuarioCrear"
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="rol">Correo</label>
                                <Field
                                    type="text"
                                    name="email"
                                    component={renderField}
                                    className="form-control"
                                />
                            </div>
                            <div className="col-sm-12">
                                <h3>Sucursales</h3>
                            </div>
                            <div className="col-12 col-md-12 form-group">
                                <label>
                                    Indique el nivel de navegación del usuario
                                </label>
                                <div className="d-flex">
                                    <div className="radio c-radio c-radio-nofont d-flex ml-3">
                                        <label className="negro-dark2 font-weight-normal">
                                            <Field
                                                name="es_global"
                                                component="input"
                                                type="radio"
                                                value="true"
                                            />
                                            <span />
                                            Empresa completa{" "}
                                            <small>
                                                Capaz de filtrar por cualquier
                                                sucursal o ver la empresa como
                                                conjunto
                                            </small>
                                        </label>
                                    </div>
                                    <div className="radio c-radio c-radio-nofont d-flex ml-3">
                                        <label className="negro-dark2 font-weight-normal">
                                            <Field
                                                name="es_global"
                                                component="input"
                                                type="radio"
                                                value="false"
                                            />
                                            <span />
                                            Sucursales especificadas{" "}
                                            <small>
                                                Capaz de ver información de las
                                                sucursales asignadas
                                            </small>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {esGlobal === "false" && (
                                <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                    <label htmlFor="sucursales">
                                        Indique a qué sucursales podrá acceder
                                        el usuario*
                                    </label>
                                    <FieldArray
                                        name="sucursales"
                                        component={renderCheckBox}
                                        items={sucursales}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-12 mt-2">
                                <h3>Permisos</h3>
                            </div>
                        </div>
                        {tipo_punto_venta == PVMCE && (
                            <div className="row mb-3">
                                <div className="col-12 col-md-6 col-lg-6">
                                    {lectura ? (
                                        <div className="w-100 p-3 font-weight-bold">
                                            <span
                                                className={`fa fa-check fa-lg mr-2  ${punto_venta_mostrador_caja
                                                        ? "naranja"
                                                        : "transparente"
                                                    }`}
                                            />
                                            Punto de venta
                                        </div>
                                    ) : (
                                        <div className="checkbox c-checkbox p-3">
                                            <label className="needsclick">
                                                <input
                                                    type="checkbox"
                                                    onChange={() =>
                                                        changeValue(
                                                            "punto_venta_mostrador_caja",
                                                            "UsuarioCrear"
                                                        )
                                                    }
                                                    checked={
                                                        punto_venta_mostrador_caja
                                                    }
                                                />
                                                <span className="fa fa-check" />
                                                Punto de venta
                                            </label>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${punto_venta_mostradorValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Mostrador
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="punto_venta_mostrador"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Mostrador
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${punto_venta_cajaValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Caja
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="punto_venta_caja"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Caja
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-12 col-md-6 col-lg-4">
                                {lectura ? (
                                    <div className="w-100 p-3 font-weight-bold">
                                        <span
                                            className={`fa fa-check fa-lg mr-2  ${dashboardValue
                                                    ? "naranja"
                                                    : "transparente"
                                                }`}
                                        />
                                        Dashboard
                                    </div>
                                ) : (
                                    <div className="checkbox c-checkbox p-3">
                                        <label className="needsclick">
                                            <Field
                                                name="dashboard"
                                                component="input"
                                                type="checkbox"
                                            />
                                            <span className="fa fa-check" />
                                            Dashboard
                                        </label>
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                {lectura ? (
                                    <div className="w-100 p-3 font-weight-bold">
                                        <span
                                            className={`fa fa-check fa-lg mr-2  ${bodega
                                                    ? "naranja"
                                                    : "transparente"
                                                }`}
                                        />
                                        Bodega
                                    </div>
                                ) : (
                                    <div className="checkbox c-checkbox p-3">
                                        <label className="needsclick">
                                            <input
                                                type="checkbox"
                                                onChange={() =>
                                                    changeValue(
                                                        "bodega",
                                                        "UsuarioCrear"
                                                    )
                                                }
                                                checked={bodega}
                                            />
                                            <span className="fa fa-check" />
                                            Bodega
                                        </label>
                                        {/* <label className="needsclick">
                                            <Field
                                                name="bodega"
                                                component="input"
                                                type="checkbox"
                                            />
                                            <span className="fa fa-check" />
                                            Bodega
                                        </label> */}
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-12">
                                        {lectura ? (
                                            <div className="w-100 ml-3 font-weight-bold">
                                                <span
                                                    className={`fa fa-check fa-lg mr-2  ${igreso_mercaderiaValue
                                                            ? "naranja"
                                                            : "transparente"
                                                        }`}
                                                />
                                                Ingreso de mercadería
                                            </div>
                                        ) : (
                                            <div className="checkbox c-checkbox ml-3">
                                                <label className="needsclick">
                                                    <Field
                                                        name="igreso_mercaderia"
                                                        component="input"
                                                        type="checkbox"
                                                    />
                                                    <span className="fa fa-check" />
                                                    Ingreso de mercadería
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-12">
                                        {lectura ? (
                                            <div className="w-100 ml-3 font-weight-bold">
                                                <span
                                                    className={`fa fa-check fa-lg mr-2  ${baja_mercaderiaValue
                                                            ? "naranja"
                                                            : "transparente"
                                                        }`}
                                                />
                                                Baja de mercadería
                                            </div>
                                        ) : (
                                            <div className="checkbox c-checkbox ml-3">
                                                <label className="needsclick">
                                                    <Field
                                                        name="baja_mercaderia"
                                                        component="input"
                                                        type="checkbox"
                                                    />
                                                    <span className="fa fa-check" />
                                                    Baja de mercadería
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-12">
                                        {lectura ? (
                                            <div className="w-100 ml-3 font-weight-bold">
                                                <span
                                                    className={`fa fa-check fa-lg mr-2  ${salida_mercaderiaValue
                                                            ? "naranja"
                                                            : "transparente"
                                                        }`}
                                                />
                                                Salida de mercadería
                                            </div>
                                        ) : (
                                            <div className="checkbox c-checkbox ml-3">
                                                <label className="needsclick">
                                                    <Field
                                                        name="salida_mercaderia"
                                                        component="input"
                                                        type="checkbox"
                                                    />
                                                    <span className="fa fa-check" />
                                                    Salida de mercadería
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-12">
                                        {lectura ? (
                                            <div className="w-100 ml-3 font-weight-bold">
                                                <span
                                                    className={`fa fa-check fa-lg mr-2  ${reajuste_mercaderiaValue
                                                            ? "naranja"
                                                            : "transparente"
                                                        }`}
                                                />
                                                Reajuste de mercadería
                                            </div>
                                        ) : (
                                            <div className="checkbox c-checkbox ml-3">
                                                <label className="needsclick">
                                                    <Field
                                                        name="reajuste_mercaderia"
                                                        component="input"
                                                        type="checkbox"
                                                    />
                                                    <span className="fa fa-check" />
                                                    Reajuste de mercadería
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                {lectura ? (
                                    <div className="w-100 p-3 font-weight-bold">
                                        <span
                                            className={`fa fa-check fa-lg mr-2  ${perfilValue
                                                    ? "naranja"
                                                    : "transparente"
                                                }`}
                                        />
                                        Perfil
                                    </div>
                                ) : (
                                    <div className="checkbox c-checkbox p-3">
                                        <label className="needsclick">
                                            <Field
                                                name="perfil"
                                                component="input"
                                                type="checkbox"
                                            />
                                            <span className="fa fa-check" />
                                            Perfil
                                        </label>
                                    </div>
                                )}
                            </div>
                            {tipo_punto_venta == PVN && (
                                <div className="col-12 col-md-6 col-lg-4">
                                    {lectura ? (
                                        <div className="w-100 p-3 font-weight-bold">
                                            <span
                                                className={`fa fa-check fa-lg mr-2  ${punto_ventaValue
                                                        ? "naranja"
                                                        : "transparente"
                                                    }`}
                                            />
                                            Punto de venta
                                        </div>
                                    ) : (
                                        <div className="checkbox c-checkbox p-3">
                                            <label className="needsclick">
                                                <Field
                                                    name="punto_venta"
                                                    component="input"
                                                    type="checkbox"
                                                />
                                                <span className="fa fa-check" />
                                                Punto de venta
                                            </label>
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className="col-12 col-md-6 col-lg-4">
                                {lectura ? (
                                    <div className="w-100 p-3 font-weight-bold">
                                        <span
                                            className={`fa fa-check fa-lg mr-2  ${productoValue
                                                    ? "naranja"
                                                    : "transparente"
                                                }`}
                                        />
                                        Productos
                                    </div>
                                ) : (
                                    <div className="checkbox c-checkbox p-3">
                                        <label className="needsclick">
                                            <Field
                                                name="producto"
                                                component="input"
                                                type="checkbox"
                                            />
                                            <span className="fa fa-check" />
                                            Productos
                                        </label>
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                {lectura ? (
                                    <div className="w-100 p-3 font-weight-bold">
                                        <span
                                            className={`fa fa-check fa-lg mr-2  ${gastoValue
                                                    ? "naranja"
                                                    : "transparente"
                                                }`}
                                        />
                                        Reportar Gastos
                                    </div>
                                ) : (
                                    <div className="checkbox c-checkbox p-3">
                                        <label className="needsclick">
                                            <Field
                                                name="gasto"
                                                component="input"
                                                type="checkbox"
                                            />
                                            <span className="fa fa-check" />
                                            Reportar Gastos
                                        </label>
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                {lectura ? (
                                    <div className="w-100 p-3 font-weight-bold">
                                        <span
                                            className={`fa fa-check fa-lg mr-2  ${bitacoraValue
                                                    ? "naranja"
                                                    : "transparente"
                                                }`}
                                        />
                                        Bitácora
                                    </div>
                                ) : (
                                    <div className="checkbox c-checkbox p-3">
                                        <label className="needsclick">
                                            <Field
                                                name="bitacora"
                                                component="input"
                                                type="checkbox"
                                            />
                                            <span className="fa fa-check" />
                                            Bitácora
                                        </label>
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                {lectura ? (
                                    <div className="w-100 p-3 font-weight-bold">
                                        <span
                                            className={`fa fa-check fa-lg mr-2  ${orden_compraValue
                                                    ? "naranja"
                                                    : "transparente"
                                                }`}
                                        />
                                        Ordenes de compra
                                    </div>
                                ) : (
                                    <div className="checkbox c-checkbox p-3">
                                        <label className="needsclick">
                                            <Field
                                                name="orden_compra"
                                                component="input"
                                                type="checkbox"
                                            />
                                            <span className="fa fa-check" />
                                            Ordenes de compra
                                        </label>
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                {lectura ? (
                                    <div className="w-100 p-3 font-weight-bold">
                                        <span
                                            className={`fa fa-check fa-lg mr-2  ${orden_onlineValue
                                                    ? "naranja"
                                                    : "transparente"
                                                }`}
                                        />
                                        Ordenes online
                                    </div>
                                ) : (
                                    <div className="checkbox c-checkbox p-3">
                                        <label className="needsclick">
                                            <Field
                                                name="orden_online"
                                                component="input"
                                                type="checkbox"
                                            />
                                            <span className="fa fa-check" />
                                            Ordenes online
                                        </label>
                                    </div>
                                )}
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-4">
                                    {lectura ? (
                                        <div className="w-100 p-3 font-weight-bold">
                                            <span
                                                className={`fa fa-check fa-lg mr-2  ${despacho
                                                        ? "naranja"
                                                        : "transparente"
                                                    }`}
                                            />
                                            Despacho
                                        </div>
                                    ) : (
                                        <div className="checkbox c-checkbox p-3">
                                            <label className="needsclick">
                                                <input
                                                    type="checkbox"
                                                    onChange={() =>
                                                        changeValue(
                                                            "despacho",
                                                            "UsuarioCrear"
                                                        )
                                                    }
                                                    checked={despacho}
                                                />
                                                <span className="fa fa-check" />
                                                Despacho
                                            </label>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${despacho_despachosValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Despachos
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="despacho_despachos"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Despachos
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${despacho_recepcionesValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Recepciones
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="despacho_recepciones"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Recepciones
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${despacho_recepciones_orden_compraValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Recepciones ordenes de
                                                    compra
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="despacho_recepciones_orden_compra"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Recepciones ordenes de
                                                        compra
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    {lectura ? (
                                        <div className="w-100 p-3 font-weight-bold">
                                            <span
                                                className={`fa fa-check fa-lg mr-2  ${reportes
                                                        ? "naranja"
                                                        : "transparente"
                                                    }`}
                                            />
                                            Reportes
                                        </div>
                                    ) : (
                                        <div className="checkbox c-checkbox p-3">
                                            <label className="needsclick">
                                                <input
                                                    type="checkbox"
                                                    onChange={() =>
                                                        changeValue(
                                                            "reportes",
                                                            "UsuarioCrear"
                                                        )
                                                    }
                                                    checked={reportes}
                                                />
                                                <span className="fa fa-check" />
                                                Reportes
                                            </label>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${reporte_balance_generalValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Balance general
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="reporte_balance_general"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Balance general
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${reporte_apertura_cierreValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Apertura y cierre
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="reporte_apertura_cierre"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Apertura y cierre
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${reporte_bodegaValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Bodega
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="reporte_bodega"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Bodega
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${reporte_ventaValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Ventas
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="reporte_venta"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Ventas
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${reporte_devolucionValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Devoluciones
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="reporte_devolucion"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Devoluciones
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${reporte_cuenta_cobrarValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Cuentas por cobrar
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="reporte_cuenta_cobrar"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Cuentas por cobrar
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${reporte_gastoValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Gastos
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="reporte_gasto"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Gastos
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${reporte_productos_mas_vendidosValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Productos más vendidos
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="reporte_productos_mas_vendidos"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Productos más vendidos
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${reporte_mejores_clientesValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Mejores clientes
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="reporte_mejores_clientes"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Mejores clientes
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${reporte_total_invertidoValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Total invertido
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="reporte_total_invertido"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Total invertido
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${reporte_ganancia_brutaValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Ganancia bruta
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="reporte_ganancia_bruta"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Ganancia bruta
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${reporte_despachosValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Despachos
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="reporte_despachos"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Despachos
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${reporte_historial_preciosValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Historial de precios
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="reporte_historial_precios"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Historial de precios
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${reporte_movimientosValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Historial de inventario
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="reporte_movimientos"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Historial de inventario
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${reporte_historial_bajas_salidasValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Historial de bajas y salidas
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="reporte_historial_bajas_salidas"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Historial de bajas y
                                                        salidas
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${reporte_descuentos_recargosValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Descuentos y recargos
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="reporte_descuentos_recargos"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Descuentos y recargos
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${permiso_grafica_ventasValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Gráfica de Ventas
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="permiso_grafica_ventas"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Gráfica de Ventas
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    {lectura ? (
                                        <div className="w-100 p-3 font-weight-bold">
                                            <span
                                                className={`fa fa-check fa-lg mr-2  ${configuracion
                                                        ? "naranja"
                                                        : "transparente"
                                                    }`}
                                            />
                                            Configuración
                                        </div>
                                    ) : (
                                        <div className="checkbox c-checkbox p-3">
                                            <label className="needsclick">
                                                <input
                                                    type="checkbox"
                                                    onChange={() =>
                                                        changeValue(
                                                            "configuracion",
                                                            "UsuarioCrear"
                                                        )
                                                    }
                                                    checked={configuracion}
                                                />
                                                <span className="fa fa-check" />
                                                Configuración
                                            </label>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${conf_tiendaValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Tienda
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="conf_tienda"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Tienda
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${conf_usuarioValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Usuarios
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="conf_usuario"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Usuarios
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${conf_sucursalValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Sucursales
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="conf_sucursal"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Sucursales
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${conf_clienteValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Clientes
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="conf_cliente"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Clientes
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${conf_proveedorValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Proveedores
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="conf_proveedor"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Proveedores
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${conf_cajaValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Cajas
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="conf_caja"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Cajas
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12">
                                            {lectura ? (
                                                <div className="w-100 ml-3 font-weight-bold">
                                                    <span
                                                        className={`fa fa-check fa-lg mr-2  ${conf_destino_salidaValue
                                                                ? "naranja"
                                                                : "transparente"
                                                            }`}
                                                    />
                                                    Destinos de salida
                                                </div>
                                            ) : (
                                                <div className="checkbox c-checkbox ml-3">
                                                    <label className="needsclick">
                                                        <Field
                                                            name="conf_destino_salida"
                                                            component="input"
                                                            type="checkbox"
                                                        />
                                                        <span className="fa fa-check" />
                                                        Destinos de salida
                                                    </label>
                                                </div>
                                            )}
                                        </div>

                                        {utiliza_fel && (
                                            <div className="col-12">
                                                {lectura ? (
                                                    <div className="w-100 ml-3 font-weight-bold">
                                                        <span
                                                            className={`fa fa-check fa-lg mr-2  ${conf_adicionales_nota_felValue
                                                                    ? "naranja"
                                                                    : "transparente"
                                                                }`}
                                                        />
                                                        Adicionales nota de
                                                        crédito
                                                    </div>
                                                ) : (
                                                    <div className="checkbox c-checkbox ml-3">
                                                        <label className="needsclick">
                                                            <Field
                                                                name="conf_adicionales_nota_fel"
                                                                component="input"
                                                                type="checkbox"
                                                            />
                                                            <span className="fa fa-check" />
                                                            Adicionales nota de
                                                            crédito
                                                        </label>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="d-flex justify-content-center flex-row">
                            <Link
                                className="btn btn-tertiary m-1 align-self-center"
                                to="/configuracion/usuarios"
                            >
                                <img src={icons.cancelar} alt="" />
                                Cancelar
                            </Link>
                            <button
                                type="submit"
                                className="btn btn-primary m-1 align-self-center"
                            >
                                <img src={icons.save} alt="" />
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

let UsuariosCrearForm = reduxForm({
    // a unique name for the form
    form: "UsuarioCrear",
    validate: (data) => {
        const sucursales = validatorFromFunction(() => {
            if (data.es_global) {
                return true;
            }
            let valid = false;
            data.sucursales.forEach((item) => {
                if (item[Object.keys(item)[0]]) valid = true;
            });
            return valid;
        });
        return validate(data, {
            username: validators.exists()("Campo requerido"),
            es_global: validators.exists()("Campo requerido"),
            password: validators.exists()("Campo requerido"),
            first_name: validators.exists()("Campo requerido"),
            sucursales: sucursales()("Campo requerido"),
            rol: validators.exists()("Campo requerido"),
        });
    },
})(Form);

//  Decorador para leer los valores del form
const selector = formValueSelector("UsuarioCrear");
UsuariosCrearForm = connect((state) => {
    const rolValue = selector(state, "rol");

    //  Valores de checkbox de permisos
    const esGlobal = selector(state, "es_global");
    const dashboardValue = selector(state, "dashboard");
    const perfilValue = selector(state, "perfil");
    const bodegaValue = selector(state, "bodega");
    const punto_ventaValue = selector(state, "punto_venta");
    const productoValue = selector(state, "producto");
    const gastoValue = selector(state, "gasto");
    const bitacoraValue = selector(state, "bitacora");
    const reporte_balance_generalValue = selector(
        state,
        "reporte_balance_general"
    );
    const reporte_apertura_cierreValue = selector(
        state,
        "reporte_apertura_cierre"
    );
    const reporte_bodegaValue = selector(state, "reporte_bodega");
    const reporte_ventaValue = selector(state, "reporte_venta");
    const reporte_devolucionValue = selector(state, "reporte_devolucion");
    const reporte_cuenta_cobrarValue = selector(state, "reporte_cuenta_cobrar");
    const reporte_gastoValue = selector(state, "reporte_gasto");
    const reporte_productos_mas_vendidosValue = selector(
        state,
        "reporte_productos_mas_vendidos"
    );
    const reporte_mejores_clientesValue = selector(
        state,
        "reporte_mejores_clientes"
    );
    const reporte_total_invertidoValue = selector(
        state,
        "reporte_total_invertido"
    );
    const reporte_ganancia_brutaValue = selector(
        state,
        "reporte_ganancia_bruta"
    );
    const reporte_despachosValue = selector(state, "reporte_despachos");
    const reporte_historialPreciosValue = selector(
        state,
        "reporte_hisotrial_precios"
    );
    const reporte_historial_bajas_salidasValue = selector(
        state,
        "reporte_historial_bajas_salidas"
    );
    const reporte_descuentos_recargosValue = selector(
        state,
        "reporte_descuentos_recargos"
    );
    const reporte_movimientosValue = selector(state, "reporte_movimientos");
    const conf_tiendaValue = selector(state, "conf_tienda");
    const conf_usuarioValue = selector(state, "conf_usuario");
    const conf_sucursalValue = selector(state, "conf_sucursal");
    const conf_clienteValue = selector(state, "conf_cliente");
    const conf_proveedorValue = selector(state, "conf_proveedor");
    const conf_cajaValue = selector(state, "conf_caja");
    const conf_destino_salidaValue = selector(state, "conf_destino_salida");
    const conf_adicionales_nota_felValue = selector(
        state,
        "conf_adicionales_nota_fel"
    );
    const despacho_despachosValue = selector(state, "despacho_despachos");
    const despacho_recepcionesValue = selector(state, "despacho_recepciones");
    const despacho_recepciones_orden_compraValue = selector(
        state,
        "despacho_recepciones_orden_compra"
    );
    const punto_venta_mostradorValue = selector(state, "punto_venta_mostrador");
    const punto_venta_cajaValue = selector(state, "punto_venta_caja");
    const orden_compraValue = selector(state, "orden_compra");
    const orden_onlineValue = selector(state, "orden_online");
    const igreso_mercaderiaValue = selector(state, "igreso_mercaderia");
    const baja_mercaderiaValue = selector(state, "baja_mercaderia");
    const salida_mercaderiaValue = selector(state, "salida_mercaderia");
    const reajuste_mercaderiaValue = selector(state, "reajuste_mercaderia");
    const permiso_grafica_ventasValue = selector(state, "permiso_grafica_ventas");
    return {
        rolValue,
        esGlobal,
        dashboardValue,
        perfilValue,
        bodegaValue,
        punto_ventaValue,
        productoValue,
        gastoValue,
        bitacoraValue,
        reporte_balance_generalValue,
        reporte_apertura_cierreValue,
        reporte_bodegaValue,
        reporte_ventaValue,
        reporte_devolucionValue,
        reporte_cuenta_cobrarValue,
        reporte_gastoValue,
        reporte_productos_mas_vendidosValue,
        reporte_mejores_clientesValue,
        reporte_total_invertidoValue,
        reporte_ganancia_brutaValue,
        reporte_despachosValue,
        reporte_historialPreciosValue,
        reporte_movimientosValue,
        reporte_historial_bajas_salidasValue,
        reporte_descuentos_recargosValue,
        conf_tiendaValue,
        conf_usuarioValue,
        conf_sucursalValue,
        conf_clienteValue,
        conf_proveedorValue,
        conf_cajaValue,
        conf_destino_salidaValue,
        conf_adicionales_nota_felValue,
        despacho_despachosValue,
        despacho_recepcionesValue,
        despacho_recepciones_orden_compraValue,
        punto_venta_mostradorValue,
        punto_venta_cajaValue,
        orden_compraValue,
        orden_onlineValue,
        igreso_mercaderiaValue,
        baja_mercaderiaValue,
        salida_mercaderiaValue,
        reajuste_mercaderiaValue,
        permiso_grafica_ventasValue,
        initialValues: {
            sucursales: [],
        },
    };
})(UsuariosCrearForm);

export default UsuariosCrearForm;

import React, { Component } from "react";
import { getMe, setRuta} from "./redux/modules/usuario/usuario";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import Breadcrumbs from "./common/components/Breadcrumbs/Breadcrumbs";

// maquetado base
import Navbar from "./common/components/Navbar/NavbarContainer";
import Sidebar from "./common/components/Sidebar/Sidebar";
import { ZohoSalesIQ } from "./common/components/ZohoSalesIQ";
// funciona para determinar si puede acceder a la vista
function isAuthenticated() {
    return localStorage.getItem("token");
}

class PrivateRouteBase extends Component {
    componentWillMount(props) {
        this.props.setRuta(window.location.href);
        this.props.getMe();
    }

    render() {
        const { component: Component, navbarCollapse , ...rest } = this.props;
        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated() ? (
                        <div>
                            <Navbar navbarCollapse={navbarCollapse} />
                            <ZohoSalesIQ/>
                            <Sidebar navbarCollapse={navbarCollapse} />
                            <div className={`content-wrapper ${navbarCollapse ? 'content-wrapper-reduced' : ''}`}>
                                {rest.noBreadcrumbs ? "" : <Breadcrumbs ruta={props.location} match={props.match} />}
                                <Component {...props} />
                            </div>
                        </div>
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
                }
            />
        );
    }
}

const mstp = state => ({navbarCollapse: state.dashboard.navbarCollapse});

const mdtp = { getMe, setRuta };

const ProtectedRoute = connect(
    mstp,
    mdtp
)(PrivateRouteBase);

export default ProtectedRoute;

/* ToolBarHistorial.jsx */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// Asegúrate de importar los mismos estilos que el toolbar original
import "./toolbar.css";

import { icons } from "icons";
import { DropDownMenu, DropDownDirection } from "react-dropdown-advanced";

// Importa los mismos componentes que usas para filtros
import DatePicker from "Utils/DatePicker";
import Select from "react-select";

/**
 * Barra de herramientas para el Historial.
 * Copia del estilo del toolbar original, pero con los filtros
 * y totales tal como se aprecia en la imagen (kilataje, sucursal, etc.).
 */
const ToolBarHistorial = (props) => {
  const {
    // Date
    fecha,
    onChangeFecha, // Función para actualizar la fecha
    // Radio "Filtrar por"
    filtro,
    onFiltroChange,

    // Selects
    kilataje,
    onKilatajeChange,
    sucursal,
    onSucursalChange,

    // Búsqueda
    search,
    onSearchChange,

    // Totales
    totalDiferencia,
    totalPrecio,

    // Botones
    onImprimir,        // Lógica para botón Imprimir
    descargarListado,  // Lógica para descargar
    estado_descarga,

    // Por si quieres un título
    titulo,

    // Ayuda
    itemsAyuda,
    actionAyuda,
  } = props;

  return (
    <div className="react-bs-table-tool-bar">
      {/* Primera fila: Fecha, Totales, Imprimir */}
      <div className="row">
        <div className="col-12">
          <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
            {/* Puede ser un título o un DatePicker */}
            <div className="d-flex align-items-center">
              {/** EJEMPLO: Si manejas una sola fecha */}
              <DatePicker
                value={fecha}
                onChange={onChangeFecha}
                // maxDate={new Date()} // si deseas limitar
              />
            </div>

            {/* Totales + Imprimir */}
            <div className="d-flex align-items-center mt-2 mt-sm-0">
              {/* Total Diferencia */}
              <div
                style={{
                  backgroundColor: "#FFF3D5",
                  borderRadius: "4px",
                  padding: "0.3rem 0.6rem",
                  marginRight: "1rem",
                  minWidth: "80px",
                  textAlign: "center",
                }}
              >
                <small style={{ display: "block" }}>Total Diferencia</small>
                <strong>{totalDiferencia}</strong>
              </div>
              {/* Total Precio */}
              <div
                style={{
                  backgroundColor: "#FFF3D5",
                  borderRadius: "4px",
                  padding: "0.3rem 0.6rem",
                  marginRight: "1rem",
                  minWidth: "100px",
                  textAlign: "center",
                }}
              >
                <small style={{ display: "block" }}>Total Precio</small>
                <strong>{totalPrecio}</strong>
              </div>

              {/* Botón Imprimir (puede ser Link o button) */}
              <button
                className="btn btn-light"
                onClick={onImprimir}
                style={{ minWidth: "90px" }}
              >
                <i className="fa fa-print" aria-hidden="true" /> Imprimir
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Segunda fila: Radio Filtrar por, kilataje, sucursal, buscar y descargar */}
      <div className="row mt-3">
        <div className="col-12 d-flex flex-wrap align-items-center">
          {/* "Filtrar por" */}
          <div className="mr-3 d-flex align-items-center flex-wrap">
            <span className="mr-2">Filtrar por</span>
            {/* Radio: Todos */}
            <label className="radio c-radio c-radio-nofont mr-3">
              <input
                type="radio"
                name="filtro"
                value="todos"
                checked={filtro === "todos"}
                onChange={() => onFiltroChange("todos")}
              />
              <span /> Todos
            </label>
            {/* Radio: Existencia en inventario */}
            <label className="radio c-radio c-radio-nofont mr-3">
              <input
                type="radio"
                name="filtro"
                value="con_inventario"
                checked={filtro === "con_inventario"}
                onChange={() => onFiltroChange("con_inventario")}
              />
              <span /> Existencia en inventario
            </label>
            {/* Radio: Sin existencia */}
            <label className="radio c-radio c-radio-nofont mr-3">
              <input
                type="radio"
                name="filtro"
                value="sin_inventario"
                checked={filtro === "sin_inventario"}
                onChange={() => onFiltroChange("sin_inventario")}
              />
              <span /> Sin existencia
            </label>
            {/* Radio: Existencia negativa */}
            <label className="radio c-radio c-radio-nofont mr-3">
              <input
                type="radio"
                name="filtro"
                value="inventario_negativo"
                checked={filtro === "inventario_negativo"}
                onChange={() => onFiltroChange("inventario_negativo")}
              />
              <span /> Existencia negativa
            </label>
          </div>

          {/* Kilataje */}
          <div className="mr-3" style={{ width: "180px" }}>
            <Select
              placeholder="Kilataje"
              value={kilataje}
              onChange={(opt) => onKilatajeChange(opt ? opt.value : null)}
              // options={...}  Ajusta tus opciones {value,label}
            />
          </div>

          {/* Sucursal */}
          <div className="mr-3" style={{ width: "180px" }}>
            <Select
              placeholder="Sucursal"
              value={sucursal}
              onChange={(opt) => onSucursalChange(opt ? opt.value : null)}
              // options={...}
            />
          </div>

          {/* Buscar */}
          <div className="toolbar-search mr-3" style={{ width: "200px" }}>
            <input
              type="text"
              className="form-control"
              placeholder="Buscar"
              value={search}
              onChange={(e) => onSearchChange(e.target.value)}
            />
            <span className="fa fa-search icon-search" />
          </div>

          {/* Botón Descargar */}
          <button
            className="btn btn-secondary"
            disabled={estado_descarga}
            onClick={descargarListado}
          >
            {estado_descarga ? (
              <i className="fa fa-spinner fa-pulse" />
            ) : (
              <i className="fa fa-download" />
            )}
            &nbsp;Descargar
          </button>

          {/* Botón de Ayuda (opcional) */}
          {itemsAyuda && actionAyuda && (
            <div className="toolbar-search ml-2">
              <button
                className="btn btn-ayuda btn-ayuda-flotante fnt-16 d-flex align-items-center justify-content-center"
                style={{
                  width: "2rem",
                  height: "2rem",
                }}
              >
                <img
                  src={icons.ayuda}
                  alt="ayuda"
                  title="Ayuda"
                  style={{
                    margin: "0",
                    height: "1.1rem",
                  }}
                />
                <DropDownMenu
                  items={itemsAyuda}
                  onClick={actionAyuda}
                  direction={DropDownDirection.DownLeft}
                  className="fnt-10"
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ToolBarHistorial.propTypes = {
  fecha: PropTypes.string,
  onChangeFecha: PropTypes.func,
  filtro: PropTypes.string,
  onFiltroChange: PropTypes.func,
  kilataje: PropTypes.any,
  onKilatajeChange: PropTypes.func,
  sucursal: PropTypes.any,
  onSucursalChange: PropTypes.func,
  search: PropTypes.string,
  onSearchChange: PropTypes.func,
  totalDiferencia: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalPrecio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onImprimir: PropTypes.func,
  descargarListado: PropTypes.func,
  estado_descarga: PropTypes.bool,
  titulo: PropTypes.string,
  itemsAyuda: PropTypes.array,
  actionAyuda: PropTypes.func,
};

export default ToolBarHistorial;

import { handleActions } from "redux-actions";
import _ from "lodash";
import { api } from "api";
import Swal from "sweetalert2";
import { ToastStore } from "react-toasts";
import { actions as printActions } from "../print/print";

// ------------------------------------
// Action Types
// ------------------------------------
const ACTION_TYPES = {
  PRODUCTOS: "INVENTARIO/PRODUCTOS",
  TODOS_PRODUCTOS: "INVENTARIO/TODOS_PRODUCTOS",
  PRODUCTOS_FILTRADOS: "INVENTARIO/PRODUCTOS_FILTRADOS",
  LOADER: "INVENTARIO/LOADER",
  PAGE: "INVENTARIO/PAGE",
  ORDERING: "INVENTARIO/ORDERING",
  SEARCH: "INVENTARIO/SEARCH",
  FILTRO: "INVENTARIO/FILTRO",
  FAMILIAS: "INVENTARIO/FAMILIAS",
  SET_IMPRESION: "CAJA_SET_IMPRESION",
  QUITAR_SIN_CONTAR: "INVENTARIO/QUITAR_SIN_CONTAR",
  SET_SIN_CONTAR: "INVENTARIO/SET_SIN_CONTAR",
  PAGE_SIN_CONTAR: "INVENTARIO/PAGE_SIN_CONTAR",
  SEARCH_SIN_CONTAR: "INVENTARIO/SEARCH_SIN_CONTAR",
  ACTUALIZAR_EXISTENCIAS: "INVENTARIO/ACTUALIZAR_EXISTENCIAS",
  SET_CONTEO_PARCIAL: "INVENTARIO/SET_CONTEO_PARCIAL",
  CLEAR_CONTEO_PARCIAL: "INVENTARIO/CLEAR_CONTEO_PARCIAL",
  PAGE_CONTEO: "INVENTARIO/PAGE_CONTEO",
  ORDERING_CONTEO: "INVENTARIO/ORDERING_CONTEO",
  SEARCH_CONTEO: "INVENTARIO/SEARCH_CONTEO",
  SET_TOTAL_FRACCIONES: "INVENTARIO/SET_TOTAL_FRACCIONES",
  SET_TOTAL_CONTEO: "INVENTARIO/SET_TOTAL_CONTEO",
  TOTALES_LOADING: "INVENTARIO/TOTALES_LOADING",
  SET_TOTALES_GLOBALES: "INVENTARIO/SET_TOTALES_GLOBALES",
  TOTALES_ERROR: "INVENTARIO/TOTALES_ERROR",
  SET_FRACCIONES_NO_CONTEADAS: "INVENTARIO/SET_FRACCIONES_NO_CONTEADAS",
  SET_CONTEOS_PROCESADOS: "INVENTARIO/SET_CONTEOS_PROCESADOS",
};

const endpointBodegas = "bodegas";
const endpointConteo = "conteo_inventario"; 

// ------------------------------------
// Thunks y Acciones Principales
// ------------------------------------


export const fetchTotalFracciones = (sucursalId) => (dispatch) => {
  if (!sucursalId) return;
  dispatch(setLoader(true));
  
  return api.get(endpointConteo, { sucursal: sucursalId, page_size: 1 })
    .then((resp) => {
      dispatch({
        type: ACTION_TYPES.SET_TOTAL_FRACCIONES,
        payload: resp.count,
      });
    })
    .finally(() => dispatch(setLoader(false)));
};


export const FetchTotalSinContar = (sucursalId) => (dispatch) => {
  if (!sucursalId) return;
  dispatch(setLoader(true));
  return api
    .get("bodegas/contar_fracciones", {sucursal: sucursalId})
    .then((resp) => {
      console.log(resp)
      dispatch({
        type: ACTION_TYPES.SET_TOTAL_CONTEO,
        payload: resp.count,
      });
    })
    .finally(() => dispatch(setLoader(false)));
};

export const fetchConteoParcial = (sucursalId, page = 1, ordering = "-actualizado_en", search = "", familia = null, kilataje = null) => (dispatch, getStore) => {
  if (!sucursalId) return;
  dispatch({ type: ACTION_TYPES.LOADER, loader: true });
  const params = {
    sucursal_id: sucursalId,
    page,
    ordering,
    search,
    familia,
    kilataje,
  };
  const store = getStore().inventario;
  const pageSinContar = store.pageSinContar;
  api.get(endpointConteo, params)
    .then((resp) => {
      dispatch({ type: ACTION_TYPES.SET_CONTEO_PARCIAL, payload: resp });
      dispatch({ type: ACTION_TYPES.PAGE_CONTEO, page: page });
      dispatch({ type: ACTION_TYPES.ORDERING_CONTEO, ordering: ordering });
      dispatch({ type: ACTION_TYPES.SEARCH_CONTEO, search: search });
      dispatch(fetchTotalFracciones(sucursalId));
      dispatch(FetchTotalSinContar(sucursalId));
    })
    .catch((err) => {
      console.log(err)
    })
    .finally(() => {
      dispatch({ type: ACTION_TYPES.LOADER, loader: false });
      dispatch(listarSinContar(pageSinContar, "-id"));
      
    });
};

export const updateConteoParcial = (sucursalId, fraccionId, cantidad) => (dispatch, getStore) => {
  if (!sucursalId || !fraccionId) return Promise.resolve();
  dispatch({ type: ACTION_TYPES.LOADER, loader: true });
  const store = getStore().inventario;
  
  return api
    .post(endpointConteo + "/actualizar_cantidad", {
      sucursal_id: sucursalId,
      fraccion_id: fraccionId,
      cantidad_fisica: cantidad,
    })
    .then(() => {
      const updatedStore = getStore().inventario;
      const currentPage = updatedStore.pageSinContar;
      const totalItems = updatedStore.dataSinContar.count - 1;
      const totalPages = Math.ceil(totalItems / 10);

      const newPage = currentPage > totalPages ? totalPages : currentPage;
      
      dispatch(listarSinContar(newPage, "-id"));
      dispatch(fetchConteoParcial(sucursalId, store.pageConteo, store.orderingConteo, store.searchConteo));
      dispatch(fetchTotalesGlobales());
      dispatch(FetchTotalSinContar(sucursalId));
      dispatch(fetchTotalFracciones(sucursalId));
    })
    .catch(() => {
      Swal.fire("Error", "No se pudo actualizar la cantidad", "error");
    })
    .finally(() => {
      dispatch({ type: ACTION_TYPES.LOADER, loader: false });
    });
};

export const finalizarConteo = (sucursalId, motivo) => (dispatch) => {
  if (!sucursalId) return;
  dispatch({ type: ACTION_TYPES.LOADER, loader: true });
  const data = {
    sucursal_id: sucursalId,
    motivo: motivo || "Conteo de inventario",
  };
  return api
    .post(endpointConteo + "/finalizar", data)
    .then((resp) => {
      Swal.fire("Exito", "Conteo finalizado correctamente", "success");
      dispatch({
        type: ACTION_TYPES.SET_FRACCIONES_NO_CONTEADAS,
        payload: resp.fracciones_no_conteadas || []
      });
      dispatch({
        type: ACTION_TYPES.SET_CONTEOS_PROCESADOS,
        payload: resp.conteos_procesados || []
      });
      dispatch({ type: ACTION_TYPES.CLEAR_CONTEO_PARCIAL });
    })
    .catch(() => {
      Swal.fire("Error", "Error al finalizar el conteo", "error");
    })
    .finally(() => {
      dispatch(fetchTotalesGlobales());
      dispatch(FetchTotalSinContar(sucursalId));
      dispatch(fetchTotalFracciones(sucursalId));
      dispatch(impresionGeneral(1,'Reportes'));
      dispatch({ type: ACTION_TYPES.LOADER, loader: false });
    });
};

export const fetchTotalesGlobales = () => (dispatch) => {
  dispatch({ type: ACTION_TYPES.TOTALES_LOADING });
  return api.get("conteo_inventario/totales")
    .then(response => {
      dispatch({
        type: ACTION_TYPES.SET_TOTALES_GLOBALES,
        payload: response
      });
    })
    .catch(error => {
      dispatch({ type: ACTION_TYPES.TOTALES_ERROR });
      throw error;
    });
};

/**
 * Listar familias
 */
export const listarFamilias = () => (dispatch) => {
  dispatch({ type: ACTION_TYPES.LOADER, loader: true });
  api.get("/familia")
    .then((response) => {
      dispatch({ type: ACTION_TYPES.FAMILIAS, payload: response.results });
    })
    .catch((error) => {
      console.error("Error cargando familias:", error);
    })
    .finally(() => {
      dispatch({ type: ACTION_TYPES.LOADER, loader: false });
    });
};

export const listarSinContar = (page, ordering) => (dispatch, getStore) => {
  dispatch(setLoader(true));
  const store = getStore();
  const filtros = store.inventario.filtros;
  const config = store.configuracion.config

  const params = {
    page,
    ordering,
    search: filtros.producto,
    familia: filtros.familia,
    kilataje: filtros.kilataje,
  };

  if (config.conteo_inventario_cero){
    params.inventario_cero = config.conteo_inventario_cero
  }
  if (config.conteo_inventario_negativo){
    params.inventario_negativo = config.conteo_inventario_negativo
  }
  return api
    .get("bodegas/listar_sin_contar", params)
    .then((resp) => {
      const pageSize = 10;
      const totalPages = Math.ceil(resp.count / pageSize);
      let adjustedPage = page;

      if (resp.results.length === 0 && page > 1) {
        adjustedPage = page - 1;
      }
      else if (page > totalPages && totalPages > 0) {
        adjustedPage = totalPages;
      }

      if (adjustedPage !== page) {
        dispatch({ type: ACTION_TYPES.PAGE_SIN_CONTAR, page: adjustedPage });
        return api.get("bodegas/listar_sin_contar", { 
          ...params, 
          page: adjustedPage 
        }).then((newResp) => {
          dispatch({ type: ACTION_TYPES.SET_SIN_CONTAR, payload: newResp })
          dispatch(FetchTotalSinContar(sucursalId));
        });
        
      } else {
        dispatch({ type: ACTION_TYPES.SET_SIN_CONTAR, payload: resp });
        dispatch({ type: ACTION_TYPES.PAGE_SIN_CONTAR, page: page });

      }
    })
    .catch(() => {
      Swal.fire("Error", "Error al listar productos sin contar", "error");
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};


export const buscarProductoPorCodigo = (codigoBarras) => (dispatch) => {
  dispatch(setLoader(true));
  if (!codigoBarras) {
    dispatch(setLoader(false));
    return Promise.resolve(null);
  }
  const params = {
    page_size: 1,
    codigo_barras: codigoBarras.trim(),
  };
  return api
    .get(endpointBodegas + "/buscar_por_codigo", params)
    .then((resp) => (resp ? resp : null))
    .catch(() => null)
    .finally(() => {
      dispatch(setLoader(false));
    });
};

export const actualizarExistencias = () => (dispatch, getStore) => {
  const store = getStore().inventario;
  const seleccionados = store.seleccionados;
  const ids = seleccionados.map((item) => item.fraccionId);
  if (!ids.length) return Promise.resolve();

  dispatch(setLoader(true));
  return api
    .post(endpointBodegas + "/obtener_existencias", { ids: ids })
    .then((response) => {
      if (response && response.results) {
        dispatch({
          type: ACTION_TYPES.ACTUALIZAR_EXISTENCIAS,
          payload: response.results,
        });
      }
    })
    .catch(() => {
      Swal.fire("Error", "Error al actualizar existencias", "error");
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};

export const quitarConteo = (fraccionId) => (dispatch, getStore) => {
  const sucursalId = localStorage.getItem("sucursal");
  if (!sucursalId) {
    Swal.fire("Error", "No hay sucursal seleccionada", "error");
    return Promise.resolve();
  }
  dispatch(setLoader(true));
  return api
    .post(endpointConteo + "/quitar", { fraccion_id: fraccionId, sucursal_id: parseInt(sucursalId) })
    .then(() => {
      const store = getStore().inventario;
      dispatch(fetchConteoParcial(sucursalId, store.pageConteo, store.orderingConteo, store.searchConteo));
      dispatch(fetchTotalesGlobales()); 
      dispatch(FetchTotalSinContar(sucursalId));
      dispatch(fetchTotalFracciones(sucursalId));

    })
    .catch(() => {
      Swal.fire("Error", "No se pudo quitar el producto del conteo", "error");
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};


// ------------------------------------
// Helpers
// ------------------------------------
export const setLoader = (loader) => ({
  type: ACTION_TYPES.LOADER,
  loader,
});

export const filtroChange = (payload) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.FILTRO, payload: payload });
};


export const setVentaImpresion = (venta_impresion) => ({
  type: ACTION_TYPES.SET_IMPRESION,
  venta_impresion: venta_impresion,
});

export const impresionGeneral = (id, seccionImpresion) => (dispatch) => {
  dispatch(setLoader(true));
  dispatch(setVentaImpresion(null));
  dispatch(printActions.print(seccionImpresion));
  dispatch(setLoader(false));
};
export const updateSearchConteo = (search) => (dispatch) => {
  dispatch({ type: ACTION_TYPES.SEARCH_CONTEO, search });
  dispatch({ type: ACTION_TYPES.PAGE_CONTEO, page: 1 }); 
};
// ------------------------------------
// Initial State
// ------------------------------------
const initialState = {
  data: { results: [], count: 0 },
  dataSinContar: { results: [], count: 0 },
  pageSinContar: 1,
  searchSinContar: "",
  totalesGlobales: null,
  totalesLoading: false,
  conteoParcial: { results: [], count: 0 },
  pageConteo: 1,
  orderingConteo: "-actualizado_en",
  searchConteo: "",
  loader: false,
  page: 1,
  ordering: "-creado",
  search: "",
  filtros: { producto: "", familia: "", kilataje: "" },
  familias: [],
  seleccionados: [],
  totalFraccionesSucursal: 0,
  totalConteoGlobal: 0,
  fracciones_no_conteadas: [],
  conteos_procesados: [],
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(
  {
    [ACTION_TYPES.PRODUCTOS_FILTRADOS]: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
    [ACTION_TYPES.FAMILIAS]: (state, { payload }) => ({
      ...state,
      familias: payload,
    }),
    [ACTION_TYPES.FILTRO]: (state, { payload }) => ({
      ...state,
      filtros: { ...state.filtros, ...payload },
    }),
    [ACTION_TYPES.LOADER]: (state, { loader }) => ({
      ...state,
      loader,
    }),
    [ACTION_TYPES.PAGE]: (state, { page }) => ({
      ...state,
      page,
    }),
    [ACTION_TYPES.ORDERING]: (state, { ordering }) => ({
      ...state,
      ordering,
    }),
    [ACTION_TYPES.SEARCH]: (state, { search }) => ({
      ...state,
      search,
    }),
    [ACTION_TYPES.SET_SIN_CONTAR]: (state, { payload }) => ({
      ...state,
      dataSinContar: payload,
    }),
    [ACTION_TYPES.PAGE_SIN_CONTAR]: (state, { page }) => ({
      ...state,
      pageSinContar: page,
    }),
    [ACTION_TYPES.SEARCH_SIN_CONTAR]: (state, { search }) => ({
      ...state,
      searchSinContar: search,
    }),
    [ACTION_TYPES.ACTUALIZAR_EXISTENCIAS]: (state, { payload }) => {
      const nuevosSeleccionados = state.seleccionados.map((prod) => {
        const actualizado = payload.find((p) => p.id === prod.fraccionId);
        return actualizado
          ? { ...prod, existenciaSistema: actualizado.total_inventario }
          : prod;
      });
      return { ...state, seleccionados: nuevosSeleccionados };
    },
    [ACTION_TYPES.SET_CONTEO_PARCIAL]: (state, { payload }) => ({
      ...state,
      conteoParcial: payload,
    }),
    [ACTION_TYPES.CLEAR_CONTEO_PARCIAL]: (state) => ({
      ...state,
      conteoParcial: { results: [], count: 0 },
    }),
    [ACTION_TYPES.PAGE_CONTEO]: (state, { page }) => ({
      ...state,
      pageConteo: page,
    }),
    [ACTION_TYPES.ORDERING_CONTEO]: (state, { ordering }) => ({
      ...state,
      orderingConteo: ordering,
    }),
    [ACTION_TYPES.SET_TOTAL_FRACCIONES]: (state, { payload }) => {
      return {
        ...state,
        totalFraccionesSucursal: payload,
      };
    },
    [ACTION_TYPES.SET_TOTAL_CONTEO]: (state, { payload }) => {
      return {
        ...state,
        totalConteoGlobal: payload,
      };
    },
    [ACTION_TYPES.SEARCH_CONTEO]: (state, { search }) => ({
      ...state,
      searchConteo: search,
    }),
    [ACTION_TYPES.TOTALES_LOADING]: (state) => ({
      ...state,
      totalesLoading: true,
    }),
    [ACTION_TYPES.SET_TOTALES_GLOBALES]: (state, { payload }) => ({
      ...state,
      totalesGlobales: payload,
      totalesLoading: false,
    }),
    [ACTION_TYPES.TOTALES_ERROR]: (state) => ({
      ...state,
      totalesLoading: false,
    }),
    [ACTION_TYPES.SET_FRACCIONES_NO_CONTEADAS]: (state, { payload }) => ({
      ...state,
      fracciones_no_conteadas: payload,
    }),
    [ACTION_TYPES.SET_CONTEOS_PROCESADOS]: (state, { payload }) => ({
      ...state,
      conteos_procesados: payload,
    }),
  },
  
  initialState
);

// ------------------------------------
// Exportaciones
// ------------------------------------
export const actions = {
  listarFamilias,
  listarSinContar,
  buscarProductoPorCodigo,
  actualizarExistencias,
  impresionGeneral,
  quitarConteo,
  updateSearchConteo,
  filtroChange,
  setLoader,
  fetchConteoParcial,
  updateConteoParcial,
  finalizarConteo,
  fetchTotalFracciones,
  fetchTotalesGlobales
};

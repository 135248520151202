import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import LoadMask from "Utils/LoadMask/LoadMask";
import TablaConteo from "./TablaConteo";
import TablaSinContar from "./TablaSinContar";
import Swal from "sweetalert2";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { RenderField } from "Utils/renderField/renderField";
import { PrintContainer, PrintComponent } from "Utils/Print";
import MovimientosPDF from "../../Utils/MovimientosPDF";
import ExistenciaRealModal from "./ExistenciaRealModal";


class ListadoInventario extends Component {
  static propTypes = {
    loader: PropTypes.bool.isRequired,
    dataSinContar: PropTypes.object.isRequired,
    pageSinContar: PropTypes.number.isRequired,
    familias: PropTypes.array.isRequired,
    config: PropTypes.object,
    print_state: PropTypes.object,

    conteoParcial: PropTypes.object.isRequired, 
    pageConteo: PropTypes.number.isRequired,
    orderingConteo: PropTypes.string.isRequired,
    searchConteo: PropTypes.string.isRequired,

    listarSinContar: PropTypes.func.isRequired,
    buscarProductoPorCodigo: PropTypes.func.isRequired,
    finalizarConteo: PropTypes.func.isRequired,
    filtroChange: PropTypes.func.isRequired,
    listarFamilias: PropTypes.func.isRequired,
    fetchConteoParcial: PropTypes.func.isRequired,
    updateConteoParcial: PropTypes.func.isRequired,
    actualizarExistencias: PropTypes.func.isRequired,
    updateSearchConteo: PropTypes.func.isRequired,
    quitarConteo: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      nuevoProducto: {
        codigo: "",
        cantidad: 0,
        fraccionId: null,
        nombreProducto: "",
        existenciaSistema: 0,
      },
      sinContarOrdering: "-id",
      showExistenciaModal: false,
      currentRow: null,
      modalError: null,
      searchInputValue: "",
      debouncedSearchValue: ""
    };
  
    this.codigoRef = React.createRef();
    this.cantidadRef = React.createRef();
    this.searchTimeout = null;
  }

  componentDidMount() {
    const sucursalId = localStorage.getItem("sucursal");
    if (!sucursalId) {
      Swal.fire("Error", "No hay sucursal seleccionada", "error");
      return;
    }
    this.fetchTotalesGlobales();
    this.props.listarFamilias();
    this.props.fetchConteoParcial(
      sucursalId,
      this.props.pageConteo,
      this.props.orderingConteo,
      this.props.searchConteo,
      this.props.filtros.familia,
      this.props.filtros.kilataje
    );

    const storedTabIndex = sessionStorage.getItem("inventarioTabIndex");
    if (storedTabIndex !== null) {
      const idx = parseInt(storedTabIndex, 10);
      this.setState({ tabIndex: idx });
    }

    if (this.codigoRef && this.codigoRef.current) {
      this.codigoRef.current.focus();
    }
  }

  
  componentDidUpdate(prevProps, prevState) {
    const { tabIndex } = this.state;
    const { filtros, orderingConteo } = this.props;
    const sucursalId = localStorage.getItem("sucursal");
    if (tabIndex === 0) {

      const searchChanged = prevState.debouncedSearchValue !== this.state.debouncedSearchValue;
      const filtersChanged = (
        prevProps.filtros.familia !== filtros.familia ||
        prevProps.filtros.kilataje !== filtros.kilataje
      );
  
      if (searchChanged || filtersChanged) {
        this.props.fetchConteoParcial(
          sucursalId,
          1,
          orderingConteo,
          this.state.debouncedSearchValue,
          filtros.familia,
          filtros.kilataje
        );
      }
    }
  
    if (tabIndex === 1) {
      const filtersChanged = (
        prevProps.filtros.producto !== filtros.producto ||
        prevProps.filtros.familia !== filtros.familia ||
        prevProps.filtros.kilataje !== filtros.kilataje
      );
  
      if (filtersChanged) {
        console.log('primer if')
        this.props.listarSinContar(1, this.state.sinContarOrdering); 
      }
    }
  }  
  componentWillUnmount() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
  }
  handleTabSelect = (tabIndex) => {
    this.setState({ tabIndex });
    sessionStorage.setItem("inventarioTabIndex", String(tabIndex));
    const sucursalId = localStorage.getItem("sucursal");
  
    if (tabIndex === 0) {
      this.props.fetchConteoParcial(
        sucursalId,
        1,
        this.props.orderingConteo,
        this.props.searchConteo
      );
    } else if (tabIndex === 1) {
      this.props.listarSinContar(1, this.state.sinContarOrdering);
    }
  };
  

handleSearchChange = (value) => {
  this.setState({ searchInputValue: value });
  
  if (this.searchTimeout) {
    clearTimeout(this.searchTimeout);
  }
  
  this.searchTimeout = setTimeout(() => {
    this.setState({ debouncedSearchValue: value }, () => {
      this.props.updateSearchConteo(this.state.debouncedSearchValue);
    });
  }, 300);
};
  getAcumuladoActual = () => {
    const { conteoParcial } = this.props;
    const { fraccionId } = this.state.nuevoProducto;

    if (!fraccionId || !conteoParcial || !conteoParcial.results) return 0;

    for (let i = 0; i < conteoParcial.results.length; i++) {
      const item = conteoParcial.results[i];
      if (item.fraccion && item.fraccion.id === fraccionId) {
        return item.cantidad_fisica;
      }
    }
    return 0;
  };
  handleBuscarCodigo = () => {
    const codigo = this.state.nuevoProducto.codigo;
    if (!codigo || !codigo.trim()) {
      return;
    }
    const sucursalId = localStorage.getItem("sucursal");
    if (!sucursalId) {
      Swal.fire("Error", "No hay sucursal seleccionada", "error");
      return;
    }
    this.props
      .buscarProductoPorCodigo(codigo.trim())
      .then((producto) => {
        if (!producto) {
          Swal.fire("Error", "Producto no encontrado", "error");
          this.resetCampos();
          return;
        }
        const nombreProd =
          (producto.producto && producto.producto.nombre) || "Sin nombre";
        const existenciaSis = producto.total_inventario || 0;
        this.setState({
          nuevoProducto: {
            codigo: producto.codigo_barras ? producto.codigo_barras : "",
            fraccionId: producto.id,
            nombreProducto: nombreProd,
            existenciaSistema: existenciaSis,
            cantidad: 0,
          },
        }, () => {
          if (this.cantidadRef && this.cantidadRef.current) {
            this.cantidadRef.current.focus();
          }
        });
      })
      .catch(() => {
        Swal.fire("Error", "Error consultando producto en servidor", "error");
      });
  };

  handleKeyPressCodigo = (e) => {
    if (e.key === "Enter") {
      this.handleBuscarCodigo();
    }
  };

  handleAgregarRemoto = () => {
    const sucursalId = localStorage.getItem("sucursal");
    if (!sucursalId) {
      Swal.fire("Error", "No hay sucursal seleccionada", "error");
      return;
    }
    const { fraccionId, cantidad } = this.state.nuevoProducto;
    if (!fraccionId) {
      Swal.fire("Error", "No hay fracción seleccionada", "error");
      return;
    }
    const cant = Number(cantidad);
    if (isNaN(cant)) {
      Swal.fire("Error", "La cantidad debe ser numérica", "error");
      return;
    }
    this.props.updateConteoParcial(sucursalId, fraccionId, cant)
      .then(() => {
        this.resetCampos();
      });
  };

  resetCampos = () => {
    this.setState(prevState => ({
      nuevoProducto: {
        codigo: "",
        cantidad: 0,
        fraccionId: null,
        nombreProducto: "",
        existenciaSistema: 0,
      }
    }), () => {
      if (this.codigoRef && this.codigoRef.current) {
        this.codigoRef.current.focus();
      }
    });
  };

  onFinalizar = () => {
    const sucursalId = localStorage.getItem("sucursal");
    if (!sucursalId) {
      Swal.fire("Error", "No hay sucursal seleccionada", "error");
      return;
    }
    this.props.finalizarConteo(sucursalId, "Conteo físico de inventario");
  };



  fetchTotalesGlobales = () => {
    const sucursalId = localStorage.getItem("sucursal");
    if (!sucursalId) return;
    
    this.props.fetchTotalesGlobales()
      .catch(() => {
        Swal.fire("Error", "No se pudieron obtener los totales globales", "error");
      });
  }

  calcularTotalesRemoto = () => {
    return this.props.totalesGlobales || {
      totalDiferencia: 0,
      difNegativa: 0,
      difPrecio: 0
    };
  }

  handleAsignarExistencia = (row) => {
    this.setState({
      showExistenciaModal: true,
      currentRow: row,
      modalError: null,
    });
  };

  handleModalConfirm = (value, error) => {
    if (error) {
      this.setState({ modalError: error });
      return;
    }
    const sucursalId = localStorage.getItem("sucursal");
    if (!sucursalId) {
      Swal.fire("Error", "No hay sucursal seleccionada", "error");
      return;
    }
    const row = this.state.currentRow;
    this.setState({
      showExistenciaModal: false,
      currentRow: null,
      modalError: null,
    });
    const valNum = Number(value);
    if (isNaN(valNum)) {
      Swal.fire("Error", "La cantidad debe ser numérica", "error");
      return;
    }
    this.props.updateConteoParcial(sucursalId, row.id, valNum)
};

  handleModalClose = () => {
    this.setState({
      showExistenciaModal: false,
      currentRow: null,
      modalError: null,
    });
  };

  handleQuitar = (idRegistro) => {
    Swal.fire({
      title: "¿Desea quitar el producto?",
      text: "Se eliminará el producto del conteo",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, quitar",
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.value) {
        this.props.quitarConteo(idRegistro);
      }
    });
  };

  renderFiltros = () => {
    const {
      familias,
      filtros,
      config,
      searchConteo
    } = this.props;
    const { tabIndex } = this.state;

    const opcionesFamilia = (familias || []).map(f => ({
      value: f.id,
      label: f.nombre || "Sin nombre"
    }));

    const opcionesKilataje = [
      {
          label: "10k",
          value: 10,
      },
      {
          label: "14k",
          value: 14,
      },
      {
          label: "18k",
          value: 18,
      },
      {
          label: "21k",
          value: 21,
      },
      {
          label: "24k",
          value: 24,
      },
  ];

    return (
      <div className="row mb-3">
        <div className="col-md-4 pr-1">
          <div className="form-group">
            <label className="small text-muted mb-1">
              {tabIndex === 0 ? 'Filtrar productos' : 'Buscar producto'}
            </label>
            {tabIndex === 0 ? (
              // Filtro para CONTEO (usa searchConteo)
              <input
              type="text"
              className="form-control form-control-sm"
              value={this.state.searchInputValue} // Usar el estado interno
              onChange={(e) => this.handleSearchChange(e.target.value)}
              placeholder="Buscar en conteo..."
            />
            ) : (
              // Filtro para SIN CONTAR (usa filtros.producto)
              <input
                type="text"
                className="form-control form-control-sm"
                value={filtros.producto}
                onChange={(e) => this.props.filtroChange({ producto: e.target.value })}
                placeholder="Buscar productos..."
              />
            )}
          </div>
        </div>

        {/* Select Familia - Compartido pero con filtros independientes */}
        <div className="col-md-4 px-1">
          <div className="form-group">
            <label className="small text-muted mb-1">Familia</label>
            <RenderField.renderStaticSelect
              input={{
                value: filtros.familia,
                onChange: (value) => this.props.filtroChange({ familia: value })
              }}
              options={opcionesFamilia}
              valueKey="value"
              labelKey="label"
              placeholder="Todas las familias"
            />
          </div>
        </div>

        {/* Select Kilataje - Solo para Joyería */}
        {(config && config.conf_tipo_negocio === "Joyeria") && (
          <div className="col-md-4 pl-1">
            <div className="form-group">
              <label className="small text-muted mb-1">Kilataje</label>
              <RenderField.renderStaticSelect
                input={{
                  value: filtros.kilataje,
                  onChange: (value) => this.props.filtroChange({ kilataje: value })
                }}
                options={opcionesKilataje}
                valueKey="value"
                labelKey="label"
                placeholder="Todos los kilatajes"
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  renderContenedorFiltros = (children) => (
    <div className="card mb-4 bg-white">
      <div className="card-body p-3">{children}</div>
    </div>
  );

  render() {
    const {
      loader,
      dataSinContar,
      pageSinContar,
      config,
      print_state,
      conteoParcial,
    } = this.props;
    const { nuevoProducto, tabIndex, sinContarOrdering } = this.state;
    const acumuladoActual = this.getAcumuladoActual();
    // Obtener IDs de productos ya contados
    const idsContados = this.props.conteoParcial.results
      ? this.props.conteoParcial.results.map(item => item.fraccion && item.fraccion.id)
      : [];
    // Filtrar productos sin contar
    const sinContarFiltrado = dataSinContar.results
      ? dataSinContar.results.filter(item => !idsContados.includes(item.id))
      : [];
    const totalSinContarReal = sinContarFiltrado.length;
    // Cálculo de totales basado en conteoParcial
    const totales = this.calcularTotalesRemoto();

    const { total_diferencia, dif_negativa, dif_precio } = totales;
    const { totalFraccionesSucursal, totalConteoGlobal } = this.props;

    let progressPercent = 0;
    if (totalConteoGlobal > 0) {
      progressPercent = Math.min(
        Math.round((totalFraccionesSucursal / totalConteoGlobal) * 100),
        100
      );
    }
    


    const sucursalId = localStorage.getItem("sucursal")
    return (
      <div className="row mt">
        <ExistenciaRealModal
          show={this.state.showExistenciaModal}
          onHide={this.handleModalClose}
          rowData={this.state.currentRow}
          onConfirm={this.handleModalConfirm}
          error={this.state.modalError}
        />

        {print_state && print_state.flag && <PrintComponent />}

        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="row mb-3">
            <div className="col-lg-7 col-md-6">
              <Tabs selectedIndex={tabIndex} onSelect={this.handleTabSelect}>
                <TabList className="tab_list d-flex">
                  <Tab
                    className="content-tab-venta flex-grow-1 text-center fnt-14"
                    selectedClassName="content-tab-venta-active"
                  >
                    CONTEO DE PRODUCTO
                  </Tab>
                  <Tab
                    className="content-tab-venta flex-grow-1 text-center fnt-14"
                    selectedClassName="content-tab-venta-active"
                  >
                    SIN CONTAR
                  </Tab>
                </TabList>
              </Tabs>
            </div>

            <div className="col-lg-5 col-md-6 d-flex align-items-end justify-content-end">
              <div className="d-flex align-items-center flex-grow-1">
                <div className="flex-grow-1">
                  <div
                    style={{
                      backgroundColor: "#FFDB6E",
                      height: "42px",
                      width: "150px",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "4px",
                      padding: "0 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                        {progressPercent}%
                      </span>
                      <span
                        style={{
                          fontSize: "13px",
                          marginLeft: "10px",
                          fontWeight: "bold",
                        }}
                      >
                        Progreso
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <Link to="/bodega">
                    <button className="btn btn-secondary mr-2">Regresar</button>
                  </Link>
                  <button className="btn btn-primary"
                    onClick={this.onFinalizar}
                    disabled={config.conteo_inventario_completo && progressPercent < 100}
                  >
                    Finalizar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Tabs selectedIndex={tabIndex} onSelect={this.handleTabSelect}>
            {/* TAB: CONTEO DE PRODUCTO */}
            <TabPanel>
              <div className="card mb-4 bg-white">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-lg-9 pr-4">
                      <div className="row mb-3 align-items-center">
                        <div className="col-md-2 mb-2 pr-1">
                          <label className="small text-muted mb-0">Cód/Barras</label>
                          <input
                            type="text"
                            name="codigo"
                            value={nuevoProducto.codigo}
                            onChange={(e) =>
                              this.setState({
                                nuevoProducto: {
                                  ...nuevoProducto,
                                  codigo: e.target.value,
                                },
                              })
                            }
                            onKeyPress={this.handleKeyPressCodigo}
                            ref={this.codigoRef}
                            className="form-control form-control-sm"
                          />
                        </div>

                        <div className="col-md-3 mb-2 px-1">
                          <label className="small text-muted mb-0">Producto</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            readOnly
                            value={nuevoProducto.nombreProducto}
                          />
                        </div>

                        <div className="col-md-2 mb-2 px-1">
                          <label className="small text-muted mb-0">Exist. Sistema</label>
                          <input
                            type="number"
                            disabled
                            className="form-control form-control-sm bg-light"
                            value={nuevoProducto.existenciaSistema}
                          />
                        </div>

                        <div className="col-md-2 mb-2 px-1">
                          <label className="small text-muted mb-0">Cantidad Real {acumuladoActual > 0 && `acum: ${acumuladoActual}`}
                          </label>
                          <input
                            type="number"
                            ref={this.cantidadRef}
                            className="form-control form-control-sm"
                            value={nuevoProducto.cantidad}
                            onChange={(e) =>
                              this.setState({
                                nuevoProducto: {
                                  ...nuevoProducto,
                                  cantidad: e.target.value,
                                },
                              })
                            }
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                this.handleAgregarRemoto();
                              }
                            }}
                          />
                        </div>

                        <div
                          className="mb-2"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            height: "50px",
                            width: "70px",
                            gap: "5px",
                            alignItems: "end",
                          }}
                        >
                          <button
                            className="btn btn-sm p-0"
                            style={{ width: "32px", height: "32px" }}
                            onClick={() => this.handleAgregarRemoto()}
                            >
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 42 42"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                y="4"
                                width="42"
                                height="38"
                                rx="19"
                                fill="#080808"
                              />
                              <rect
                                width="42"
                                height="38"
                                rx="19"
                                fill="#3C4043"
                              />
                              <path
                                d="M22 13V25M28 19L16 19"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <button
                            className="btn btn-sm p-0"
                            style={{ width: "32px", height: "32px" }}
                            onClick={() => {
                              const sucursalId = localStorage.getItem("sucursal");
                              this.props.listarSinContar(1, this.state.sinContarOrdering);
                              this.props.fetchConteoParcial(
                                sucursalId,
                                this.props.pageConteo,
                                this.props.orderingConteo,
                                this.props.searchConteo,
                                this.props.filtros.familia,
                                this.props.filtros.kilataje
                              );
                              this.props.fetchTotalesGlobales()
                            }}
                          >
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 42 42"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                y="4"
                                width="42"
                                height="38"
                                rx="19"
                                fill="#A9ABAD"
                              />
                              <rect
                                width="42"
                                height="38"
                                rx="19"
                                fill="#EAEAE9"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M24.59 7.29914C24.9771 7.15161 25.4104 7.34578 25.558 7.73283L26.7361 10.8236C26.8494 11.1209 26.7628 11.4574 26.52 11.663C26.2771 11.8687 25.931 11.8987 25.6564 11.738C24.5836 11.1101 23.335 10.75 22 10.75C17.9959 10.75 14.75 13.9959 14.75 18C14.75 19.3217 15.103 20.5588 15.7195 21.6244C15.9269 21.983 15.8043 22.4418 15.4458 22.6492C15.0873 22.8566 14.6285 22.734 14.4211 22.3755C13.6761 21.0877 13.25 19.5925 13.25 18C13.25 13.1675 17.1675 9.24996 22 9.24996C22.9386 9.24996 23.8434 9.39798 24.6918 9.67204L24.1563 8.26709C24.0088 7.88004 24.203 7.44667 24.59 7.29914ZM28.5542 13.3508C28.9127 13.1433 29.3715 13.2659 29.5789 13.6244C30.3239 14.9122 30.75 16.4074 30.75 18C30.75 22.0041 27.7541 25.25 23.75 25.25C22.9958 25.25 22.2626 25.116 21.575 24.8571C21.1842 24.7039 20.7262 24.9035 20.573 25.2942C20.4198 25.685 20.6194 26.143 21.0102 26.2962C21.8975 26.6432 22.9125 26.83 23.75 26.83C28.0826 26.83 31.75 23.1626 31.75 18.83C31.75 16.5209 30.7455 14.387 29.2337 12.8751C28.9932 12.6346 28.6315 12.5122 28.5542 13.3508Z"
                                fill="#212529"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>

                      {/* Filtros para CONTEO de producto */}
                      {this.renderContenedorFiltros(this.renderFiltros())}
                    </div>

                    <div className="col-lg-3 border-left pl-4">
                      {this.props.totalesLoading ? (
                        <div
                          className="d-flex justify-content-center align-items-center bg-light p-3 rounded"
                          style={{ minWidth: "220px", height: "100px" }}
                        >
                          <span className="font-weight-bold">Cargando...</span>
                        </div>
                      ) : (
                        <div
                          className="bg-light p-3 rounded"
                          style={{ minWidth: "220px" }}
                        >
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <span className="small font-weight-bold">Total Diferencia</span>
                            <span
                              className={
                                "font-weight-bold " +
                                (total_diferencia !== 0 ? "text-danger" : "")
                              }
                            >
                              {total_diferencia}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <span className="small font-weight-bold">Dif. Negativa</span>
                            <span className="font-weight-bold text-danger">
                              {dif_negativa}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="small font-weight-bold">Dif. Precio</span>
                            <span className="font-weight-bold">
                              <RenderCurrency value={dif_precio} />
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Tabla de CONTEO DE PRODUCTO con paginación y ordenamiento controlados */}
              <LoadMask loading={loader} dark blur>
                <TablaConteo
                  config={config}
                  datos={conteoParcial.results}
                  totalCount={conteoParcial.count}
                  currentPage={this.props.pageConteo}
                  onPageChange={(page) => {
                    const sucursalId = localStorage.getItem("sucursal");
                    this.props.fetchConteoParcial(
                      sucursalId,
                      page,
                      this.props.orderingConteo,
                      this.props.searchConteo
                    );
                  }}
                  onSort={(sortName, sortOrder) => {
                    const ordering = sortOrder === "desc" ? `-${sortName}` : sortName;
                    const sucursalId = localStorage.getItem("sucursal");
                    this.props.fetchConteoParcial(
                      sucursalId,
                      1,
                      ordering,
                      this.props.searchConteo
                    );
                  }}
                  quitar={this.handleQuitar}
                />
              </LoadMask>
            </TabPanel>

            {/* TAB: SIN CONTAR */}
            <TabPanel>
              {/* Filtros para SIN CONTAR se basan en filtros.producto */}
              {this.renderContenedorFiltros(this.renderFiltros())}
              <LoadMask loading={loader} dark blur>
                {(() => {
                  const originalSinContar =
                    dataSinContar.results || [];
                  return (
                    <TablaSinContar
                      datos={dataSinContar.results || []}
                      totalCount={dataSinContar.count || 0}
                      loading={loader}
                      currentPage={pageSinContar}
                      config={config}
                      onPageChange={(page) =>
                        this.props.listarSinContar(page, sinContarOrdering)
                      }
                      onSort={(sortName, sortOrder) => {
                        const ordering = sortOrder === "desc" ? "-" + sortName : sortName;
                        this.setState({ sinContarOrdering: ordering }, () => {
                          this.props.listarSinContar(1, ordering);
                        });
                      }}
                      onAsignarExistencia={this.handleAsignarExistencia}
                    />

                  );
                })()}
              </LoadMask>
            </TabPanel>
          </Tabs>
        </div>

        {/* Impresión */}
        <div className="w100 print-only">
          <PrintContainer name="Reportes" className="impresion-venta d-table w-100">
            <MovimientosPDF
              conteos_procesados={this.props.conteos_procesados}
              fracciones_no_conteadas	={this.props.fracciones_no_conteadas}
              conf_tipo_negocio={this.props.conf_tipo_negocio}
            />
          </PrintContainer>
        </div>
      </div>
    );
  }
}

export default ListadoInventario;
import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "Utils/LoadMask/LoadMask";
import Select, { Async } from "react-select";
import { api } from "api";
import {
    RenderDateTime,
    RenderMoneda,
    RenderNumero,
    RenderNumeroDecimales,
    RenderPorcentaje,
} from "Utils/renderField/renderTableField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import DatePicker from "Utils/DatePicker";
import moment from "moment/moment";
import { default as GraficaMovimiento } from "../Grafica";

class DetalleVenta extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            fracciones: [],
            fraccion: null,
        };
    }

    onChangeSucursal = (sucursal) => {
        this.props.setSucursal(sucursal);
    };

    onChangeProducto = (producto) => {
        this.props.setProducto(producto);
    };

    onChangePresentacion = (fraccion) => {
        this.props.setFraccion(fraccion);
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.producto !== this.props.producto) {
            const producto = nextProps.producto;
            if (producto) {
                const fraccion = producto.fracciones[0];
                this.props.setFraccion(fraccion);
                this.setState({ fraccion });
            }
        }
    }

    render() {
        const {
            loader,
            sucursal,
            producto,
            fraccion,
            data,
            fecha_inicial,
            fecha_final,
            estado_descarga,
            conf_tipo_negocio,
        } = this.props;
        const options = _.cloneDeep(tableOptions);

        //  Bind
        const { descargarListado } = this.props;
        const getSucursales = (search) => {
            return api
                .get("sucursales", { search })
                .catch(() => {})
                .then((data) => {
                    return { options: data.results };
                });
        };

        const getProductos = (search) => {
            return api
                .get("productos", { search })
                .catch(() => {})
                .then((data) => {
                    return { options: data.results };
                });
        };

        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;

        return (
            <div>
                <div className="row mb-2">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="mb-1 mt-1">
                                <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                    <h3>
                                        <b>Historial de inventario</b>
                                    </h3>
                                    <div
                                        className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                        style={{ flex: "3" }}
                                    >
                                        {" "}
                                        &nbsp;
                                        <div style={{ flex: "0.2" }}>
                                            <label>Fecha Inicio</label>
                                            <DatePicker
                                                value={fecha_inicial}
                                                maxDate={new Date()}
                                                onChange={(date) => {
                                                    this.props.setFecha(
                                                        "Inicial",
                                                        date
                                                    );
                                                }}
                                            />
                                        </div>
                                        &nbsp; &nbsp;
                                        <div style={{ flex: "0.2" }}>
                                            <label>Fecha Fin</label>
                                            <DatePicker
                                                value={fecha_final}
                                                minDate={
                                                    new Date(
                                                        moment(
                                                            fecha_inicial,
                                                            "YYYY-MM-DD"
                                                        )
                                                    )
                                                }
                                                onChange={(date) => {
                                                    this.props.setFecha(
                                                        "Final",
                                                        date
                                                    );
                                                }}
                                            />
                                        </div>
                                        &nbsp; &nbsp;
                                        <div style={{ flex: "0.2" }}>
                                            <label>Sucursal</label>
                                            <Async
                                                className="form-control"
                                                placeholder="Seleccionar sucursal"
                                                onChange={this.onChangeSucursal}
                                                searchPromptText="Escriba para buscar"
                                                valueKey={"id"}
                                                labelKey={"nombre"}
                                                loadOptions={getSucursales}
                                                value={this.props.sucursal}
                                            />
                                        </div>
                                        &nbsp; &nbsp;
                                        <div style={{ flex: "0.2" }}>
                                            <label>Producto</label>
                                            <Async
                                                className="form-control"
                                                labelKey="nombre"
                                                valueKey="id"
                                                searchPromptText="Escriba para buscar"
                                                placeholder={
                                                    "Seleccionar producto"
                                                }
                                                loadOptions={getProductos}
                                                onChange={this.onChangeProducto}
                                                value={this.props.producto}
                                            />
                                        </div>
                                        &nbsp; &nbsp;
                                        {producto ? (
                                            <div style={{ flex: "0.2" }}>
                                                <label>Presentacion</label>
                                                <Select
                                                    labelKey="nombre"
                                                    valueKey="id"
                                                    searchPromptText="Escriba para buscar"
                                                    placeholder="Seleccionar presentación"
                                                    className="form-control"
                                                    options={
                                                        producto.fracciones
                                                    }
                                                    value={fraccion}
                                                    onChange={
                                                        this
                                                            .onChangePresentacion
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <div style={{ flex: "0.2" }}>
                                                <label>Presentacion</label>

                                                <Select
                                                    labelKey="nombre"
                                                    valueKey="id"
                                                    placeholder="Debe seleccionar producto"
                                                    className="form-control"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Nombre del producto</b>
                                            {producto ? producto.nombre : ""}
                                        </div>
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Marca</b>
                                            {producto ? producto.marca : ""}
                                        </div>
                                        {conf_tipo_negocio === "Joyeria" && (
                                            <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                                <b>Kilataje</b>
                                                {producto ? producto.kilataje : ""}
                                            </div>
                                        )}
                                        {conf_tipo_negocio === "Joyeria" && (
                                            <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                                <b>Peso</b>
                                                {producto ? producto.peso : ""}
                                            </div>
                                        )}
                                        <div className="d-flex flex-column flex-1 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Presentacion</b>
                                            {fraccion && producto
                                                ? fraccion.nombre
                                                : ""}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-column flex-3 w-100 border-bottom ml-sm-5 mr-sm-5 m-0 mt-2">
                                            <b>Código de barras</b>
                                            {fraccion && producto
                                                ? fraccion.codigo_barras
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                                <GraficaMovimiento />
                            </div>
                        </div>
                    </div>
                </div>

                {producto && sucursal && fraccion ? (
                    <div className="row mb-2">
                        <div className="col-12">
                            <div className="grid-container">
                                <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                    <div
                                        className="d-flex flex-column flex-md-row flex-lg-row justify-content-between mb-2"
                                        style={{ flex: "1" }}
                                    >
                                        <h3>
                                            <b>Listado</b>
                                        </h3>
                                        <button
                                            className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                            disabled={estado_descarga}
                                            onClick={() => {
                                                descargarListado();
                                            }}
                                        >
                                            <em
                                                className={`fa ${
                                                    estado_descarga
                                                        ? "fa-spinner fa-pulse"
                                                        : "fa-download"
                                                }`}
                                            ></em>
                                            &nbsp;Descargar Listado
                                        </button>
                                    </div>
                                    <div className="row d-flex align-items-center justify-content-end mt-3">
                                        <div className="col-12 col-md-2 col-lg-2">
                                            <h5 className="text-right">
                                                Total entrada:
                                                <b className="ml-2">
                                                    <RenderNumero
                                                        monto={
                                                            data.total_entrada
                                                                ? data.total_entrada
                                                                : 0
                                                        }
                                                    />
                                                </b>
                                            </h5>
                                        </div>
                                        <div className="col-12 col-md-2 col-lg-2 pl-3 pl-md-0 pl-lg-0">
                                            <h5 className="text-right">
                                                Total salida:
                                                <b className="ml-2">
                                                    <RenderNumero
                                                        monto={
                                                            data.total_salida
                                                                ? data.total_salida
                                                                : 0
                                                        }
                                                    />
                                                </b>
                                            </h5>
                                        </div>
                                        <div className="col-12 col-md-2 col-lg-2 pl-3 pl-md-0 pl-lg-0">
                                            <h5 className="text-right">
                                                Total PC:
                                                <b className="ml-2">
                                                    <RenderMoneda
                                                        monto={
                                                            data.total_pc
                                                                ? data.total_pc
                                                                : 0
                                                        }
                                                    />
                                                </b>
                                            </h5>
                                        </div>
                                        <div className="col-12 col-md-2 col-lg-2 pl-3 pl-md-0 pl-lg-0">
                                            <h5 className="text-right">
                                                Total PV:
                                                <b className="ml-2">
                                                    <RenderMoneda
                                                        monto={
                                                            data.total_pv
                                                                ? data.total_pv
                                                                : 0
                                                        }
                                                    />
                                                </b>
                                            </h5>
                                        </div>
                                        <div className="col-12 col-md-3 col-lg-3 pl-3 pl-md-0 pl-lg-0">
                                            <h5 className="text-right">
                                                Total importe:
                                                <b className="ml-2">
                                                    <RenderMoneda
                                                        monto={
                                                            data.total_importe
                                                                ? data.total_importe
                                                                : 0
                                                        }
                                                    />
                                                </b>
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row d-flex align-items-center justify-content-end mt-3">
                                        <div className="col-12 col-md-3 col-lg-2">
                                            <h5 className="text-right">
                                                Saldo inicial:
                                                <b className="ml-2">
                                                    <RenderNumero
                                                        monto={
                                                            data.saldo_inicial
                                                                ? data.saldo_inicial
                                                                : 0
                                                        }
                                                    />
                                                </b>
                                            </h5>
                                        </div>
                                    </div>
                                    <LoadMask loading={loader} light blur>
                                        {/* <GraficaMovimiento/> */}
                                        <BootstrapTable
                                            data={loader ? [] : data.results}
                                            remote
                                            pagination
                                            hover
                                            fetchInfo={{
                                                dataTotalSize: data.count,
                                            }}
                                            options={options}
                                        >
                                            <TableHeaderColumn
                                                dataSort
                                                isKey
                                                dataField="creado"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="center"
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        <RenderDateTime
                                                            fecha={cell}
                                                        />
                                                    );
                                                }}
                                            >
                                                Fecha
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                dataField="motivo"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="center"
                                            >
                                                Movimiento
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                dataField={"venta_data__codigo"}
                                                dataFormat={(cell, row) => {
                                                    return `${
                                                        row.venta_data
                                                            ? row.venta_data
                                                                  .codigo
                                                            : row.movimiento
                                                                  .num_documento
                                                            ? row.movimiento
                                                                  .num_documento
                                                            : "-----"
                                                    }`;
                                                }}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="center"
                                            >
                                                No. Referencia
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                dataField={
                                                    "movimiento__cliente_proveedor__nombre"
                                                }
                                                dataFormat={(cell, row) => {
                                                    if (row.motivo === "Venta") {
                                                        return row.venta_data && row.venta_data.cliente ? row.venta_data.cliente.nombre : "-----";
                                                    } else {
                                                        return row.movimiento && row.movimiento.cliente_proveedor ? row.movimiento.cliente_proveedor.nombre : "-----";
                                                    }
                                                }}

                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="center"
                                            >
                                                Cliente/Proveedor
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="entrada"
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        sucursal.empresa.id === 59 ? 
                                                        <RenderNumeroDecimales
                                                            monto={cell}
                                                        />:
                                                        <RenderNumero
                                                        monto={cell}
                                                    />
                                                    )
                                            }}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                Entrada
                                            </TableHeaderColumn>
                                            
                                            <TableHeaderColumn
                                                dataSort  
                                                width="100px"
                                                dataField="salida"
                                                dataFormat={(cell, row) => {
                                                        return (
                                                            sucursal.empresa.id === 59 ? 
                                                            <RenderNumeroDecimales
                                                                monto={cell}
                                                            />:
                                                            <RenderNumero
                                                            monto={cell}
                                                        />
                                                        )
                                                }}

                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                Salida
                                            </TableHeaderColumn>

                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="pc"
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        <RenderMoneda
                                                            monto={cell}
                                                        />
                                                    );
                                                }}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                PC
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="pv"
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        <RenderMoneda
                                                            monto={cell}
                                                        />
                                                    );
                                                }}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                PV
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="importe"
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        <RenderMoneda
                                                            monto={cell}
                                                        />
                                                    );
                                                }}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                Importe
                                            </TableHeaderColumn>
                                            {/* <TableHeaderColumn
                                                dataSort
                                                dataField="existencia_actual"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                Cuanto habia
                                            </TableHeaderColumn> */}
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="existencia_final"
                                                dataFormat={(cell, row) => {
                                                    return (
                                                        sucursal.empresa.id === 59 ? 
                                                        <RenderNumeroDecimales
                                                            monto={cell}
                                                        />:
                                                        <RenderNumero
                                                        monto={cell}
                                                    />
                                                    )
                                                }}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                Saldo
                                            </TableHeaderColumn>
                                        </BootstrapTable>
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default DetalleVenta;

import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/familia/familia";
import Editar from './FamiliaresEditar'


const ms2p = (state) => {
    return {
        ...state.familia,
    };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Editar);
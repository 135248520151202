import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    RenderCurrency,
    RenderNumber,
} from "Utils/renderField/renderReadField";
import NumberFormat from "react-number-format";
import { icons } from "icons";
import Tooltip from "rc-tooltip";
import EdicionDetalle from "./EdicionDetalle";
import "rc-tooltip/assets/bootstrap.css";
import {validarConfDecimales} from "../../../Utils/validaConfiguracionDecimal";

class DatosVenta extends Component {
    static propTypes = {
        seleccionados: PropTypes.array.isRequired,
        restar: PropTypes.func.isRequired,
        agregar: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { seleccionados, conf_venta_precio_editable, pais, conf_empresa } = this.props;
        // bind
        const {
            cambiarPrecioEditable,
            quitar,
            ingresoCantidad,
            desplegarItem,
            asignarUnidadDeMedida,
        } = this.props;

        const numeroDeDecimales = validarConfDecimales(conf_empresa)

        return (
            <div
                className="grid-container mt0 border-radius-buttom-squa"
                style={{ backgroundColor: "#F5F5F5" }}
            >
                <div className="r-encajar mt-2">
                    {seleccionados.length === 0 ? (
                        <h4 className="negro-dark2 mr-2 ml-2">
                            Agregue un producto a la venta
                        </h4>
                    ) : (
                        /* region PRODUCTOS EN VENTA */
                        <div>
                            {seleccionados.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            desplegarItem(item, index);
                                        }}
                                        className={` ${
                                            item.collapsed
                                                ? "container-item-collapsed"
                                                : ""
                                        } d-flex flex-column justify-content-between container-item`}
                                    >
                                        <div className="d-flex flex-column">
                                            <div className="w-100 d-flex flex-row">
                                                {/* region FLECHAS EXPANSION */}
                                                {!item.es_vale && (
                                                    <div
                                                        style={{
                                                            width: "20px",
                                                        }}
                                                    >
                                                        {item.collapsed ? (
                                                            <em
                                                                style={{
                                                                    flex: "0.4",
                                                                }}
                                                                className="fa fa-angle-down icono-flechas"
                                                            />
                                                        ) : (
                                                            <em
                                                                style={{
                                                                    flex: "0.4",
                                                                }}
                                                                className="fa fa-angle-right icono-flechas"
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                                {/* endregion FLECHAS EXPANSION */}

                                                <div className="w-100">
                                                    <b>
                                                        {item.es_vale
                                                            ? ""
                                                            : item.producto
                                                                  .nombre}
                                                    </b>{" "}
                                                    {item.codigo_barras
                                                        ? item.codigo_barras
                                                        : ""}
                                                </div>

                                                {/* region BOTON ELIMINAR */}
                                                <div
                                                    className="align-self-start text-right"
                                                    style={{ width: "20px" }}
                                                >
                                                    <button
                                                        className="btn-delete"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            quitar(item, index);
                                                        }}
                                                    >
                                                        <em className="fa fa-times" />
                                                    </button>
                                                </div>
                                                {/* endregion BOTON ELIMINAR */}
                                            </div>
                                            {/* region DETALLE ORDEN PRODUCTO */}
                                            {!item.es_vale && (
                                                <div className="d-flex flex1 flex-column justify-content-between">
                                                    {/* unidad */}
                                                     <div
                                                        className="d-sm-none d-flex flex1 align-items-center justify-content-center"
                                                       > 
                                                                &nbsp;&nbsp;
                                                                <span
                                                                    className="flex1 text-center"
                                                                    style={{
                                                                        paddingLeft:
                                                                            "3px",
                                                                    }}
                                                                >
                                                                    {
                                                                        'Medida: ' + item.nombre
                                                                    }
                                                                </span>
                                                            </div>
                                                    <div
                                                        className="d-flex justify-content-between align-items-center mt-10"
                                                        style={{
                                                            fontSize: "13px",
                                                          
                                                        }}
                                                    >
                                                        
                                                        {item.producto
                                                            .inventariable &&
                                                        item.cantidad_total >
                                                            item.inventario_total ? (
                                                            <div
                                                                className="d-flex flex-row align-items-center justify-content-center"
                                                                style={{
                                                                    flex: "0.3",
                                                                }}
                                                            >
                                                                <Tooltip
                                                                    placement="top"
                                                                    overlay={
                                                                        <div>
                                                                            <strong>
                                                                                Se
                                                                                ha
                                                                                excedido
                                                                                el
                                                                                inventario
                                                                            </strong>
                                                                            <br />
                                                                            <strong>
                                                                                Disponible:{" "}
                                                                                {
                                                                                    item.inventario_total
                                                                                }
                                                                            </strong>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <em className="text-danger fa fa-exclamation-triangle opacityPulse-css" />
                                                                </Tooltip>
                                                            </div>
                                                        ) : null}
                                                        
                                                        <div
                                                            className="d-flex flex-column align-items-center justify-content-center"
                                                            style={{
                                                                flex: "0.6",
                                                                marginLeft:'10px'
                                                            }}
                                                        >
                                                            
                                                            {conf_venta_precio_editable ? (
                                                                <NumberFormat
                                                                    style={{
                                                                        height:
                                                                            "35px",
                                                                        padding:
                                                                            "6px 4px",
                                                                        minWidth:'80px',
                                                                        
                                                                    }}
                                                                    className={`form-control text-right ${
                                                                        item.desc_porcentaje
                                                                            ? "mt-3"
                                                                            : ""
                                                                    }`}
                                                                    decimalScale={
                                                                        numeroDeDecimales
                                                                    }
                                                                    fixedDecimalScale={
                                                                        true
                                                                    }
                                                                    value={
                                                                        item.precio
                                                                            ? item.precio
                                                                            : 0
                                                                    }
                                                                    prefix={
                                                                        pais ==
                                                                        "GT"
                                                                            ? "Q "
                                                                            : "$ "
                                                                    }
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                    onValueChange={(
                                                                        values
                                                                    ) => {
                                                                        cambiarPrecioEditable(
                                                                            index,
                                                                            values.floatValue
                                                                        );
                                                                    }}
                                                                    onFocus={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        e.target.select();
                                                                    }}
                                                                />
                                                            ) : (
                                                                <div className="width100 text-right font-weight-bold">
                                                                    <RenderCurrency
                                                                        value={
                                                                            item.precio
                                                                                ? item.precio
                                                                                : 0
                                                                        }
                                                                        decimalScale={numeroDeDecimales}
                                                                    />
                                                                </div>
                                                            )}
                                                            {item.desc_porcentaje >
                                                                0 && (
                                                                <div className="width100 text-right precio-anterior">
                                                                    <RenderCurrency
                                                                        value={
                                                                            item.precio_original
                                                                                ? item.precio_original
                                                                                : 0
                                                                        }
                                                                        decimalScale={numeroDeDecimales}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                        &nbsp;&nbsp;&nbsp;
                                                        
                                                        <div
                                                            className="text-center mr-1 flex-row-center"
                                                            style={{
                                                                flex: "1.8",
                                                            }}
                                                        >
                                                            x
                                                            <div
                                                                className="d-flex flex-row ml-10 flex1"
                                                                style={{
                                                                    height:
                                                                        "30px",
                                                                }}
                                                            >
                                                                <button
                                                                    className=" btn btn-item-venta btn-menos d-sm-flex d-none align-items-center justify-content-center"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        this.props.restar(
                                                                            item,
                                                                            index
                                                                        );
                                                                    }}
                                                                    type="button"
                                                                >
                                                                    <i className="fa fa-minus"></i>
                                                                </button>
                                                                <NumberFormat
                                                                    className="form-control venta-cantidad text-right"
                                                                    decimalScale={
                                                                        item
                                                                            .producto
                                                                            .a_granel
                                                                            ? 3
                                                                            : 0
                                                                    }
                                                                    fixedDecimalScale={
                                                                        item
                                                                            .producto
                                                                            .a_granel
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    value={
                                                                        item.cantidad
                                                                    }
                                                                    style={{
                                                                        minWidth:
                                                                            "55px",
                                                                        padding:
                                                                            "6px 2px",
                                                                    }}
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                    onValueChange={(
                                                                        values
                                                                    ) => {
                                                                        ingresoCantidad(
                                                                            values.floatValue,
                                                                            item,
                                                                            index
                                                                        );
                                                                    }}
                                                                />
                                                                
                                                                <button
                                                                    className="btn btn-item-venta btn-mas d-sm-flex d-none align-items-center justify-content-center"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        this.props.sumar(
                                                                            item,
                                                                            index
                                                                        );
                                                                    }}
                                                                    type="button"
                                                                >
                                                                   
                                                                    <i className="fa fa-plus"></i>
                                                                </button>
                                                                
                                                            </div>
                                                            {/* UNIDAD DE MEDIDA */}
                                                            <div
                                                                className="d-sm-flex d-none flex1 align-items-center justify-content-center"
                                                                style={{
                                                                    height:
                                                                        "35px",
                                                                }}
                                                            > 
                                                                &nbsp;&nbsp;
                                                               
                                                                <span
                                                                    className="flex1 text-center"
                                                                    style={{
                                                                        paddingLeft:
                                                                            "3px",
                                                                    }}
                                                                >
                                                                    {
                                                                        item.nombre
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <b
                                                            className="text-right"
                                                            style={{
                                                                flex: "0.6",
                                                                minWidth:'80px'

                                                            }}
                                                        >
                                                            <RenderCurrency
                                                           
                                                                value={
                                                                    item.precio *
                                                                    item.cantidad
                                                                }
                                                                decimalScale={numeroDeDecimales}
                                                            />
                                                
                                                        </b>
                                                    </div>
                                                </div>
                                            )}
                                            {/* endregion DETALLE ORDEN PRODUCTO */}
                                            {/* region DETALLE ORDEN VALE*/}
                                            {item.es_vale && (
                                                <div className="d-flex flex-column justify-content-between w-100">
                                                    <b className="text-danger">
                                                        Vale de descuento
                                                    </b>
                                                    <div className="d-flex flex-row">
                                                        <div className="flex-1">
                                                            {item.codigo}
                                                        </div>
                                                        <div className="flex-1 text-right">
                                                            <RenderCurrency
                                                                className={
                                                                    "font-weight-bold"
                                                                }
                                                                value={
                                                                    item.total_importe *
                                                                    -1
                                                                }
                                                                decimalScale={numeroDeDecimales}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {/* endregion DETALLE ORDEN VALE*/}
                                            {/* region MAS OPCIONES DEL PRODUCTO */}
                                            {!item.es_vale && (
                                                <EdicionDetalle
                                                    {...this.props}
                                                    item={item}
                                                    index={index}
                                                />
                                            )}
                                            {/* endregion MAS OPCIONES DEL PRODUCTO */}
                                        </div>
                                        {/* <div className="d-flex flex-row">

                                    </div> */}
                                    </div>
                                );
                            })}
                        </div>
                        /* endregion PRODUCTOS EN VENTA */
                    )}
                </div>
            </div>
        );
    }
}

export default DatosVenta;

import _ from "lodash";
import { api } from "api";
import Swal from "sweetalert2";
import { ToastStore } from "react-toasts";
import { handleActions } from "redux-actions";
import { METODO_ENTREGA, METODO_PAGO } from "utility/variables";
import { initialize as initializeForm } from "redux-form";
import { actions as usuarioActions } from "../usuario/usuario";

// ------------------------------------
// Constants
// ------------------------------------
const LOADING = "CONF_LOADING";
const SET = "CONG_SET";
const SET_METODOS_ENTREGA = "CONF_SET_METODOS_ENTREGA";
const SET_METODOS_PAGO = "CONF_SET_METODOS_PAGO";

const endpoint_metodos_pago_entrega = "metodos_pago_entrega";
const endpoint_metodos_pago = endpoint_metodos_pago_entrega+"/metodos";

// ------------------------------------
// Actions
// ------------------------------------
export const loading = (loading = true) => ({
    type: LOADING,
    loading,
});

export const getConfig = () => (dispatch, getStore) => {
    const store = getStore();
    const {
        metodos_entrega,
        metodos_pago,
        tipos_facturas,
    } = store.configuracion;
    dispatch(loading());
    api.get("configuracion/me")
        .then((data) => {
            // set data
            dispatch(setConfig(data));

            let form_metodos_entrega = [];
            let comprobacion = false;
            metodos_entrega.forEach((metodo_entrega, index) => {
                form_metodos_entrega[index] = {};
                const esta = !!_.find(data.metodos_entrega, {
                    id: metodo_entrega.id,
                });
                form_metodos_entrega[index][
                    `metodo_entrega-${metodo_entrega.id}`
                ] = esta;
                if (esta) {
                    const actual = _.find(data.metodos_entrega, {
                        id: metodo_entrega.id,
                    });
                    form_metodos_entrega[index]["precio"] =
                        actual.precio_empresa;
                    comprobacion = true;
                }
            });

            if (data.metodos_entrega.length > 0 && comprobacion)
                data.metodos_entrega = form_metodos_entrega;
            if (form_metodos_entrega.length > 0)
                data.metodos_entrega = form_metodos_entrega;
            let form_metodos_pago = [];
            comprobacion = false;
            metodos_pago.forEach((metodo_pago, index) => {
                form_metodos_pago[index] = {};
                const esta = !!_.find(data.metodos_pago, {
                    id: metodo_pago.id,
                });
                form_metodos_pago[index][
                    `metodo_pago-${metodo_pago.id}`
                ] = esta;
                const actual = _.find(data.metodos_pago, {
                    id: metodo_pago.id,
                });
                if (actual)
                    form_metodos_pago[index]["prefijo"] = actual.prefijo;
                if (actual && actual.prefijo === "DEP") {
                    form_metodos_pago[index]["banco"] = actual.banco_cuenta;
                    form_metodos_pago[index]["no_cuenta"] = actual.no_cuenta;
                    form_metodos_pago[index]["nombre"] = actual.nombre_cuenta;
                }

                if (esta) comprobacion = true;
            });

            if (data.metodos_pago.length > 0 && comprobacion)
                data.metodos_pago = form_metodos_pago;
            if (form_metodos_pago.length > 0)
                data.metodos_pago = form_metodos_pago;
            if (!data.nombres_precios) {
                data.nombres_precios = {
                    nombre_precio: "Precio",
                    nombre_precio2: "Precio 2",
                    nombre_precio3: "Precio 3",
                    nombre_precio4: "Precio 4",
                    nombre_precio5: "Precio 5",
                };
            }

            //  Tipos facturas
            data.tipos_facturas = [];
            tipos_facturas.forEach((item) => {
                const obj = {};
                const activo = _.find(data.conf_tipos_facturas, function (o) {
                    return o == item.value;
                });
                obj[`tipo_factura-${item.value}`] = activo ? true : false;
                data.tipos_facturas.push(obj);
            });
            // set data para el form de edicion de configuraciones
            dispatch(initializeForm("configuracion", data));
        })
        .catch((err) => {
            console.log("error config", err);
        })
        .finally(() => {
            dispatch(loading(false));
        });
};

export const submitConfig = (logos_empresa) => (dispatch, getStore) => {
    const store = getStore();
    const { config } = store.configuracion;
    const form = store.form.configuracion;
    let datos = form.values;
    console.log("DATOS", datos); // <-- Aquí puedes ver los datos que se envían al backend
    // Se genera el array para la asignacion de metodos de entrega
    let metodos_entrega = [];
    form.values.metodos_entrega.forEach((item, index) => {
        if (!!item) {
            if (item[Object.keys(item)[0]]) {
                const partes = Object.keys(item)[0].split("metodo_entrega-");
                metodos_entrega.push({
                    id: partes[1],
                    precio: item.precio ? item.precio : 0,
                });
            }
        }
    });

    // Se genera el array para la asignacion de metodos de pago
    let metodos_pago = [];
    form.values.metodos_pago.forEach((item, index) => {
        if (!!item) {
            if (item[Object.keys(item)[0]]) {
                const partes = Object.keys(item)[0].split("metodo_pago-");
                let obj = { id: partes[1] };
                if (item.banco || item.no_cuenta || item.nombre)
                    obj = {
                        id: partes[1],
                        banco: item.banco,
                        no_cuenta: item.no_cuenta,
                        nombre: item.nombre,
                    };
                metodos_pago.push(obj);
            }
        }
    });

    // Se genera el array para el listado de factura
    let tipos_facturas = [];
    form.values.tipos_facturas.forEach((item) => {
        if (!!item) {
            if (item[Object.keys(item)[0]]) {
                const partes = Object.keys(item)[0].split("tipo_factura-");
                tipos_facturas.push(partes[1]);
            }
        }
    });

    // Se cambia el valor de los metodos de entrega y pago
    datos = { ...datos, metodos_entrega, metodos_pago, tipos_facturas };
    let logos = [];
    logos_empresa.forEach(function (logo) {
        if (logo.file == undefined && logo.name == "logo") {
            logo.file = config.logo;
        }
        if (logo.file == undefined && logo.name == "banner") {
            logo.file = config.banner;
        }
        if (logo.file == undefined && logo.name == "logo_ticket") {
            logo.file = config.logo_ticket;
        }
        logos.push(logo);
    });

    console.log("LOGOS", logos);
    dispatch(loading());
    api.putAttachments(`configuracion/${config.id}`, datos, logos)
        .then((rsp) => {
            // la configuracion fue editada, recargar
            ToastStore.success("Datos almacenados correctamente");
            dispatch(setConfig(rsp));
            dispatch(usuarioActions.getMe());
        })
        .catch((err) => {
            Swal(
                "ERROR",
                err.detail || "Ha ocurrido un error, intenta más tarde.",
                "error"
            );
        })
        .finally(() => {
            dispatch(loading(false));
        });
};

export const getMetodosPagoEntrega = () => async (dispatch) => {
    dispatch(loading(true));
    try {
        const response_entrega= await api.get(endpoint_metodos_pago_entrega, {
            tipo: METODO_ENTREGA,
        });
        dispatch(setMetodosEntrega(response_entrega.results));
        
        const response_pago = await api.get(endpoint_metodos_pago, {
            tipo: METODO_PAGO,
        });
        dispatch(setMetodosPago(response_pago));
    } catch (error) {
        console.log("GET-METODOS-PAGO-ENTREGA-ERROR: ", error);
    } finally {
        dispatch(loading(false));
        dispatch(getConfig());
    }
};

// ------------------------------------
// PureActions
// ------------------------------------
export const setConfig = (config) => ({
    type: SET,
    config,
});
export const setMetodosEntrega = (metodos_entrega) => ({
    type: SET_METODOS_ENTREGA,
    metodos_entrega,
});
export const setMetodosPago = (metodos_pago) => ({
    type: SET_METODOS_PAGO,
    metodos_pago,
});

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [LOADING]: (state, { loading }) => {
        return {
            ...state,
            loading,
        };
    },
    [SET]: (state, { config }) => {
        return {
            ...state,
            config,
        };
    },
    [SET_METODOS_ENTREGA]: (state, { metodos_entrega }) => {
        return {
            ...state,
            metodos_entrega,
        };
    },
    [SET_METODOS_PAGO]: (state, { metodos_pago }) => {
        return {
            ...state,
            metodos_pago,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    loading: false,
    config: {
        conf_caja_forzar_cierre: true,
        conf_venta_imprimir_recibo: true,
        conf_venta_pedir_cliente: true,
    },
    metodos_entrega: [],
    metodos_pago: [],
    tipos_facturas: [
        {
            id: "FACT",
            value: "FACT",
            label: "Factura normal",
        },
        {
            id: "FCAM",
            value: "FCAM",
            label: "Factura cambiaria",
        },
    ],
};

export default handleActions(reducers, initialState);

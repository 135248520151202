import React from "react";
import {
    RenderDateTime,
    RenderMoneda,
    RenderNumero
} from "Utils/renderField/renderTableField";

const HistorialPDF = ({
    data,
    conf_tipo_negocio,
    hideCabecera,
    totalDiferencia,
    totalPrecio,
    fecha_i,
    fecha_f,
    totalPeso,
    joyeria
}) => {
    return (
        <div className="table-responsive p-3">
            <div className="card shadow-sm mb-4">
                <div className="card-header bg-primary text-white">
                    <h5 className="card-title mb-0">
                        Detalle del Inventario
                    </h5>
                </div>

                <div className="card-body">
                    {!hideCabecera && (
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <p className="mb-1">
                                    <strong>Fecha inicio:</strong>{" "}
                                    <RenderDateTime fecha={fecha_i} />
                                </p>
                                <p className="mb-1">
                                    <strong>Fecha Fin:</strong>{" "}
                                    <RenderDateTime fecha={fecha_f} />
                                </p>
                            </div>
                            <div className="col-md-6">
                                <p className="mb-1">
                                    <strong>Total Diferencia Precio:</strong>{" "}
                                    <RenderMoneda monto={totalPrecio} />
                                </p>
                            </div>
                            <div className="col-md-6">
                                <p className="mb-1">
                                    <strong>Total Diferencia:</strong>{" "}
                                    <RenderNumero monto={totalDiferencia} />
                                </p>
                            </div>
                            {joyeria && <div className="col-md-6">
                                <p className="mb-1">
                                    <strong>Total Peso:</strong>{" "}
                                    {totalPeso} grs.
                                </p>
                            </div>}
                        </div>
                    )}

                    <table className="table table-bordered">
                        <thead className="table-info">
                            <tr>
                                <th>Código</th>
                                <th>Artículo</th>
                                {conf_tipo_negocio === "Joyeria" && <th>Kilataje</th>}
                                {conf_tipo_negocio === "Joyeria" && <th>Peso</th>}
                                <th>Cant. Antes</th>
                                <th>Cant. Después</th>
                                <th>Diferencia</th>
                                <th>Exist. Sistema</th>
                                <th>Exist. Real</th>
                                <th>Precio Público</th>
                                <th>Costo</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.map((detalle, idx) => {
                                const {
                                    fraccion,
                                    existencias_antes,
                                    existencias_despues,
                                    precio,
                                    costo
                                } = detalle;

                                const producto = fraccion.producto || {};
                                const diferencia = existencias_despues - existencias_antes;

                                return (
                                    <tr key={`detalle-${idx}`}>
                                        <td>
                                            {fraccion.codigo_barras || "---"}
                                        </td>
                                        <td>{producto.nombre || "---"}</td>

                                        {conf_tipo_negocio === "Joyeria" && (
                                            <td>
                                                {producto.kilataje &&
                                                    producto.kilataje !== "[]"
                                                    ? producto.kilataje
                                                    : "---"}
                                            </td>
                                        )}

                                        {conf_tipo_negocio === "Joyeria" && (
                                            <td>
                                                {producto.peso || "---"}
                                            </td>
                                        )}

                                        <td>
                                            <RenderNumero monto={existencias_antes} />
                                        </td>
                                        <td>
                                            <RenderNumero monto={existencias_despues} />
                                        </td>
                                        <td>
                                            <RenderNumero monto={diferencia} />
                                        </td>
                                        <td>
                                            <RenderNumero monto={existencias_antes} />
                                        </td>
                                        <td>
                                            <RenderNumero monto={existencias_despues} />
                                        </td>
                                        <td>
                                            <RenderMoneda monto={precio} />
                                        </td>
                                        <td>
                                            <RenderMoneda monto={costo} />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>


                    </table>
                </div>
            </div>
        </div>
    );
};

export default HistorialPDF;
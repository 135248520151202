import { handleActions } from "redux-actions";
import { api } from "api";
import _ from "lodash";
import Swal from "sweetalert2";
import { ToastStore } from "react-toasts";
import {
    actions as dashboardActions,
    setLoader as setLoaderdashboard,
} from "../dashboard/dashboard";
import { actions as cajaActions } from "../caja/caja";
import { actions as devolucionActions } from "../devolucion/devolucion";
// TODO agregar las acciones que se tienen que refrescar cuando cambia la sucursal
import { actions as bodegaActions } from "../bodega/bodega";
import { actions as gastosActions } from "../gastos/gastos";
import { actions as productoActions } from "../producto/producto";
import { actions as balanceActions } from "../reportes/balance_general";
import { actions as reporteGastosActions } from "../reportes/gastos";
import { actions as reporteProdMasVendidosActions } from "../reportes/productos_mas_vendidos";
import { actions as aperturaActions } from "../reportes/apertura_cierre";
import { actions as ventasActions } from "../reportes/ventas";
import { actions as repBodegaActions } from "../reportes/bodega";
import { actions as usuariosActions } from "../usuarios/usuarios";
import { actions as repCuentaCobrarActions } from "../reportes/cuenta_cobrar";
import { actions as inversionActions } from "../reportes/inversion";
import { actions as cuentaCobrarActions } from "../cuenta_cobrar/cuenta_cobrar";
import { actions as reporteGananciaBrutaActions } from "../reportes/ganancia_bruta";
import { actions as despachoActions } from "../despacho/despacho";
import { actions as recepcionDespachoActions } from "../despacho/recepcion_despacho";
import { actions as reporteDespachoActions } from "../reportes/despachos";
import { actions as puntoVentaAction } from "../venta/venta";
import { actions as puntoVentaCajaAction } from "../punto_venta/caja";
import { actions as cotizacionAction } from "../cotizacion/cotizacion";
import { actions as oCAction } from "../orden_compra/orden_compra";
import { actions as recepcionOCAction } from "../despacho/recepcion_orden_compra";
import { actions as OrdenesOnlineAction } from "../orden_online/orden_online";
import { actions as descuentoRecargoAction } from "../reportes/descuento_recargo";
import { actions as familiaActions} from "../familia/familia";
import { getConfig } from "../configuracion/configuracion";

// ------------------------------------
// Constants
// ------------------------------------
const ME = "ME";
const MENU = "ME_MENU";
const RUTA = "ME_RUTA";
const SUCURSAL = "ME_SUCURSAL";
const USUARIOS_SUCURSAL = "USUARIOS_SUCURSAL";
const SET_FORMATO_FACTURA = "ME_SET_FORMATO_FACTURA";
const SET_FACTURA_PERSONALIZADA = "ME_SET_FACTURA_PERSONALIZADA";
const SET_UTILIZA_FEL = "ME_SET_UTILIZA_FEL";
const SET_VENTA_PERMITIR_DESCUENTO = "ME_SET_VENTA_PERMITIR_DESCUENTO";
const SET_ITEMS_POR_FACTURA = "ME_SET_ITEMS_POR_FACTURA";
const SET_UNIDADES_DE_MEDIDA_EMPRESA = "SET_UNIDADES_DE_MEDIDA_EMPRESA";
const SET_VISTA_BIENVENIDA = "ME_SET_VISTA_BIENVENIDA";
const SET_BIENVENIDA_VISTO = "ME_SET_BIENVENIDA_VISTO";
const SET_CONF_COTIZACION = "ME_SET_CONF_COTIZACION";
const SET_TIPO_VENTA = "ME_SET_TIPO_VENTA";
const SET_CONF_REDONDEO = "ME_SET_CONF_REDONDEO";
const SET_CONF_TIENDA_ONLINE = "ME_SET_CONF_TIENDA_ONLINE";
const SET_CONF_INVENTARIO_NEGATIVO = "ME_SET_CONF_INVENTARIO_NEGATIVO";
const CONF_VENTA_PRECIO_EDITABLE = "ME_CONF_VENTA_PRECIO_EDITABLE";
const CONF_MOSTRAR_REFERENCIA = "ME_CONF_MOSTRAR_REFERENCIA";
const CONF_MOSTRAR_PRECIO_IMPRESION = "ME_CONF_MOSTRAR_PRECIO_IMPRESION";
const METODOS_PAGO = "ME_METODOS_PAGO";
const METODOS_ENTREGA = "ME_METODOS_ENTREGA";
const NOMBRES_PRECIOS = "ME_NOMBRES_PRECIOS";
const CONF_PORCENTAJE_PRECIO = "ME_CONF_PORCENTAJE_PRECIO";
const CONF_PRECIO_CON3_DECIMALES = "CONF_EFECTIVO_CON3_DECIMALES";
export const LOADER = "USUARIO_LOADER";
const CONF_EMPRESA_VENTAS_AL_CREDITO = "CONF_EMPRESA_VENTAS_AL_CREDITO";
const CONF_HABILITAR_CAMPO_CLIENTE = "CONF_HABILITAR_CAMPO_CLIENTE";
const BUSCAR_NOMBRE = "BUSCAR_NOMBRE";
const CONF_HABILITAR_NO_FACTURA = "CONF_HABILITAR_NO_FACTURA";
const CONF_TIPO_NEGOCIO = "ME_CONF_TIPO_NEGOCIO";

// ------------------------------------
// Actions
// ------------------------------------
export const getMe = () => (dispatch) => {
    if (localStorage.getItem("sucursal")) {
        dispatch(setSucursal(localStorage.getItem("sucursal")));
    }
    dispatch(getConfig());
    api.get("usuarios/me", {})
        .catch(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("sucursal");
        })
        .then((me) => {
            if (me) {
                console.log(me);
                //dispatch({ type: SUBMIT, autenticado: true });
                dispatch(setMe(me));

                // Configuracion si la empresa permite descuentos en la venta
                dispatch(
                    setVentaPermitirDescuento(me.venta_permitir_descuento)
                );

                // Formato de la factura del cliente
                dispatch(setFormatoFactura(me.formato_factura));

                // Items por factura
                dispatch(setItemsPorFactura(me.items_por_factura));

                // Bandera para saber si la empresa del usuario utiliza una factura personalizada
                dispatch(setFacturaPersonalizada(me.factura_personalizada));

                // Bandera para saber si la empresa del usuario utiliza FEL
                dispatch(setUtilizaFEL(me.utiliza_fel));

                // Datos para el diseño de la vista de bienvenida
                dispatch(setVistaBienvenida(me.vista_bienvenida));
                // Bandera para saber si el usuario ha visto la bienvenida
                dispatch(setBienvenidaVisto(me.bienvenida_visto));
                // Bandera para saber si la empresa tiene habilitado las cotiaciones de productos
                dispatch(setConfCotizacion(me.conf_cotizacion));
                // Tipo de cotizacion configurado
                dispatch(setTipoPuntoVenta(me.tipo_punto_venta));
                // Configuracion de redondeo de total de venta
                dispatch(setConfRedondeo(me.conf_venta_redondeo_total));
                // Configuracion para tienda online
                dispatch(setConfTiendaOnline(me.conf_tienda_online));
                // Configuracion para el inventario negativo
                dispatch(setInventarioNegativo(me.conf_inventario_negativo));
                // Configuracion para el inventario negativo
                dispatch(setVentaPrecioEditable(me.conf_venta_precio_editable));

                // Configuracion de es joyeria
                dispatch(setConfEsJoyeria(me.conf_tipo_negocio));

                // Confiuracion para mostrar el botón de mostrar referencia
                dispatch(setMostrarReferencia(me.conf_mostrar_referencia));
                // Configuracion para habilitar el campo de cliente cuando sea cf
                dispatch(
                    setHabilitarCampoCliente(me.conf_habilitar_campo_cliente)
                );

                // Configuracion para habilitar el buscar por nombre
                dispatch(setBuscarNombre(me.buscar_nombre));

                // Configuración para habilitar numero de factura;
                dispatch(setHabilitarNoFactura(me.conf_habilitar_no_factura));
                // Configuracion para metodos de pago
                dispatch(setMetodosPago(me.metodos_pago));
                // Configuracion para metodos de entrega
                dispatch(setMetodosEntrega(me.metodos_entrega));
                // Configuracion para nombres de precios
                dispatch(setNombresPrecios(me.nombres_precios));
                // Configuracion para mostrar el precio en la impresion de baja y despacho
                dispatch(
                    setMostrarPrecioImpresion(me.conf_mostrar_precio_impresion)
                );
                // Configuracion para asignar el precio a los productos por porcentaje
                dispatch(setConfPorcentajePrecio(me.conf_precios_porcentaje));

                // Configuracion para validar numero de decimales
                dispatch(setConf3Decimales(me.conf_modena_con_3_decimales));

                // Configuracion de la empresa si maneja ventas al credito o no
                dispatch(setConfVentasAlCredito(me.conf_ventas_al_credito));

                //  Unidades de medida de la empresa
                dispatch(setUnidadesMedidaEmpresa(me.unidades_de_medida));
                if (!localStorage.getItem("sucursal")) {
                    if (
                        me.sucursales.length !== 0 &&
                        !(me.es_admin && me.es_global)
                    ) {
                        if (me.sucursales.length > 2) {
                            dispatch(setSucursal(me.sucursales[1].id));
                        } else {
                            dispatch(setSucursal(me.sucursales[0].id));
                        }
                    } else {
                        dispatch(setSucursal("empresa"));
                    }
                } else {
                    if (me.sucursal !== null) {
                        const sucursal = _.find(me.sucursales, {
                            id: parseInt(localStorage.getItem("sucursal")),
                        });
                        if (!sucursal) {
                            dispatch(setSucursal(me.sucursales[0].id));
                        }
                    }
                }

                dispatch(getCierreCaja());
            }
            //dispatch({ type: LOGIN_LOADER, loader: false });
        });
};

export const getUsuariosSucursal = () => (dispatch) => {
    api.get("usuarios/lista_all", {})
        .catch((err) => {})
        .then((data) => {
            if (data) {
                dispatch(setUsuariosSucursal(data));
            }
        });
};

const asyncLocalStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    },
    removeItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.removeItem(key);
        });
    },
};

export const setSucursal = (id) => (dispatch, getStore) => {
    if (id === "empresa") {
        asyncLocalStorage
            .removeItem("sucursal")
            .then(function () {
                dispatch(_setSucursal(undefined));
                return asyncLocalStorage.getItem("sucursal");
            })
            .then(function (value) {
                dispatch(peticiones());
                dispatch(cajaActions.get_ultimo_cierre());
            });
    } else {
        asyncLocalStorage
            .setItem("sucursal", id)
            .then(function () {
                dispatch(_setSucursal(id));
                return asyncLocalStorage.getItem("sucursal");
            })
            .then(function (value) {
                dispatch(peticiones());
                dispatch(cajaActions.get_ultimo_cierre());
            });
    }
};

export const getCierreCaja = () => {
    return async (dispatch, getStore) => {
        const store = getStore();
        const { me } = store.usuario;
        dispatch(setLoader(true));
        if (localStorage.getItem("sucursal")) {
            if (me.caja) {
                await dispatch(cajaActions.get_ultimo_cierre());
                dispatch(setLoader(false));
            } else {
                dispatch(cajaActions.setUltimoCierre({}));
                dispatch(setLoader(false));
            }
        } else {
            dispatch(setLoader(false));
        }
    };
};

export const peticiones = () => (dispatch, getStore) => {
    const store = getStore();
    const ruta = store.usuario.ruta;
    if (ruta.endsWith("/bodega")) {
        dispatch(bodegaActions.listar());
    } else if (ruta.endsWith("/gastos")) {
        dispatch(gastosActions.listar());
    } else if (ruta.endsWith("/productos")) {
        dispatch(productoActions.listarProductos());
    } else if (ruta.endsWith("/configuracion/usuarios")) {
        dispatch(usuariosActions.listar());
    } else if (ruta.endsWith("/reporte_devoluciones")) {
        dispatch(devolucionActions.listar());
    } else if (ruta.endsWith("/reporte_balance_general")) {
        dispatch(getUsuariosSucursal());
        dispatch(balanceActions.leer());
        dispatch(balanceActions.dataGraficaBalanceGeneral());
    } else if (ruta.endsWith("/abrir_caja")) {
        dispatch(cajaActions.listar());
    } else if (ruta.endsWith("/reporte_gastos")) {
        dispatch(getUsuariosSucursal());
        dispatch(reporteGastosActions.dataGraficaGastos());
        dispatch(reporteGastosActions.getReporteGastos());
    } else if (ruta.endsWith("/dashboard")) {
        dispatch(dashboardActions.setDataActivarDashboard());
    } else if (ruta.endsWith("/reporte_productos_mas_vendidos")) {
        dispatch(getUsuariosSucursal());
        dispatch(reporteProdMasVendidosActions.dataGraficaProdMasVendidos());
        dispatch(reporteProdMasVendidosActions.getReporteProdMasVendidos());
    } else if (ruta.endsWith("/reporte_apertura_cierre_caja")) {
        dispatch(getUsuariosSucursal());
        dispatch(aperturaActions.leer());
    } else if (ruta.endsWith("/reporte_ventas")) {
        dispatch(getUsuariosSucursal());
        dispatch(ventasActions.listar());
        dispatch(ventasActions.dataGraficaVentas());
    } else if (ruta.endsWith("/reporte_bodega")) {
        dispatch(repBodegaActions.getReporteBodega());
    } else if (ruta.endsWith("reporte_cuenta_por_cobrar")) {
        dispatch(repCuentaCobrarActions.listar());
        dispatch(repCuentaCobrarActions.getDataResumen());
    } else if (ruta.endsWith("cuentas_por_cobrar")) {
        dispatch(cuentaCobrarActions.listar());
    } else if (ruta.endsWith("inversion_en_bodega")) {
        dispatch(inversionActions.getInversion());
    } else if (ruta.endsWith("reporte_ganancia_bruta")) {
        dispatch(reporteGananciaBrutaActions.getListGanancia());
    } else if (ruta.endsWith("/despachos")) {
        dispatch(despachoActions.listar());
    } else if (ruta.endsWith("despachos/crear/")) {
        dispatch(despachoActions.getSucursalOrigen());
        dispatch(despachoActions.listarProductos());
    } else if (ruta.endsWith("/recepciones_de_despachos")) {
        dispatch(recepcionDespachoActions.listar());
    } else if (ruta.endsWith("/reporte_de_despachos")) {
        dispatch(reporteDespachoActions.listar());
    } else if (ruta.endsWith("/punto_de_venta")) {
        dispatch(puntoVentaAction.listar());
    } else if (ruta.endsWith("/punto_de_venta/mostrador")) {
        dispatch(puntoVentaAction.listar());
    } else if (ruta.endsWith("/punto_de_venta/caja")) {
        dispatch(puntoVentaCajaAction.listarVentas());
    } else if (ruta.endsWith("/cotizaciones")) {
        dispatch(cotizacionAction.listar());
    } else if (ruta.endsWith("/ordenes_de_compra")) {
        dispatch(oCAction.listarOC());
    } else if (ruta.endsWith("/recepciones_de_ordenes_de_compra")) {
        dispatch(recepcionOCAction.listar());
    } else if (ruta.endsWith("/ordenes_online")) {
        dispatch(OrdenesOnlineAction.listar());
    } else if (ruta.endsWith("/reporte_descuentos_y_recargos")) {
        dispatch(descuentoRecargoAction.listar());
    }
    else if (ruta.endsWith("/familias")) {
        dispatch(familiaActions.listar());
    }
};

export const marcarVistoBienvenida = () => (dispatch, getStore) => {
    const store = getStore();
    const { vista_bienvenida } = store.usuario;
    api.post("bienvenida_usuario", { bienvenida: vista_bienvenida.id })
        .catch((err) => {
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "No se ha podido almacenar los datos, intente más tarde.",
                    "error"
                );
            }
        })
        .then((resp) => {
            if (resp) {
                dispatch(getMe());
                ToastStore.success("Datos almacenados correctamente");
            }
        });
};

// ------------------------------------
// PureActions
// ------------------------------------
export const setMe = (me) => ({
    type: ME,
    me,
});

export const setRuta = (ruta) => ({
    type: RUTA,
    ruta,
    menu: false,
});

export const _setSucursal = (sucursal) => ({
    type: SUCURSAL,
    sucursal,
});

export const setUsuariosSucursal = (usuarios_sucursal) => ({
    type: USUARIOS_SUCURSAL,
    usuarios_sucursal,
});

export const closeMenu = (menu = false) => ({
    type: MENU,
    menu,
});

export const setFormatoFactura = (formato_factura) => ({
    type: SET_FORMATO_FACTURA,
    formato_factura,
});

export const setUtilizaFEL = (utiliza_fel) => ({
    type: SET_UTILIZA_FEL,
    utiliza_fel,
});
export const setFacturaPersonalizada = (factura_personalizada) => ({
    type: SET_FACTURA_PERSONALIZADA,
    factura_personalizada,
});
export const setVentaPermitirDescuento = (venta_permitir_descuento) => ({
    type: SET_VENTA_PERMITIR_DESCUENTO,
    venta_permitir_descuento,
});
export const setVistaBienvenida = (vista_bienvenida) => ({
    type: SET_VISTA_BIENVENIDA,
    vista_bienvenida,
});
export const setBienvenidaVisto = (bienvenida_visto) => ({
    type: SET_BIENVENIDA_VISTO,
    bienvenida_visto,
});

export const setConfCotizacion = (conf_cotizacion) => ({
    type: SET_CONF_COTIZACION,
    conf_cotizacion,
});

export const setTipoPuntoVenta = (tipo_punto_venta) => ({
    type: SET_TIPO_VENTA,
    tipo_punto_venta,
});
export const setConfRedondeo = (conf_venta_redondeo) => ({
    type: SET_CONF_REDONDEO,
    conf_venta_redondeo,
});
export const setConfTiendaOnline = (conf_tienda_online) => ({
    type: SET_CONF_TIENDA_ONLINE,
    conf_tienda_online,
});
export const setInventarioNegativo = (conf_inventario_negativo) => ({
    type: SET_CONF_INVENTARIO_NEGATIVO,
    conf_inventario_negativo,
});
export const setVentaPrecioEditable = (conf_venta_precio_editable) => ({
    type: CONF_VENTA_PRECIO_EDITABLE,
    conf_venta_precio_editable,
});
export const setMostrarReferencia = (conf_mostrar_referencia) => ({
    type: CONF_MOSTRAR_REFERENCIA,
    conf_mostrar_referencia,
});
export const setMostrarPrecioImpresion = (conf_mostrar_precio_impresion) => ({
    type: CONF_MOSTRAR_PRECIO_IMPRESION,
    conf_mostrar_precio_impresion,
});
export const setMetodosPago = (metodos_pago) => ({
    type: METODOS_PAGO,
    metodos_pago,
});
export const setMetodosEntrega = (metodos_entrega) => ({
    type: METODOS_ENTREGA,
    metodos_entrega,
});
export const setNombresPrecios = (nombres_precios) => ({
    type: NOMBRES_PRECIOS,
    nombres_precios,
});
export const setItemsPorFactura = (items_por_factura) => ({
    type: SET_ITEMS_POR_FACTURA,
    items_por_factura,
});
export const setUnidadesMedidaEmpresa = (unidades_de_medida_empresa) => ({
    type: SET_UNIDADES_DE_MEDIDA_EMPRESA,
    unidades_de_medida_empresa,
});

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setConfEsJoyeria = (conf_tipo_negocio) => ({
    type: CONF_TIPO_NEGOCIO,
    conf_tipo_negocio,
});

export const setConfPorcentajePrecio = (conf_porcentaje_precio) => ({
    type: CONF_PORCENTAJE_PRECIO,
    conf_porcentaje_precio,
});

export const setConf3Decimales = (conf_modena_con_3_decimales) => ({
    type: CONF_PRECIO_CON3_DECIMALES,
    conf_modena_con_3_decimales,
});

const setConfVentasAlCredito = (conf_ventas_al_credito) => ({
    type: CONF_EMPRESA_VENTAS_AL_CREDITO,
    conf_ventas_al_credito,
});
export const setHabilitarCampoCliente = (conf_habilitar_campo_cliente) => ({
    type: CONF_HABILITAR_CAMPO_CLIENTE,
    conf_habilitar_campo_cliente,
});

export const setBuscarNombre = (buscar_nombre) => ({
    type: BUSCAR_NOMBRE,
    buscar_nombre,
});

export const setHabilitarNoFactura = (conf_habilitar_no_factura) => ({
    type: CONF_HABILITAR_NO_FACTURA,
    conf_habilitar_no_factura,
});

export const actions = {
    getMe,
    setRuta,
    setSucursal,
    marcarVistoBienvenida,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [ME]: (state, { me }) => {
        return {
            ...state,
            me,
        };
    },
    [RUTA]: (state, { ruta }) => {
        return {
            ...state,
            ruta,
        };
    },
    [SUCURSAL]: (state, { sucursal }) => {
        return {
            ...state,
            sucursal,
        };
    },
    [USUARIOS_SUCURSAL]: (state, { usuarios_sucursal }) => {
        return {
            ...state,
            usuarios_sucursal,
        };
    },
    [SET_FORMATO_FACTURA]: (state, { formato_factura }) => {
        return {
            ...state,
            formato_factura,
        };
    },
    [SET_FACTURA_PERSONALIZADA]: (state, { factura_personalizada }) => {
        return {
            ...state,
            factura_personalizada,
        };
    },
    [SET_UTILIZA_FEL]: (state, { utiliza_fel }) => {
        return {
            ...state,
            utiliza_fel,
        };
    },
    [SET_VENTA_PERMITIR_DESCUENTO]: (state, { venta_permitir_descuento }) => {
        return {
            ...state,
            venta_permitir_descuento,
        };
    },
    [SET_ITEMS_POR_FACTURA]: (state, { items_por_factura }) => {
        return {
            ...state,
            items_por_factura,
        };
    },
    [MENU]: (state, { menu }) => {
        return {
            ...state,
            menu,
        };
    },
    [SET_UNIDADES_DE_MEDIDA_EMPRESA]: (
        state,
        { unidades_de_medida_empresa }
    ) => {
        return {
            ...state,
            unidades_de_medida_empresa,
        };
    },
    [SET_VISTA_BIENVENIDA]: (state, { vista_bienvenida }) => {
        return {
            ...state,
            vista_bienvenida,
        };
    },
    [SET_BIENVENIDA_VISTO]: (state, { bienvenida_visto }) => {
        return {
            ...state,
            bienvenida_visto,
        };
    },
    [SET_CONF_COTIZACION]: (state, { conf_cotizacion }) => {
        return {
            ...state,
            conf_cotizacion,
        };
    },
    [SET_TIPO_VENTA]: (state, { tipo_punto_venta }) => {
        return {
            ...state,
            tipo_punto_venta,
        };
    },
    [SET_CONF_REDONDEO]: (state, { conf_venta_redondeo }) => {
        return {
            ...state,
            conf_venta_redondeo,
        };
    },
    [SET_CONF_TIENDA_ONLINE]: (state, { conf_tienda_online }) => {
        return {
            ...state,
            conf_tienda_online,
        };
    },
    [SET_CONF_INVENTARIO_NEGATIVO]: (state, { conf_inventario_negativo }) => {
        return {
            ...state,
            conf_inventario_negativo,
        };
    },
    [METODOS_PAGO]: (state, { metodos_pago }) => {
        return {
            ...state,
            metodos_pago,
        };
    },
    [METODOS_ENTREGA]: (state, { metodos_entrega }) => {
        return {
            ...state,
            metodos_entrega,
        };
    },
    [NOMBRES_PRECIOS]: (state, { nombres_precios }) => {
        return {
            ...state,
            nombres_precios,
        };
    },
    [CONF_VENTA_PRECIO_EDITABLE]: (state, { conf_venta_precio_editable }) => {
        return {
            ...state,
            conf_venta_precio_editable,
        };
    },
    [CONF_MOSTRAR_REFERENCIA]: (state, { conf_mostrar_referencia }) => {
        return {
            ...state,
            conf_mostrar_referencia,
        };
    },
    [CONF_MOSTRAR_PRECIO_IMPRESION]: (
        state,
        { conf_mostrar_precio_impresion }
    ) => {
        return {
            ...state,
            conf_mostrar_precio_impresion,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },

    [CONF_TIPO_NEGOCIO]: (state, { conf_tipo_negocio }) => {
        return {
            ...state,
            conf_tipo_negocio,
        };
    },

    [CONF_PORCENTAJE_PRECIO]: (state, { conf_porcentaje_precio }) => {
        return {
            ...state,
            conf_porcentaje_precio,
        };
    },
    [CONF_PRECIO_CON3_DECIMALES]: (state, { conf_modena_con_3_decimales }) => {
        return {
            ...state,
            conf_modena_con_3_decimales,
        };
    },
    [CONF_EMPRESA_VENTAS_AL_CREDITO]: (state, { conf_ventas_al_credito }) => {
        return {
            ...state,
            conf_ventas_al_credito,
        };
    },
    [CONF_HABILITAR_CAMPO_CLIENTE]: (
        state,
        { conf_habilitar_campo_cliente }
    ) => {
        return {
            ...state,
            conf_habilitar_campo_cliente,
        };
    },

    [BUSCAR_NOMBRE]: (state, { buscar_nombre }) => {
        return {
            ...state,
            buscar_nombre,
        };
    },

    [CONF_HABILITAR_NO_FACTURA]: (state, { conf_habilitar_no_factura }) => {
        return {
            ...state,
            conf_habilitar_no_factura,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    me: {},
    ruta: "",
    sucursal: undefined,
    usuarios_sucursal: [],
    formato_factura: "",
    factura_personalizada: false,
    conf_tipo_negocio: "",
    utiliza_fel: false,
    venta_permitir_descuento: false,
    menu: false,
    conf_modena_con_3_decimales: false,
    items_por_factura: 0,
    unidades_de_medida_empresa: [],
    vista_bienvenida: null,
    bienvenida_visto: true,
    conf_cotizacion: false,
    tipo_punto_venta: "",
    conf_venta_redondeo: false,
    conf_ventas_al_credito: false,
    conf_tienda_online: false,
    conf_inventario_negativo: false,
    conf_venta_precio_editable: false,
    conf_mostrar_referencia: false,
    conf_mostrar_precio_impresion: false,
    metodos_pago: [],
    metodos_entrega: [],
    nombres_precios: null,
    loader: true,
    conf_porcentaje_precio: false,
    conf_habilitar_campo_cliente: false,
    buscar_nombre: false,
    conf_habilitar_no_factura: false,
};

export default handleActions(reducers, initialState);

import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/reportes/reporte_inventario";
import ListadoInventario from "./ListadoInventario";
import { goBack } from "react-router-redux";


const ms2p = (state) => {

    return {
        ...state.reporte_inventario,
        print_state: state.print,
        conf_tipo_negocio:
        state.configuracion && state.configuracion.config
            ? state.configuracion.config.conf_tipo_negocio
            : false,
    };
};

const md2p = { 
    ...actions,
};

export default connect(ms2p, md2p)(ListadoInventario);
import { connect } from 'react-redux';
import ZohoSalesIQ from './ZohoSalesIQ';

const ms2p = (state) => {

  return {
    usuario: state.usuario.me
  };
};

export default connect(ms2p)(ZohoSalesIQ);

import { connect } from 'react-redux';

import ProductosMasVendidos from './ProductosMasVendidos';
import { actions } from "../../../../redux/modules/reportes/productos_mas_vendidos";

const ms2p = (state) => ({
    ...state.reporte_productos_mas_vendidos,
    usuarios_sucursal: state.usuario.usuarios_sucursal,
    conf_tipo_negocio:
        state.configuracion && state.configuracion.config
            ? state.configuracion.config.conf_tipo_negocio
            : false, // Verificación añadida
})

const md2p = {
    ...actions,
};

export default connect(ms2p, md2p)(ProductosMasVendidos);

import React, { Component } from "react";
import PropTypes from "prop-types";
import ProductoForm from "./ProductoEditarForm";
import { api } from "api";

class ProductoEditar extends Component {
    static propTypes = {
        submit: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            archivo: null,
            n: false,
            seleccion_unidades_medida: false,
            sucursal: null,
            sucursal_activo: null,
        };
        this.onFileChange = this.onFileChange.bind(this);
        this.editarProducto = this.editarProducto.bind(this);
    }

    onFileChange(e, file, key) {
        file = file || e.target.files[0];
        const pattern = /image-*/;
        const reader = new FileReader();
        if (file) {
            if (!file.type.match(pattern)) {
                alert("Formato inválido");
                return;
            }
            // this.setState({ loaded: false });
            reader.onload = (e) => {
                // this.setState({
                //   imageSrc: reader.result,
                //   loaded: true
                // });
                // const clave = this.props.clave;
                // console.log(file,reader);
                // this.props.setFile(file);
                this.setState({ [key]: file });
            };
            reader.readAsDataURL(file);
        }
    }

    cambioSeleccionUnidad = (estado) => {
        this.setState({ seleccion_unidades_medida: estado });
    };

    componentWillMount() {
        this.props.getCategorias();
        this.props.leer(this.props.match.params.id);
    }
    editarProducto() {
        const { producto } = this.props;
        const fotos = [{ name: "imagen", file: this.state.foto_producto }];
        Object.keys(producto).forEach((item) => {
            if (item.includes("imagen_")) {
                const partes = item.split("imagen_");
                const name = `catalogo_${parseInt(partes[1] - 1)}`;
                fotos.push({
                    name: name,
                    file: this.state[name],
                });
            }
        });

        this.props.submit(fotos);
    }

    changeSucursal = (value) => {
        this.setState({ sucursal: value });
    };

    changeSucursalActivo = (id) => {
        this.setState({
            sucursal_activo: this.state.sucursal_activo === id ? null : id,
        });
    };

    render() {
        // state
        const {
            producto,
            loader,
            unidades_de_medida_empresa,
            conf_tienda_online,
            categorias,
            categorias_seleccionados,
            categorias_expanded,
            lista_sucursales,
            nombres_precios,
            conf_porcentaje_precio,
            familias,
        } = this.props;
        // bind
        const {
            cambioTipoProducto,
            asignarCategoria,
            setCategoriasExpanded,
            cambioPorcentajePrecio,
            conf_tipo_negocio,
        } = this.props;

        const getFamilias = (search) => {
            return api
                .get(`familia/?sin_paginacion=true`, { search })
                .then((data) => {
                    const listaFamilias = data.map((item) => ({
                        id: item.id,
                        nombre: item.nombre,
                    }));
                    console.log(
                        "Familias desde editar el coso:",
                        listaFamilias
                    );
                    return { options: listaFamilias };
                })
                .catch((err) => {
                    console.error("Error al obtener las familias:", err);
                    return { options: [] }; // Devuelve un array vacío en caso de error
                });
        };
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <ProductoForm
                        conf_tienda_online={conf_tienda_online}
                        loader={loader}
                        editarProducto={this.editarProducto}
                        onFileChange={this.onFileChange}
                        cambioSeleccionUnidad={this.cambioSeleccionUnidad}
                        producto={producto}
                        unidades_de_medida_empresa={unidades_de_medida_empresa}
                        seleccion_unidades_medida={
                            this.state.seleccion_unidades_medida
                        }
                        cambioTipoProducto={cambioTipoProducto}
                        foto_producto={this.state.foto_producto}
                        categorias={categorias}
                        categorias_seleccionados={categorias_seleccionados}
                        categorias_expanded={categorias_expanded}
                        asignarCategoria={asignarCategoria}
                        setCategoriasExpanded={setCategoriasExpanded}
                        sucursales={lista_sucursales}
                        sucursal={this.state.sucursal}
                        sucursal_activo={this.state.sucursal_activo}
                        changeSucursal={this.changeSucursal}
                        changeSucursalActivo={this.changeSucursalActivo}
                        cambioPorcentajePrecio={cambioPorcentajePrecio}
                        nombres_precios={nombres_precios}
                        conf_porcentaje_precio={conf_porcentaje_precio}
                        conf_tipo_negocio={conf_tipo_negocio}
                        getFamilias={getFamilias}
                    />
                </div>
            </div>
        );
    }
}

export default ProductoEditar;

import React from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { RenderCurrency } from "Utils/renderField/renderReadField";

const TablaConteo = ({ datos, quitar, onSort, config, totalCount,currentPage, onPageChange }) => {
  const options = {
    page: currentPage,
    sizePerPage: 10,
    onPageChange: (page) => onPageChange(page),
    onSortChange: onSort,
    remote: true,
    paginationSize: 3,
    hideSizePerPage: true,
    //noDataText: loading ? "Cargando..." : "No hay productos sin contar",
  };
  const transformarDatos = (items) => {
    return items.map(item => {
      const fraccion = item.fraccion || {};
      const producto = (item.fraccion && item.fraccion.producto) || {};
      
      return {
        rowId: item.id,
        codigoBarras: fraccion.codigo_barras || "",
        nombreProducto: producto.nombre || "",
        kilataje: producto.kilataje || "",
        peso: producto.peso || "",
        existenciaSistema: item.inventario || 0,
        existenciaReal: item.cantidad_fisica || 0,
        diferencia: item.diferencia || 0,
        precio_diferencia: item.precio_diferencia || 0,
        originalItem: item
      };
    });
  };
  const dataTransformada = transformarDatos(datos);

  return (
<BootstrapTable
      data={dataTransformada}
      keyField="rowId"
      remote
      fetchInfo={{ dataTotalSize: totalCount }}
      options={{
        ...options,
        page: currentPage,
        onPageChange: (page) => onPageChange(page),
        hideSizePerPage: true,
        onSort: (sortName, sortOrder) => {
          if (onSort) {
            onSort(sortName, sortOrder);
          }
        },
        remote: true
      }}
      hover
      pagination
    >
      <TableHeaderColumn dataField="codigoBarras" dataSort>
        Código Barras
      </TableHeaderColumn>

      <TableHeaderColumn dataField="nombreProducto" dataSort>
        Producto
      </TableHeaderColumn>


      {config && config.conf_tipo_negocio === "Joyeria" && (
          <TableHeaderColumn
            width="8%"
            dataField="kilataje"
            dataAlign="center"
            dataSort={true}
            dataFormat={(cell) =>
              cell ? <div>{cell}</div> : <div>- - -</div>
            }
          >
            Kilataje
          </TableHeaderColumn>
        )}
        {config && config.conf_tipo_negocio === "Joyeria" && (
          <TableHeaderColumn
            width="8%"
            dataField="peso"
            dataAlign="center"
            dataSort={true}
            dataFormat={(cell) =>
              cell ? <div>{cell}</div> : <div>- - -</div>
            }
          >
            Peso
          </TableHeaderColumn>
        )}

      <TableHeaderColumn dataField="existenciaSistema" dataSort>
        Exist. Sistema
      </TableHeaderColumn>

      <TableHeaderColumn dataField="existenciaReal" dataSort>
        Exist. Real
      </TableHeaderColumn>

      <TableHeaderColumn dataField="diferencia" dataSort>
        Diferencia
      </TableHeaderColumn>

      <TableHeaderColumn
        dataField="precio_diferencia"
        dataSort
        dataFormat={(cell) => {
          if (cell !== null && cell !== undefined) {
            return <RenderCurrency value={cell} />;
          }
          return "-";
        }}
      >
        Dif. Precio
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="acciones"
        dataFormat={(_, row) => (
          <button
            className="btn btn-danger btn-sm"
            onClick={() => {
              if (quitar) {
                quitar(row.originalItem.fraccion.id);
              }
            }}
          >
            Quitar
          </button>
        )}
      >
        Acciones
      </TableHeaderColumn>
    </BootstrapTable>
  );
};

TablaConteo.propTypes = {
  datos: PropTypes.array.isRequired,
  quitar: PropTypes.func,
  onSort: PropTypes.func,
  options: PropTypes.object,
  config: PropTypes.object,
  totalCount: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func
};

export default TablaConteo;

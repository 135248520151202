import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
// Pestañas
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import ToolbarInventario from './ToolbarInventario';
import ToolBarHistorial from "../../Utils/Toolbar/ToolbarHistorial";
import { icons } from "icons";
import { PrintContainer, PrintComponent } from "Utils/Print";
import MovimientosPDF from "../../Utils/MovimientosPDF";
import {
    RenderImage,
    RenderDateTime,
    RenderMoneda,
    RenderNumero,
} from "Utils/renderField/renderTableField";

import {
    ActionItem,
    HeaderItem,
} from "react-dropdown-advanced";

import ModalAyuda from "Utils/Ayuda";

import Select, { Async } from "react-select";
import "react-select/dist/react-select.css";
import DatePicker from "Utils/DatePicker";
import BodegaListadoPDF from "../../Utils/BodegaListadoPDF/BodegaListadoPDF";
import HistorialPDF from "../../Utils/HistorialPDF/HistorialPDF";

class Apertura extends Component {
    static propTypes = {
        bodegaData: PropTypes.object.isRequired,
        loaderBodega: PropTypes.bool.isRequired,
        usuario: PropTypes.object.isRequired,
        filtroBodega: PropTypes.string.isRequired,
        filtroChange: PropTypes.func.isRequired,
        listar: PropTypes.func.isRequired,
        pageBodega: PropTypes.number.isRequired,
        sortChange: PropTypes.func.isRequired,
        estadoDescargaBodega: PropTypes.bool.isRequired,
        config: PropTypes.object.isRequired,
        buscar: PropTypes.func,
        searchBodega: PropTypes.string,

        // --- Historial (segundo tab) ---
        reporteData: PropTypes.object,
        reporteLoader: PropTypes.bool,
        pageReporte: PropTypes.number,
        fechaReporte: PropTypes.string,
        idUsuarioReporte: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        idProveedorReporte: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        getHistorialBodega: PropTypes.func,
        tipoMovimientoChange: PropTypes.func,
        idUsuarioChange: PropTypes.func,
        idProveedorChange: PropTypes.func,
        setFecha: PropTypes.func,
        sortChangeHistorial: PropTypes.func,
        descargarInventario: PropTypes.func,
        descargarHistorial: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            dropdownItems: [
                new HeaderItem("¿Con qué necesitas ayuda?"),
                new ActionItem("ingreso", "¿Cómo realizar un ingreso de mercadería?"),
                new ActionItem("baja", "¿Cómo realizar un reporte de baja?"),
                new ActionItem("reajuste", "¿Cómo realizar un reajuste?"),
            ],
            familiaValue: null,
            kilatajeValue: null,
            modalAyuda: false,
            itemsImagenes: [],
            selectedMovimiento: null,
            itemsBodegaIngreso: [
                {
                    src: icons.bodega_ingreso_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.bodega_ingreso_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.bodega_ingreso_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.bodega_ingreso_p4,
                    alt: "Paso 4",
                },
                {
                    src: icons.bodega_ingreso_p5,
                    alt: "Paso 5",
                },
                {
                    src: icons.bodega_ingreso_p6,
                    alt: "Paso 6",
                },
                {
                    src: icons.bodega_ingreso_p7,
                    alt: "Paso 7",
                },
                {
                    src: icons.bodega_ingreso_p8,
                    alt: "Paso 8",
                },
                {
                    src: icons.bodega_ingreso_p9,
                    alt: "Paso 9",
                },
                {
                    src: icons.bodega_ingreso_p10,
                    alt: "Paso 10",
                },
                {
                    src: icons.bodega_ingreso_p11,
                    alt: "Paso 11",
                },
                {
                    src: icons.bodega_ingreso_p12,
                    alt: "Paso 12",
                },
            ],
            itemsBodegaBaja: [
                {
                    src: icons.bodega_baja_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.bodega_baja_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.bodega_baja_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.bodega_baja_p4,
                    alt: "Paso 4",
                },
                {
                    src: icons.bodega_baja_p5,
                    alt: "Paso 5",
                },
                {
                    src: icons.bodega_baja_p6,
                    alt: "Paso 6",
                },
                {
                    src: icons.bodega_baja_p7,
                    alt: "Paso 7",
                },
                {
                    src: icons.bodega_baja_p8,
                    alt: "Paso 8",
                },
            ],
            itemsBodegaReajuste: [
                {
                    src: icons.bodega_reajuste_p1,
                    alt: "Paso 1",
                },
                {
                    src: icons.bodega_reajuste_p2,
                    alt: "Paso 2",
                },
                {
                    src: icons.bodega_reajuste_p3,
                    alt: "Paso 3",
                },
                {
                    src: icons.bodega_reajuste_p4,
                    alt: "Paso 4",
                },
                {
                    src: icons.bodega_reajuste_p5,
                    alt: "Paso 5",
                },
                {
                    src: icons.bodega_reajuste_p6,
                    alt: "Paso 6",
                },
                {
                    src: icons.bodega_reajuste_p7,
                    alt: "Paso 7",
                },
                {
                    src: icons.bodega_reajuste_p8,
                    alt: "Paso 8",
                },
            ],

            // Manejo de pestañas
            tabIndex: 0,
            inventarioOrdering: this.props.sort || "-creado",
            historialOrdering: this.props.sort || "-creado",
        };
    }

    componentWillMount() {
        // Cargar inventario actual
        this.props.setSeleccionados([]);
        this.props.listar(this.props.pageBodega);
        this.props.listarFamilias();
    }
    componentDidUpdate(prevProps) {
        // Si no hay movimiento seleccionado y llegan resultados en reporteData, selecciona el primero
        if (
            (!this.state.selectedMovimiento || !this.state.selectedMovimiento.id) &&
            this.props.reporteData &&
            this.props.reporteData.results &&
            this.props.reporteData.results.length > 0
        ) {
            if (
                !prevProps.reporteData ||
                !prevProps.reporteData.results ||
                prevProps.reporteData.results.length !== this.props.reporteData.results.length
            ) {
                const firstMovimiento = this.props.reporteData.results[0];
                this.setState({ selectedMovimiento: firstMovimiento });
                // Llamar a getDetalle para obtener los detalles del primer movimiento
                this.props.getDetalle(firstMovimiento.id);
            }
        }
    }

    onClickAyuda = (item) => {
        if (item.key === "ingreso") {
            this.setState({ itemsImagenes: this.state.itemsBodegaIngreso });
            this.openModal();
        } else if (item.key === "baja") {
            this.setState({ itemsImagenes: this.state.itemsBodegaBaja });
            this.openModal();
        } else if (item.key === "reajuste") {
            this.setState({ itemsImagenes: this.state.itemsBodegaReajuste });
            this.openModal();
        }
    };

    closeModal = () => {
        this.setState({ modalAyuda: false });
    };

    openModal = () => {
        this.setState({ modalAyuda: true });
    };

    handleTabSelect = (tabIndex) => {
        this.setState({ tabIndex });
    };
    handleFamiliaChange = (value) => {
        this.setState({ familiaValue: value });
        this.props.listar(this.props.pageBodega, { familia: value });
    };

    handleKilatajeChange = (value) => {
        this.setState({ kilatajeValue: value });
        this.props.listar(this.props.pageBodega, { kilataje: value });
    };
    render() {

        const {
            bodegaData,
            loaderBodega,
            estadoDescargaBodega,
            usuario,
            filtroBodega,
            config,
            descargarListado,
            buscar,
            searchBodega,
        } = this.props;

        const { modalAyuda, itemsImagenes, dropdownItems, familiaValue, kilatajeValue } = this.state;

        const options = Object.assign({}, tableOptions);
        options.noDataText = loaderBodega ? "Cargando..." : options.noDataText;
        options.page = this.props.pageBodega;
        options.onPageChange = this.props.listar;
        options.onSortChange = this.props.sortChange;

        // ---------------------------
        // Props Historial (Alias)
        // ---------------------------
        const {
            reporteLoader,
            reporteData,
            fechaInicialReporte,
            fechaFinalReporte,
            idUsuarioChange,
            idProveedorChange,
            getHistorialBodega,
            tipoMovimientoChange,
            setFecha,
        } = this.props;

        const optionsHist = Object.assign({}, tableOptions);
        optionsHist.noDataText = reporteLoader ? "Cargando..." : tableOptions.noDataText;
        optionsHist.page = this.props.pageReporte;
        optionsHist.onPageChange = (newPage) => {
            this.props.setPageHistorial(newPage)
            const ordering = this.state.historialOrdering || "";
            if (this.state.selectedMovimiento && this.state.selectedMovimiento.id) {
                this.props.getDetalle(this.state.selectedMovimiento.id, newPage, ordering);
            }
        };
        optionsHist.onSortChange = (sortField, sortOrder) => {
            const ordering = sortOrder === "asc" ? sortField : `-${sortField}`;
            this.setState({ historialOrdering: ordering });
            this.props.sortChangeHistorial(sortField, sortOrder);
            if (this.state.selectedMovimiento && this.state.selectedMovimiento.id) {
                this.props.getDetalle(this.state.selectedMovimiento.id, this.props.pageReporte, ordering);
            }
        };

        const opcionesFamilia = (this.props.familias || []).map(f => ({
            value: f.id,
            label: f.nombre || "Sin nombre"
        }));

        const movimientoOptions =
            reporteData && Array.isArray(reporteData.results)
                ? reporteData.results
                    .filter(movimiento => movimiento && movimiento.id && movimiento.creado)
                    .map(movimiento => ({
                        value: movimiento.id,
                        label: `${moment(movimiento.creado).format("DD/MM/YYYY HH:mm")} - ${movimiento.usuario && movimiento.usuario.first_name
                            ? movimiento.usuario.first_name
                            : "Sin usuario"
                            }`
                    }))
                : [];

        const columnStyle = {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden"
        };
        const opcionesKilataje = [
            {
                label: "10k",
                value: 10,
            },
            {
                label: "14k",
                value: 14,
            },
            {
                label: "18k",
                value: 18,
            },
            {
                label: "21k",
                value: 21,
            },
            {
                label: "24k",
                value: 24,
            },
        ];

        return (
            <div className="row">
                {this.props.print_state.flag && <PrintComponent />}
                <div className="col-lg-12 col-md-12 col-sm-12">
                    {/* Botones superiores (Ingreso, Baja, etc.) */}
                    <div className="d-flex justify-content-between flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row">
                        {/* Ingreso de mercadería */}
                        <div className="text-center flex1">
                            {usuario.igreso_mercaderia ? (
                                <Link className="a-remove-fromat" to="/bodega/ingreso_de_mercaderia/">
                                    <img
                                        className="icon-img"
                                        style={{ maxWidth: "60px" }}
                                        src={icons.ingreso_bodega}
                                        alt=""
                                    />
                                    &nbsp;
                                    <button className="btn btn-secondary btn-bodega">
                                        Ingreso de Mercadería
                                    </button>
                                </Link>
                            ) : (
                                <div>
                                    <img
                                        className="icon-img"
                                        style={{ maxWidth: "60px" }}
                                        src={icons.ingreso_bodega}
                                        alt=""
                                    />
                                    &nbsp;
                                    <button disabled className="btn btn-secondary btn-bodega">
                                        Ingreso de Mercadería
                                    </button>
                                </div>
                            )}
                        </div>

                        {/* Reportar Baja */}
                        <div className="text-center flex1 border-left-bodega">
                            {usuario.baja_mercaderia ? (
                                <Link className="a-remove-fromat" to="/bodega/reportar_baja/">
                                    <img
                                        className="icon-img"
                                        style={{ maxWidth: "60px" }}
                                        src={icons.baja_bodega}
                                        alt=""
                                    />
                                    &nbsp;
                                    <button className="btn btn-danger btn-bodega">
                                        Reportar Baja
                                    </button>
                                </Link>
                            ) : (
                                <div>
                                    <img
                                        className="icon-img"
                                        style={{ maxWidth: "60px" }}
                                        src={icons.baja_bodega}
                                        alt=""
                                    />
                                    &nbsp;
                                    <button disabled className="btn btn-danger btn-bodega">
                                        Reportar Baja
                                    </button>
                                </div>
                            )}
                        </div>

                        {/* Salida de Producto */}
                        <div className="text-center flex1 border-left-bodega">
                            {usuario.salida_mercaderia ? (
                                <Link className="a-remove-fromat" to="/bodega/salida_de_producto/">
                                    <img
                                        className="icon-img"
                                        style={{ maxWidth: "60px" }}
                                        src={icons.salida_producto_bodega}
                                        alt=""
                                    />
                                    &nbsp;
                                    <button className="btn btn-tertiary btn-bodega">
                                        Salida de Producto
                                    </button>
                                </Link>
                            ) : (
                                <div>
                                    <img
                                        className="icon-img"
                                        style={{ maxWidth: "60px" }}
                                        src={icons.salida_producto_bodega}
                                        alt=""
                                    />
                                    &nbsp;
                                    <button disabled className="btn btn-tertiary btn-bodega">
                                        Salida de Producto
                                    </button>
                                </div>
                            )}
                        </div>

                        {/* Inventario */}
                        <div className="text-center flex1 border-left-bodega">
                            {usuario.reajuste_mercaderia ? (
                                <Link className="mb1 a-remove-fromat" to="/bodega/inventario/">
                                    <img
                                        className="icon-img"
                                        style={{ maxWidth: "60px" }}
                                        src={icons.reajuste_bodega}
                                        alt=""
                                    />
                                    &nbsp;
                                    <button className="btn btn-primary btn-bodega">
                                        Inventario
                                    </button>
                                </Link>
                            ) : (
                                <div>
                                    <img
                                        className="icon-img"
                                        style={{ maxWidth: "60px" }}
                                        src={icons.reajuste_bodega}
                                        alt=""
                                    />
                                    &nbsp;
                                    <button disabled className="btn btn-primary btn-bodega">
                                        Inventario
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Tabs */}
                    <Tabs
                        selectedIndex={this.state.tabIndex}
                        onSelect={this.handleTabSelect}
                        className="mt-3"
                    >
                        <TabList className="tab_list d-flex">
                            <Tab className="content-tab-venta flex-grow-1 text-center fnt-14" selectedClassName="content-tab-venta-active">
                                INVENTARIO ACTUAL
                            </Tab>
                            <Tab className="content-tab-venta flex-grow-1 text-center fnt-14" selectedClassName="content-tab-venta-active">
                                HISTORIAL
                            </Tab>
                        </TabList>

                        {/* TAB 1: INVENTARIO ACTUAL */}
                        <TabPanel>
                            <div className="grid-container">
                                {/* Toolbar */}
                                <div className="grid-titulo">
                                    <ToolbarInventario
                                        titulo={"Bodega"}
                                        buscar={buscar}
                                        search={searchBodega}
                                        estado_descarga={estadoDescargaBodega}
                                        descarga_excel={this.props.descargarInventario}
                                        itemsAyuda={dropdownItems}
                                        actionAyuda={this.onClickAyuda}
                                        total_inventario={this.props.totales_bodega && this.props.totales_bodega.total_inventario}
                                        total_costo={
                                            this.props.totales_bodega && this.props.totales_bodega.total_costo
                                                ? parseFloat(this.props.totales_bodega.total_costo).toFixed(2)
                                                : "0.00"
                                        }
                                        total_peso={this.props.totales_bodega && this.props.totales_bodega.total_peso}
                                        total_productos={this.props.totales_bodega && this.props.totales_bodega.total_productos}
                                        familiaOptions={opcionesFamilia}

                                        kilatajeOptions={opcionesKilataje}
                                        filtroValue={filtroBodega}
                                        onFiltroChange={(value) => this.props.filtroChange(value)}
                                        familiaValue={this.state.familiaValue}
                                        onFamiliaChange={this.handleFamiliaChange}
                                        kilatajeValue={this.state.kilatajeValue}
                                        onKilatajeChange={this.handleKilatajeChange}
                                        onImprimir={() => this.props.impresionBodega()}
                                        joyeria={config.conf_tipo_negocio === "Joyeria"}
                                    />
                                </div>

                                <LoadMask loading={loaderBodega} light blur radius>
                                    <BootstrapTable
                                        data={loaderBodega ? [] : (bodegaData && bodegaData.results ? bodegaData.results : [])}
                                        remote
                                        pagination
                                        hover
                                        fetchInfo={{ dataTotalSize: bodegaData && bodegaData.count ? bodegaData.count : 0 }}
                                        options={options}
                                    >
                                        <TableHeaderColumn width="0%" isKey dataField="id" dataSort>
                                            ID
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            width="8%"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataField="nombre"
                                            dataFormat={(cell, row) => (
                                                row.producto && row.producto.img_cropped ?
                                                    <RenderImage src={row.producto.img_cropped} alt={cell} />
                                                    : "---"
                                            )}
                                        >
                                            Img
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataField="codigo_barras"
                                            dataFormat={(cell) => cell || "---"}
                                            dataSort
                                        >
                                            Código
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            width="20%"
                                            dataField="producto__nombre"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell, row) => (
                                                <div>
                                                    <b>{row.producto ? row.producto.nombre : "---"}</b>
                                                    <div>{row.nombre}</div>
                                                </div>
                                            )}
                                            dataSort
                                        >
                                            Nombre
                                        </TableHeaderColumn>

                                        {config && config.conf_tipo_negocio === "Joyeria" && (

                                            <TableHeaderColumn
                                                width="6%"
                                                dataField="producto__kilataje"
                                                dataFormat={(cell, row) =>
                                                    row.producto && row.producto.kilataje ? row.producto.kilataje : "---"
                                                }

                                                dataSort
                                            >
                                                Kilataje
                                            </TableHeaderColumn>
                                        )}
                                        {config && config.conf_tipo_negocio === "Joyeria" && (
                                            <TableHeaderColumn
                                                width="6%"
                                                dataField="producto__peso"
                                                dataFormat={(cell, row) =>
                                                    row.producto && row.producto.peso ? row.producto.peso : "---"
                                                }
                                                dataSort
                                            >
                                                Peso
                                            </TableHeaderColumn>
                                        )}

                                        <TableHeaderColumn
                                            width="8%"
                                            dataField="producto__marca"
                                            dataFormat={(cell, row) =>
                                                row.producto && row.producto.marca ? row.producto.marca : "---"
                                            }
                                            dataSort
                                        >
                                            Marca
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            width="9%"
                                            dataField="costo"
                                            dataSort
                                            dataFormat={(cell, row) => {
                                                const costo = config.conf_costo_promedio && row.costo_promedio_ponderado ?
                                                    row.costo_promedio_ponderado :
                                                    cell;
                                                return <RenderMoneda monto={costo} />;
                                            }}
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                        >
                                            Costo
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            dataField="fecha_ultima_compra"
                                            dataSort
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataFormat={(cell) => <RenderDateTime fecha={cell} />}
                                        >
                                            Última compra
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            width="9%"
                                            dataField="inventario_transito"
                                            dataSort
                                            dataFormat={(cell) => cell || 0}
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                        >
                                            Producto en tránsito
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            width="9%"
                                            dataField="inventario_a_recibir"
                                            dataSort
                                            dataFormat={(cell) => cell || 0}
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                        >
                                            Producto por recibir
                                        </TableHeaderColumn>

                                        <TableHeaderColumn
                                            width="9%"
                                            dataField="inventario"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataSort
                                            dataFormat={(cell, row) => {
                                                const alerta = row.minimo_existencias !== null && row.minimo_existencias >= cell;
                                                return (
                                                    <div className={alerta ? "rojo" : ""}>
                                                        {alerta && <i className="fa fa-exclamation-circle" />}
                                                        &nbsp;
                                                        {row.producto && row.producto.a_granel ?
                                                            cell.toFixed(3) :
                                                            cell.toFixed(2)}
                                                    </div>
                                                );
                                            }}
                                        >
                                            Inventario
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </LoadMask>
                            </div>
                        </TabPanel>

                        {/* TAB 2: HISTORIAL */}
                        <TabPanel>
                            <div className="px-3 pt-3" style={{ backgroundColor: "white" }}>
                                {/* Row para selectores de fecha y movimiento */}
                                <div className="row align-items-center">
                                    <div className="col-sm-3">
                                        <label>Fecha Inicial</label>
                                        <DatePicker
                                            value={fechaInicialReporte}
                                            maxDate={new Date()}
                                            onChange={(date) => this.props.setFecha("Inicial", date)}
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <label>Fecha Final</label>
                                        <DatePicker
                                            value={fechaFinalReporte}
                                            maxDate={new Date()}
                                            onChange={(date) => this.props.setFecha("Final", date)}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <label>Seleccionar Movimiento</label>
                                        <Select
                                            options={movimientoOptions}
                                            value={movimientoOptions.find(
                                                option =>
                                                    option.value ===
                                                    (this.state.selectedMovimiento ? this.state.selectedMovimiento.id : null)
                                            )}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    border: "1px solid gray"
                                                })
                                            }}
                                            onChange={(selectedOption) => {
                                                // Busca el movimiento seleccionado en reporteData.results
                                                const selectedMovimiento =
                                                    reporteData && reporteData.results
                                                        ? reporteData.results.find(
                                                            m =>
                                                                m.id ===
                                                                (selectedOption && selectedOption.value ? selectedOption.value : null)
                                                        )
                                                        : null;
                                                this.setState({ selectedMovimiento });
                                                this.props.getDetalle(selectedOption.value);
                                            }}
                                            placeholder="Seleccione un movimiento..."
                                            getOptionValue={(option) => option.value}
                                            getOptionLabel={(option) => option.label}
                                        />
                                    </div>
                                </div>

                                {(() => {
                                    const detalles = this.props.detalle && this.props.detalle.results
                                        ? this.props.detalle.results
                                        : [];


                                    return (
                                        <div>
                                            {/* Sección de Totales */}
                                            <div className="row mb-2 mt-2">
                                                <div className="col d-flex align-items-end justify-content-end">
                                                    {this.state.selectedMovimiento && (
                                                        <React.Fragment>
                                                            <div style={{
                                                                marginRight: "2rem",
                                                                textAlign: "right",
                                                                backgroundColor: "#f8f9fa",
                                                                padding: "0.5rem 1rem",
                                                                borderRadius: "4px"
                                                            }}>
                                                                <small className="text-muted">Total Diferencia</small>
                                                                <div className="font-weight-bold">
                                                                    <RenderNumero monto={this.props.totales_historial && this.props.totales_historial.total_diferencias ? this.props.totales_historial.total_diferencias : 0} />
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                marginRight: "2rem",
                                                                textAlign: "right",
                                                                backgroundColor: "#f8f9fa",
                                                                padding: "0.5rem 1rem",
                                                                borderRadius: "4px"
                                                            }}>
                                                                <small className="text-muted">Total Diferencia Precio</small>
                                                                <div className="font-weight-bold">
                                                                    <RenderMoneda monto={this.props.totales_historial && this.props.totales_historial.total_precios ? this.props.totales_historial.total_precios : 0} />
                                                                </div>
                                                            </div>
                                                            { config.conf_tipo_negocio === "Joyeria" && <div style={{
                                                                marginRight: "2rem",
                                                                textAlign: "right",
                                                                backgroundColor: "#f8f9fa",
                                                                padding: "0.5rem 1rem",
                                                                borderRadius: "4px"
                                                            }}>
                                                                <small className="text-muted">Total Peso</small>
                                                                <div className="font-weight-bold">
                                                                    <p style={{margin: 0}}>{this.props.totales_historial && this.props.totales_historial.total_peso ? this.props.totales_historial.total_peso : 0} grs.</p> 
                                                                </div>
                                                            </div>}
                                                            <button
                                                                className="btn btn-secondary"
                                                                onClick={() => {
                                                                    this.props.imprimirListado(this.state.selectedMovimiento.id);
                                                                }}
                                                                disabled={this.props.estado_descarga}
                                                            >
                                                                {this.props.estado_descarga ? (
                                                                    <i className="fa fa-spinner fa-pulse" />
                                                                ) : (
                                                                    <i className="fa fa-print" />
                                                                )}{" "}
                                                                Imprimir
                                                            </button>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                            {/* Tabla Historial */}
                                            <LoadMask loading={this.props.reporteLoader} light blur radius>
                                                <BootstrapTable
                                                    data={detalles.length > 0 ? detalles : []}
                                                    remote
                                                    pagination
                                                    hover
                                                    fetchInfo={{ dataTotalSize: this.props.detalle && this.props.detalle.count ? this.props.detalle.count : 0 }}
                                                    options={optionsHist}
                                                >
                                                    <TableHeaderColumn isKey dataField="id" hidden>
                                                        ID
                                                    </TableHeaderColumn>

                                                    <TableHeaderColumn
                                                        width="8%"
                                                        dataField="fraccion"
                                                        tdStyle={BreakLine}
                                                        thStyle={BreakLine}
                                                        dataFormat={(cell, row) => {
                                                            if (!cell || !cell.producto) return "---";
                                                            const url = cell.producto.img_cropped || "";
                                                            const altText = cell.producto.nombre || "";
                                                            return <RenderImage src={url} alt={altText} />;
                                                        }}
                                                    >
                                                        Img
                                                    </TableHeaderColumn>

                                                    <TableHeaderColumn
                                                        dataField="fraccion"
                                                        tdStyle={BreakLine}
                                                        thStyle={BreakLine}
                                                        dataSort
                                                        dataFormat={(cell) => {
                                                            if (!cell || !cell.codigo_barras) return "---";
                                                            return cell.codigo_barras;
                                                        }}
                                                    >
                                                        Código
                                                    </TableHeaderColumn>


                                                    {/* ARTÍCULO */}
                                                    <TableHeaderColumn
                                                        dataField="producto"
                                                        tdStyle={BreakLine}
                                                        dataSort
                                                        thStyle={BreakLine}
                                                        dataFormat={(cell) => (cell ? cell : "---")}
                                                    >
                                                        Artículo
                                                    </TableHeaderColumn>
                                                    {config && config.conf_tipo_negocio === "Joyeria" && (

                                                        <TableHeaderColumn
                                                            width="8%"
                                                            tdStyle={BreakLine}
                                                            thStyle={BreakLine}
                                                            dataSort
                                                            dataFormat={(_, row) => (
                                                                (row.fraccion &&
                                                                    row.fraccion.producto &&
                                                                    row.fraccion.producto.kilataje) || "---"
                                                            )}
                                                        >
                                                            Kilataje
                                                        </TableHeaderColumn>
                                                    )}
                                                    {config && config.conf_tipo_negocio === "Joyeria" && (

                                                        <TableHeaderColumn
                                                            width="8%"
                                                            tdStyle={BreakLine}
                                                            thStyle={BreakLine}
                                                            dataSort
                                                            dataFormat={(_, row) => {
                                                                return (row.fraccion &&
                                                                    row.fraccion.producto &&
                                                                    row.fraccion.producto.peso) || "---";
                                                            }}
                                                        >
                                                            Peso
                                                        </TableHeaderColumn>

                                                    )}
                                                    {/* Cant. Antes */}
                                                    <TableHeaderColumn
                                                        dataField="existencias_antes"
                                                        tdStyle={BreakLine}
                                                        dataSort
                                                        thStyle={BreakLine} dataFormat={(cell) => <RenderNumero monto={cell || 0} />}
                                                    >
                                                        Cant. Antes
                                                    </TableHeaderColumn>

                                                    {/* Cant. Después */}
                                                    <TableHeaderColumn
                                                        dataField="existencias_despues"
                                                        tdStyle={BreakLine}
                                                        dataSort
                                                        thStyle={BreakLine} dataFormat={(cell) => <RenderNumero monto={cell || 0} />}
                                                    >
                                                        Cant. Después
                                                    </TableHeaderColumn>

                                                    {/* Diferencia */}
                                                    <TableHeaderColumn
                                                        dataField="diferencia"
                                                        tdStyle={BreakLine}
                                                        dataSort
                                                        thStyle={BreakLine}
                                                        dataFormat={(cell, row) => {
                                                            const antes = parseFloat(row.existencias_antes || 0);
                                                            const despues = parseFloat(row.existencias_despues || 0);
                                                            const dif = despues - antes;
                                                            return <RenderNumero monto={dif} />;
                                                        }}
                                                    >
                                                        Diferencia
                                                    </TableHeaderColumn>

                                                    <TableHeaderColumn
                                                        dataField="existencias_antes"
                                                        tdStyle={BreakLine}
                                                        dataSort
                                                        thStyle={BreakLine}
                                                        dataFormat={(cell, row) => (
                                                            <RenderNumero monto={row.existencias_antes || 0} />
                                                        )}
                                                    >
                                                        Exist. en sistema
                                                    </TableHeaderColumn>

                                                    {/* Exist. real */}
                                                    <TableHeaderColumn
                                                        dataField="existencias_despues"
                                                        tdStyle={BreakLine}
                                                        thStyle={BreakLine}
                                                        dataSort
                                                        dataFormat={(cell, row) => (
                                                            <RenderNumero monto={row.existencias_despues || 0} />
                                                        )}
                                                    >
                                                        Exist. real
                                                    </TableHeaderColumn>

                                                    {/* Precio público */}
                                                    <TableHeaderColumn
                                                        dataField="precio"
                                                        tdStyle={BreakLine}
                                                        dataSort
                                                        thStyle={BreakLine}
                                                        dataFormat={(cell) => <RenderMoneda monto={cell || 0} />}
                                                    >
                                                        Precio público
                                                    </TableHeaderColumn>

                                                    {/* Costo */}
                                                    <TableHeaderColumn
                                                        dataField="costo"
                                                        tdStyle={BreakLine}
                                                        thStyle={BreakLine}
                                                        dataSort
                                                        dataFormat={(cell) => <RenderMoneda monto={cell || 0} />}
                                                    >
                                                        Costo
                                                    </TableHeaderColumn>
                                                </BootstrapTable>
                                            </LoadMask>
                                        </div>
                                    );
                                })()}
                            </div>
                        </TabPanel>

                    </Tabs>
                </div>

                {/* Modal de Ayuda */}
                <ModalAyuda
                    open={modalAyuda}
                    closeModal={this.closeModal}
                    openModal={this.openModal}
                    items={itemsImagenes}
                />
                <div className="w100 print-only">

                    <PrintContainer
                        name="printBodega"
                        className="impresion-venta d-table w-100"
                    >
                        <BodegaListadoPDF
                            data={this.props.data_print ? { listado: this.props.data_print, totales: this.props.totales_bodega } : { listado: [], totales: {} }}
                            conf_tipo_negocio={config.conf_tipo_negocio}
                        />
                    </PrintContainer>
                </div>
                <div className="w100 print-only">

                    <PrintContainer
                        name="HistorialPDF"
                        className="impresion-venta d-table w-100"
                    >
                        <HistorialPDF
                            data={this.props.data_print_historial ? this.props.data_print_historial : []}
                            fecha_i={fechaInicialReporte}
                            fecha_f={fechaFinalReporte}
                            totalDiferencia={this.props.totales_historial && this.props.totales_historial.total_diferencias ? this.props.totales_historial.total_diferencias : 0}
                            totalPrecio={this.props.totales_historial && this.props.totales_historial.total_precios ? this.props.totales_historial.total_precios : 0}
                            conf_tipo_negocio={config.conf_tipo_negocio}
                            totalPeso={this.props.totales_historial && this.props.totales_historial.total_peso ? this.props.totales_historial.total_peso : 0}
                            joyeria={config.conf_tipo_negocio === "Joyeria"}
                            />
                    </PrintContainer>
                </div>
            </div>
        );
    }
}

export default Apertura;

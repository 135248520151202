import { handleActions } from "redux-actions";
import { push, goBack } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import Swal from "sweetalert2";
import { ToastStore } from "react-toasts";
import { tipo_movimientos } from "variables";
import { api } from "api";
import { PVMCE } from "utility/variables";
import { actions as usuarioActions } from "../usuario/usuario";
import { actions as printActions } from "../print/print";
import uuidv1 from "uuid/v1";
import ListadoVentas from "../../../common/components/Reportes/Ventas/Listado/ListadoVentas";

// ------------------------------------
// Constants
// ------------------------------------
export const CAJAS = "CAJA_CAJAS";
export const DATA_CAJAS = "CAJA_DATA_CAJAS";
export const ULTIMO_CIERRE = "CAJA_ULTIMO_CIERRE";
export const APERTURA = "CAJA_APERTURA";
export const CAJA = "CAJA_CAJA";
export const CAJA_ID = "CAJA_CAJA_ID";
export const PAGE = "CAJA_PAGE";
export const BUSCADOR = "CAJA_BUSCADOR";
export const SORT = "CAJA_SORT";
export const LOADER = "CAJA_LOADER";
export const LOADER_PRINT = "CAJA_LOADER_PRINT";
export const DATA_MOVIMIENTO_CAJA = "CAJA_DATA_MOVIMIENTO_CAJA";
export const LOADER_MOVIMIENTO_CAJA = "CAJA_LOADER_MOVIMIENTO_CAJA";
export const SET_IMPRESION = "CAJA_SET_IMPRESION";
export const SET_RECIBO_CAJA_IMPRESION = "CAJA_SET_RECIBO_CAJA_IMPRESION";
export const UUID_REQ_LIST_MOVIMIENTOS = "CAJA_UUID_REQ_LIST_MOVIMIENTOS";
export const ESTADO_DESCARGA = "MOVIMIENTOS_ESTADO_DESCARGA";

export const constants = {};

const endpoint = "cajas";
const endpoint_historial_ventas = "historial_ventas";
const endpoint_fel = "factura_electronica";
// ------------------------------------
// Actions
// ------------------------------------
export const listarPaginado =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        const store = getStore();
        const caja = store.caja;
        const { ordering, search } = caja;
        api.get(endpoint, { page, ordering, search })
            .catch((err) => {})
            .then((data) => {
                if (data) {
                    dispatch(setDataCajas(data));
                }
                dispatch(setPage(page));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const listar = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/all`)
        .catch((err) => {})
        .then((data) => {
            if (data) {
                dispatch(setCajas(data.results));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listarPaginado());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    if (sortOrder === "asc") {
        dispatch(setSort(sortName));
    } else {
        dispatch(setSort(`-${sortName}`));
    }
    const store = getStore();
    const page = store.caja.page;
    dispatch(listarPaginado(page));
};

export const crear = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const data = store.form.CajaCrear.values;
    api.post(endpoint, data)
        .catch((err) => {
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        })
        .then((resp) => {
            if (resp) {
                ToastStore.success("Datos almacenados correctamente");
                dispatch(goBack());
            }
            dispatch(setLoader(false));
        });
};

export const aperturar = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const id = store.caja.id;
    const data = store.form.CajaApertura.values
        ? store.form.CajaApertura.values
        : {};
    let total = 0;
    // region Total
    if (data) {
        total = data.b1000 ? total + parseInt(data.b1000) * 1000 : total;
        total = data.b500 ? total + parseInt(data.b500) * 500 : total;
        total = data.b200 ? total + parseInt(data.b200) * 200 : total;
        total = data.b100 ? total + parseInt(data.b100) * 100 : total;
        total = data.b50 ? total + parseInt(data.b50) * 50 : total;
        total = data.b20 ? total + parseInt(data.b20) * 20 : total;
        total = data.b10 ? total + parseInt(data.b10) * 10 : total;
        total = data.b5 ? total + parseInt(data.b5) * 5 : total;
        total = data.b1 ? total + parseInt(data.b1) : total;
        total = data.m2000 ? total + parseInt(data.m2000) * 20 : total;
        total = data.m1000 ? total + parseInt(data.m1000) * 10 : total;
        total = data.m500 ? total + parseInt(data.m500) * 5 : total;
        total = data.m200 ? total + parseInt(data.m200) * 2 : total;
        total = data.m100 ? total + parseInt(data.m100) : total;
        total = data.m50 ? total + parseInt(data.m50) * 0.5 : total;
        total = data.m25 ? total + parseInt(data.m25) * 0.25 : total;
        total = data.m20 ? total + parseInt(data.m20) * 0.2 : total;
        total = data.m10 ? total + parseInt(data.m10) * 0.1 : total;
        total = data.m5 ? total + parseInt(data.m5) * 0.05 : total;
        total = data.m1 ? total + parseInt(data.m1) * 0.01 : total;
        total = total.toFixed(2);
    }
    // endregion
    api.post("aperturas_cierres_caja", { total_apertura: total, caja: id })
        .catch((err) => {
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        })
        .then((resp) => {
            if (resp) {
                ToastStore.success("Datos almacenados correctamente");
                dispatch(usuarioActions.getMe());
                const tipo_punto_venta = store.usuario.tipo_punto_venta;
                const ruta_punto_venta =
                    tipo_punto_venta == PVMCE
                        ? "/punto_de_venta/caja"
                        : "/punto_de_venta";
                dispatch(push(ruta_punto_venta));
            }
            dispatch(setLoader(false));
        });
};

export const cierre = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const id_caja = store.usuario.me.caja.id;
    const data = store.form.CajaCierre.values
        ? store.form.CajaCierre.values
        : {};
    let total = 0;
    let concepto = "";
    // region Total
    if (data) {
        total = data.b1000 ? total + parseInt(data.b1000) * 1000 : total;
        total = data.b500 ? total + parseInt(data.b500) * 500 : total;
        total = data.b200 ? total + parseInt(data.b200) * 200 : total;
        total = data.b100 ? total + parseInt(data.b100) * 100 : total;
        total = data.b50 ? total + parseInt(data.b50) * 50 : total;
        total = data.b20 ? total + parseInt(data.b20) * 20 : total;
        total = data.b10 ? total + parseInt(data.b10) * 10 : total;
        total = data.b5 ? total + parseInt(data.b5) * 5 : total;
        total = data.b1 ? total + parseInt(data.b1) : total;
        total = data.m2000 ? total + parseInt(data.m2000) * 20 : total;
        total = data.m1000 ? total + parseInt(data.m1000) * 10 : total;
        total = data.m500 ? total + parseInt(data.m500) * 5 : total;
        total = data.m200 ? total + parseInt(data.m200) * 2 : total;
        total = data.m100 ? total + parseInt(data.m100) : total;
        total = data.m50 ? total + parseInt(data.m50) * 0.5 : total;
        total = data.m25 ? total + parseInt(data.m25) * 0.25 : total;
        total = data.m20 ? total + parseInt(data.m20) * 0.2 : total;
        total = data.m10 ? total + parseInt(data.m10) * 0.1 : total;
        total = data.m5 ? total + parseInt(data.m5) * 0.05 : total;
        total = data.m1 ? total + parseInt(data.m1) * 0.01 : total;
        total = total.toFixed(2);
        concepto = data.concepto_cierre ? data.concepto_cierre : "";
    }
    // endregion
    api.post("aperturas_cierres_caja/cierre", {
        total_efectivo_cierre: total,
        concepto_cierre: concepto,
    })
        .catch((err) => {
            console.log(err);
            if (err.detail) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                    "error"
                );
            }
        })
        .then((resp) => {
            if (resp) {
                ToastStore.success("Datos almacenados correctamente");
                dispatch(usuarioActions.getMe());
                dispatch(push(`/cajas/detalle_cierre/${id_caja}`));
            }
            dispatch(setLoader(false));
        });
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};
const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;
    function listener() {
        api.get(`movimientos/estado_descarga`, { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
                console.error("Error details:", err); // Log error details
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Listado_clientes.xlsx";
                    const context = {
                        name: nombre,
                        url: resp.archivo,
                    };
                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }
    listener();
    intervalPromise = setInterval(listener, 1000);
};
export const descargarListado = () => (dispatch, getStore) => {
    const store = getStore();
    const { ordering, search } = store.caja;
    dispatch(setEstadoDescarga(true));
    api.get(`movimientos/descargar_excel`, { ordering, search })
        .catch((err) => {
            Swal(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

export const leer = (id) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    api.get(`${endpoint}/${id}`)
        .catch((err) => {
            Swal("ERROR", "Ha ocurrido un error.", "error");
        })
        .then((resp) => {
            if (resp) {
                dispatch(setCaja(resp));
                dispatch(initializeForm("CajaEditar", resp));
            }
            dispatch(setLoader(false));
        });
};

export const editar = () => (dispatch, getStore) => {
    const store = getStore();
    const data = store.form.CajaEditar.values;
    dispatch(setLoader(true));
    api.put(`${endpoint}/${data.id}`, data)
        .catch((err) => {
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique los datos y vuelva a intentar.",
                "error"
            );
        })
        .then((resp) => {
            if (resp) {
                ToastStore.success("Datos almacenados correctamente");
                dispatch(push("/configuracion/cajas"));
            }
            dispatch(setLoader(false));
        });
};

export const eliminar = (id) => (dispatch) => {
    // Swal({
    //     title: 'Eliminar?',
    //     text: 'No podrá revertir esta acción!',
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Sí, eliminar!',
    //     cancelButtonText: 'No, cancelar',
    //     reverseButtons: true
    // }).then((result) => {
    //     if (result.value) {
    dispatch(setLoader(true));
    api.eliminar(`${endpoint}/${id}`)
        .catch((error) => {
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique que la caja esté cerrada y vuelva a intentar.",
                "error"
            );
        })
        .then((data) => {
            if (data) {
                ToastStore.success("Registro eliminado.");
                dispatch(listarPaginado());
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
    //     }
    // });
};

export const ingresa = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const data = store.form.MovimientoForm.values;
    data.tipo = tipo_movimientos.INGRESO;
    data.monto_efectivo = data.total;
    data.apertura_cierre_caja = store.usuario.me.caja.id;
    api.post("movimientos", data)
        .catch((error) => {
            dispatch(setLoader(false));
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique la información y vuelva a intentar.",
                "error"
            );
        })
        .then((data) => {
            if (data) {
                ToastStore.success("Datos almacenados correctamente");
                dispatch(usuarioActions.getMe());
            }
            dispatch(setLoader(false));
        });
};

export const egresa = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const data = store.form.MovimientoForm.values;
    data.tipo = tipo_movimientos.RETIRO;
    data.monto_efectivo = data.total;
    const monto_actual = store.caja.ultimo_cierre.total_actual
        ? parseFloat(store.caja.ultimo_cierre.total_actual.toFixed(2))
        : 0.0;
    const total = parseFloat(data.total);
    if (monto_actual < parseFloat(total.toFixed(2))) {
        dispatch(setLoader(false));
        Swal(
            "ERROR",
            "No puede hacer un retiro por un monto mayor al efectivo en caja.",
            "error"
        );
        return;
    }
    data.apertura_cierre_caja = store.usuario.me.caja.id;
    api.post("movimientos", data)
        .catch((error) => {
            dispatch(setLoader(false));
            Swal(
                "ERROR",
                "Ha ocurrido un error, verifique la información y vuelva a intentar.",
                "error"
            );
        })
        .then((data) => {
            if (data) {
                ToastStore.success("Datos almacenados correctamente");
                dispatch(usuarioActions.getMe());
            }
            dispatch(setLoader(false));
        });
};

export const get_ultimo_cierre = () => {
    return async (dispatch, getStore) => {
        const store = getStore();
        const id = store.usuario.me.caja.id;
        dispatch(setLoader(true));
        await api
            .get(`aperturas_cierres_caja/${id}`, { excluir_sucursal: true })
            .catch((err) => {})
            .then(async (data) => {
                if (data) {
                    dispatch(setUltimoCierre(data));
                    dispatch(getListaMovimientos(1));
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };
};

export const get_apertura =
    (id, es_impresion = false) =>
    (dispatch) => {
        dispatch(setLoaderPrint(true));
        let params = {};
        if (es_impresion) {
            params.es_impresion = es_impresion;
        }
        api.get(`aperturas_cierres_caja/${id}`, params)
            .catch((err) => {})
            .then((data) => {
                if (data) {
                    dispatch(setApertura(data));
                }
            })
            .catch(() => {
                dispatch(setLoader(false));
            });
    };

// -----------------------------------
// MOVIMIENTOS DE LA CAJA ACTIVA
// -----------------------------------
export const getListaMovimientos =
    (page = 1) =>
    (dispatch, getStore) => {
        dispatch(setLoaderMovimientoCaja(true));
        const store = getStore();
        const id = store.usuario.me.caja.id;
        const data_movimientos_caja = store.caja.data_movimientos_caja;
        const { ordering, search } = data_movimientos_caja;
        const uuid = uuidv1();
        dispatch(setUuidReqListMov(uuid));
        api.get("movimientos/mov_caja_activa", {
            apertura_cierre_caja__id: id,
            page,
            ordering,
            search,
        })
            .catch((err) => {})
            .then((resp) => {
                if (resp) {
                    const otroUuid = getStore().caja.uuid_req_movimientos;
                    if (otroUuid === uuid) {
                        data_movimientos_caja.page = page;
                        data_movimientos_caja.data = resp;
                        dispatch(setDataMovimientoCaja(data_movimientos_caja));
                        dispatch(setLoaderMovimientoCaja(false));
                    }
                }
            });
    };

export const anularVenta = (id, concepto_anulado) => (dispatch, getStore) => {
    const store = getStore();
    const page = store.caja.data_movimientos_caja.page;
    dispatch(setLoaderMovimientoCaja(true));
    api.post("movimientos/anular", { movimiento: id, concepto_anulado })
        .catch((err) => {
            dispatch(push("/caja_activa"));
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal(
                    "ERROR",
                    "No se ha podido anular la venta, intente más tarde.",
                    "error"
                );
            }
        })
        .then((data) => {
            if (data) {
                if (data.id_factura) {
                    Swal(
                        "¡Anulación de factura electrónica en proceso!",
                        "Se esta generando la anulación de la factura, espere por favor",
                        "info"
                    );
                    dispatch(consultarEstadoFEL(data.id_factura));
                } else {
                    ToastStore.success("Venta anulada correctamente.");
                    dispatch(get_ultimo_cierre());
                }
            }
        })
        .finally(() => {
            dispatch(setLoaderMovimientoCaja(false));
        });
};

export const sortChangeMovimientoCaja =
    (sortName, sortOrder) => (dispatch, getStore) => {
        const store = getStore();
        const page = store.caja.data_movimientos_caja.page;
        let data_movimientos_caja = store.caja.data_movimientos_caja;
        if (sortOrder === "asc") {
            data_movimientos_caja.ordering = sortName;
            dispatch(setDataMovimientoCaja(data_movimientos_caja));
        } else {
            data_movimientos_caja.ordering = `-${sortName}`;
            dispatch(setDataMovimientoCaja(data_movimientos_caja));
        }
        dispatch(getListaMovimientos(page));
    };

export const buscarMovimientoCaja = (search) => (dispatch, getStore) => {
    const store = getStore();
    let data_movimientos_caja = store.caja.data_movimientos_caja;
    data_movimientos_caja.search = search;
    dispatch(setDataMovimientoCaja(data_movimientos_caja));
    dispatch(getListaMovimientos());
};

//-------------------------------------
// REIMPRESION DE FACTURA
//_____________________________________

export const seleccionImpresion = (id, seccionImpresion) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setVentaImpresion(null));
    let tipo = "comprobante";
    if (seccionImpresion == "detalleCajaReimpresionReciboCaja") {
        tipo = "recibo_caja";
    }
    api.get(`${endpoint_historial_ventas}/reimpresion_venta`, { id, tipo })
        .catch((err) => {
            dispatch(push("/punto_de_venta"));
            dispatch(setVentaImpresion(null));
            dispatch(setReciboCajaImpresion(null));
        })
        .then((resp) => {
            if (resp) {
                let data_impresion = resp;
                let vuelto = 0;
                if (data_impresion.length === undefined) {
                    vuelto = data_impresion.vuelto_efectivo
                        ? data_impresion.vuelto_efectivo
                        : 0;
                    data_impresion.monto_efectivo =
                        data_impresion.monto_efectivo + vuelto;
                }
                if (seccionImpresion == "detalleCajaReimpresionReciboCaja") {
                    dispatch(setReciboCajaImpresion(data_impresion));
                    dispatch(setVentaImpresion(null));
                    dispatch(printActions.print(seccionImpresion));
                } else {
                    dispatch(setReciboCajaImpresion(null));
                    dispatch(setVentaImpresion(data_impresion));
                    dispatch(printActions.print(seccionImpresion));
                }
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};


export const impresionGeneral = (id, seccionImpresion) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch(setVentaImpresion(null));

    dispatch(printActions.print(seccionImpresion));
    dispatch(setLoader(false));
};

//-------------------------------------

//-------------------------------------
// RETIMBRADO DE FACTURA
//_____________________________________

export const retimbradoFEL = (id_venta) => (dispatch) => {
    dispatch(setLoaderMovimientoCaja(true));

    api.post(`${endpoint_fel}/rieintento_fel`, { id_venta })
        .catch((err) => {
            dispatch(push("/punto_de_venta"));
            if (err) {
                Swal("ERROR", err.detail, "error");
            } else {
                Swal("ERROR", "Error al generar factura electrónica", "error");
            }
        })
        .then((data) => {
            if (data.id_factura) {
                dispatch(consultarEstadoFEL(data.id_factura));
            }
        })
        .finally(() => {
            dispatch(setLoaderMovimientoCaja(false));
        });
};

const consultarEstadoFEL = (id_factura) => (dispatch, getStore) => {
    dispatch(setLoaderMovimientoCaja(true));
    const store = getStore();
    const { page } = store.caja.data_movimientos_caja;

    api.get("factura_electronica/verificar_fel", { id_factura })
        .catch((err) => {
            Swal(
                "ERROR",
                "Error al consultar el estado del proceso FEL",
                "error"
            );
        })
        .then((resp) => {
            status = resp.estado_factura;
            if (status === "proceso") {
                setTimeout(() => {
                    dispatch(consultarEstadoFEL(id_factura));
                }, 1000);
            } else if (status === "facturado") {
                Swal(
                    "Éxito",
                    "Factura electrónica registrada correctamente",
                    "success"
                ).then(() => {
                    setTimeout(() => {
                        dispatch(get_ultimo_cierre());
                    }, 500);
                });
                dispatch(setLoaderMovimientoCaja(false));
            } else if (status === "anulada") {
                Swal(
                    "Éxito",
                    "Venta y factura electrónica anuladas correctamente",
                    "success"
                ).then(() => {
                    setTimeout(() => {
                        dispatch(get_ultimo_cierre());
                    }, 500);
                });
            } else {
                const msj = resp.detail
                    ? `Hubo un error con FEL, ${resp.detail}, intenta más tarde`
                    : "Hubo un error con FEL, intenta más tarde";
                Swal("ERROR", msj, "error");
                dispatch(get_ultimo_cierre());
                dispatch(setLoaderMovimientoCaja(false));
            }
        });
};

//-------------------------------------

// ------------------------------------
// PureActions
// ------------------------------------
export const setVentaImpresion = (venta_impresion) => ({
    type: SET_IMPRESION,
    venta_impresion,
});
export const setUltimoCierre = (ultimo_cierre) => ({
    type: ULTIMO_CIERRE,
    ultimo_cierre,
});
export const setCajas = (data) => ({
    type: CAJAS,
    data,
});
export const setDataCajas = (data_cajas) => ({
    type: DATA_CAJAS,
    data_cajas,
});
export const setPage = (page) => ({
    type: PAGE,
    page,
});
export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});
export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});
export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});
export const setLoaderPrint = (loader_print) => ({
    type: LOADER_PRINT,
    loader_print,
});
export const setCaja = (caja) => ({
    type: CAJA,
    caja,
});
export const setApertura = (apertura) => ({
    type: APERTURA,
    apertura,
});
export const setId = (id) => ({
    type: CAJA_ID,
    id,
});

const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const setDataMovimientoCaja = (data_movimientos_caja) => ({
    type: DATA_MOVIMIENTO_CAJA,
    data_movimientos_caja,
});

export const setLoaderMovimientoCaja = (loader_movimiento_caja) => ({
    type: LOADER_MOVIMIENTO_CAJA,
    loader_movimiento_caja,
});

export const setUuidReqListMov = (uuid_req_movimientos) => ({
    type: UUID_REQ_LIST_MOVIMIENTOS,
    uuid_req_movimientos,
});

export const setReciboCajaImpresion = (recibo_caja_impresion) => ({
    type: SET_RECIBO_CAJA_IMPRESION,
    recibo_caja_impresion,
});

export const actions = {
    listar,
    descargarListado,
    listarPaginado,
    buscar,
    sortChange,
    eliminar,
    crear,
    editar,
    leer,
    aperturar,
    cierre,
    ingresa,
    egresa,
    get_ultimo_cierre,
    get_apertura,
    setUltimoCierre,
    setId,
    getListaMovimientos,
    anularVenta,
    sortChangeMovimientoCaja,
    buscarMovimientoCaja,
    seleccionImpresion,
    impresionGeneral,
    retimbradoFEL,
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [CAJAS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_CAJAS]: (state, { data_cajas }) => {
        return {
            ...state,
            data_cajas,
        };
    },
    [CAJA]: (state, { caja }) => {
        return {
            ...state,
            caja,
        };
    },
    /* Se coloca el loader_print:false para que al terminar
    de cargar la data se muestre correctamente en get_apertura */
    [APERTURA]: (state, { apertura }) => {
        return {
            ...state,
            apertura,
            loader_print: false,
        };
    },
    [ULTIMO_CIERRE]: (state, { ultimo_cierre }) => {
        return {
            ...state,
            ultimo_cierre,
        };
    },
    [CAJA_ID]: (state, { id }) => {
        return {
            ...state,
            id,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [LOADER_PRINT]: (state, { loader_print }) => {
        return {
            ...state,
            loader_print,
        };
    },
    [LOADER_MOVIMIENTO_CAJA]: (state, { loader_movimiento_caja }) => {
        return {
            ...state,
            loader_movimiento_caja,
        };
    },
    [DATA_MOVIMIENTO_CAJA]: (state, { data_movimientos_caja }) => {
        return {
            ...state,
            data_movimientos_caja,
        };
    },
    [SET_IMPRESION]: (state, { venta_impresion }) => {
        return {
            ...state,
            venta_impresion,
        };
    },
    [UUID_REQ_LIST_MOVIMIENTOS]: (state, { uuid_req_movimientos }) => {
        return {
            ...state,
            uuid_req_movimientos,
        };
    },
    [SET_RECIBO_CAJA_IMPRESION]: (state, { recibo_caja_impresion }) => {
        return {
            ...state,
            recibo_caja_impresion,
        };
    },
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    id: 0,
    data: [],
    data_cajas: {},
    caja: {},
    apertura: {},
    ultimo_cierre: {},
    page: 1,
    search: "",
    ordering: "",
    loader: false,
    loader_print: false,
    data_movimientos_caja: {
        page: 1,
        data: {},
        search: "",
        ordering: "-creado",
    },
    loader_movimiento_caja: false,
    venta_impresion: null,
    recibo_caja_impresion: null,
    uuid_req_movimientos: "",
};

export default handleActions(reducers, initialState);

import React, { Component } from "react";
import { icons } from "icons";
import PropTypes from "prop-types";
import { PVN } from "utility/variables";
import { ToastStore } from "react-toasts";
import Modal from "react-responsive-modal";
import LoadMask from "Utils/LoadMask/LoadMask";
import ClienteCotizacionForm from "./clienteForm";
import { ListadoCards } from "Utils/ListadoCards";
import { ListadoAccion } from "Utils/ListadoAccion";
import TicketCotizacion from "Utils/TicketCotizacion";
import { DetalleVenta } from "Utils/Venta/DetalleVenta";
import { DetalleVentaCotizacion } from "Utils/Venta/DetalleVentaCotizacion";

import ExistenciaSucursal from "../../Ventas/Venta/ExistenciaSucursal";
import { SeleccionSucursal } from "Utils/SeleccionSucursal";
import { RenderCurrency } from "Utils/renderField/renderReadField";
import { PrintContainer, PrintComponent } from "Utils/Print";
import {
    DropDownMenu,
    DropDownDirection,
    ActionItem,
} from "react-dropdown-advanced";
import {
    formatoNombre,
    buscar,
    formatoAvatar,
    formatoMarca,
} from "Utils/CamposComunes";

import {
    formatoAcciones,
    formatoPrecio,
    formatoExistencias,
} from "./CamposMostrador";
import Tooltip from "rc-tooltip";

export default class Mostrador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_existencia: false,
            modal_cliente: false,
            modal_producto: false,
            es_cotizacion: false,
            dropdownItems: [
                new ActionItem("recibir_devolucion", "Recibir devolución"),
                new ActionItem(
                    "buscar_productos_sucursales",
                    "Buscar producto en otra sucursal"
                ),
            ],
            producto_detalle: null,
        };
    }

    static propTypes = {};

    componentWillMount() {
        this.props.setNavbarCollapse(true);
        this.props.listarProductos(this.props.page_productos);
        this.props.getPrimeraCaja();
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.utiliza_fel !== nextProps.utiliza_fel) {
            if (nextProps.utiliza_fel) {
                this.setState({
                    dropdownItems: [
                        new ActionItem(
                            "buscar_productos_sucursales",
                            "Buscar producto en otra sucursal"
                        ),
                    ],
                });
            }
        }
    }
    closeModalExistencias = () => {
        this.setState({ modal_existencia: false });
    };

    openModalExistencias = () => {
        this.setState({ modal_existencia: true });
    };

    openModalCliente = (es_cotizacion) => {
        if (this.props.seleccionados.length) {
            this.setState({ modal_cliente: true });
            if (es_cotizacion) {
                this.setState({ es_cotizacion: es_cotizacion });
            }
        } else {
            if (!es_cotizacion)
                ToastStore.error("La venta no tiene productos seleccionados.");
            else
                ToastStore.error(
                    "La cotización no tiene productos seleccionados."
                );
        }
    };

    openModalProducto = (producto) => {
        console.log("Producto recibido:", producto); // Debugging line
        this.setState(
            { modal_producto: true, producto_detalle: producto },
            () => {
                console.log("Estado actualizado:", this.state.producto_detalle); // Debugging line
            }
        );
    };

    closeModalProducto = () => {
        this.setState({ modal_producto: false, producto_detalle: null });
    };

    onClickOtrasAcciones = (item) => {
        // bind
        const { pushRouter } = this.props;

        if (item.key === "recibir_devolucion") {
            pushRouter("/devolucion");
        } else if (item.key === "buscar_productos_sucursales") {
            this.openModalExistencias();
        }
    };

    closeModalCliente = (data) => {
        if (data && data.tipo_documento && data.tipo_documento == "CF") {
            data.nit = "CF";
        }
        if (
            data.nit != "" &&
            data.nit != undefined &&
            data.nombre != "" &&
            data.nombre != undefined &&
            data.tipo_documento != "" &&
            data.tipo_documento != undefined
        ) {
            if (!this.state.es_cotizacion) {
                this.props.enviarACaja(data);
            } else {
                if (this.props.cotizacion_cargada != null) {
                    this.props.actualizarCotizacion(data);
                } else {
                    this.props.enviarCotizacion(data);
                }
            }
        }
        this.setState({ modal_cliente: false });
        this.setState({ es_cotizacion: false });
    };

    isMobile() {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
        )
            return true;
        return false;
    }

    render() {
        // state
        let productos = {};
        const {
            sucursal,
            tipo_punto_venta,
            loader_productos,
            data_productos,
            page_productos,
            search_productos,
            ver_buscador,
            ver_cobro,
            loader,
            loader_cliente,
            conf_cotizacion,
            caja,
            ver_impresion,
            venta_cotizacion,
            print_state,
            conf_venta_precio_editable,
        } = this.props;
        // state venta
        const {
            seleccionados,
            venta_permitir_descuento,
            pais,
            total,
            cotizacion_cargada,
        } = this.props;
        const { dropdownItems } = this.state;

        // bind
        const {
            listarProductos,
            sortChangeProductos,
            buscarProductos,
            pushRouter,
            agregarPrimer,
            cambioBuscador,
            // leerCliente,
            leerCliente2,
            changeDatosClientes,
            limpiarDetalles,
            finalizarCotizacion,
            conf_porcentaje_precio,
        } = this.props;
        // bind Detalle venta
        const {
            desplegarItem,
            agregar,
            quitar,
            sumar,
            restar,
            ingresoCantidad,
            cambiarDescuento,
            cambiarPrecio,
            cambiarPrecioFinal,
            cambiarPrecioEditable,
        } = this.props;

        const llavesListado = [
            {
                nombre: "img_cropped",
                sort: false,
                caption: "",
                format: formatoAvatar,
                align: "center",
                width: "80px",
            },
            {
                nombre: "producto__nombre",
                sort: true,
                caption: "Nombre",
                format: formatoNombre,
                align: "left",
            },
            {
                nombre: "producto__marca",
                sort: true,
                caption: "Marca",
                format: formatoMarca,
                align: "left",
                width: "15%",
            },
            {
                nombre: "precio",
                sort: false,
                caption: "Precio",
                format: formatoPrecio,
                align: "right",
                width: "15%",
            },
            {
                nombre: "inventario_calculado",
                sort: false,
                caption: "Existencias",
                format: formatoExistencias,
                align: "right",
                width: "13%",
            },
            {
                nombre: "acciones",
                sort: false,
                caption: "Acciones",
                format: formatoAcciones({
                    agregar,
                    openModalProducto: this.openModalProducto,
                    cambioBuscador,
                    conf_cotizacion,
                }),
                align: "center",
                width: "15%",
            },
        ];

        // Se inicializa los datos del cliente
        var datos_cliente_cotizacion = this.state.es_cotizacion
            ? { nit: "" }
            : { nit: "", caja: caja ? caja.id : null };

        // Se cargan los datos del cliente que se registro en la cotizacion
        if (cotizacion_cargada) {
            datos_cliente_cotizacion.nombre = cotizacion_cargada.nombre;
            datos_cliente_cotizacion.nit = cotizacion_cargada.nit;
            datos_cliente_cotizacion.direccion =
                cotizacion_cargada.tipo_documento || "";
            datos_cliente_cotizacion.correo = cotizacion_cargada.correo;
        }

        // VALIDACION DE TIPO DE PUNTO DE VENTA
        if (tipo_punto_venta == PVN) {
            return (
                <div>
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="padding-15">
                                <h1 className="m-0 text-center">
                                    No tienes acceso a esta vista.
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        /* region SELECCION DE SUCURSAL */
        if (sucursal === undefined) {
            return (
                <div className="row">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="grid-titulo padding-5">
                                Seleccione una sucursal
                            </div>
                            <div className="padding-15">
                                <SeleccionSucursal />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        /* endregion SELECCION DE SUCURSAL */

        productos = data_productos;
        return (
            <div className="form-group mt d-flex">
                {!ver_impresion ? (
                    <div
                        className="slide-container row w-100"
                        style={{ margin: "0" }}
                    >
                        <div
                            className={`d-block d-lg-none col-sm-12 ${
                                ver_buscador ? "form-activo" : ""
                            } ${!ver_cobro ? "slide" : ""}`}
                        >
                            {ver_buscador && (
                                <ListadoCards
                                    buscar={buscar({
                                        search: search_productos,
                                        buscar: buscarProductos,
                                        agregarPrimer: agregarPrimer,
                                    })}
                                    loader={loader_productos}
                                    llaves={llavesListado}
                                    data={data_productos}
                                    listar={listarProductos}
                                    sortChange={sortChangeProductos}
                                    titulo={"Listado de Productos"}
                                    page={page_productos}
                                    acciones={dropdownItems}
                                    onClickOtrasAcciones={
                                        this.onClickOtrasAcciones
                                    }
                                    pushRouter={pushRouter}
                                    agregar={agregar}
                                    cambioBuscador={cambioBuscador}
                                />
                            )}
                        </div>

                        <div
                            className={`d-none d-lg-block col-md-12 col-lg-7  ${
                                ver_buscador ? "form-activo" : ""
                            } ${!ver_cobro ? "slide" : ""}`}
                        >
                            <ListadoAccion
                                buscar={buscar({
                                    search: search_productos,
                                    buscar: buscarProductos,
                                    agregarPrimer: agregarPrimer,
                                })}
                                loader={loader_productos}
                                llaves={llavesListado}
                                data={data_productos}
                                listar={listarProductos}
                                sortChange={sortChangeProductos}
                                titulo={"Listado de Productos"}
                                page={page_productos}
                                acciones={dropdownItems}
                                onClickOtrasAcciones={this.onClickOtrasAcciones}
                                pushRouter={pushRouter}
                            />

                            {/* CAMBIO MOVIL DE VISTAS */}
                            {/* FIN CAMBIO MOVIL DE VISTAS*/}
                        </div>
                        <div
                            className={`col-md-12 col-lg-5 ${
                                !ver_buscador ? "form-activo" : ""
                            } ${!ver_cobro ? "form-trasero" : "venta-mt-4"}`}
                        >
                            <LoadMask loading={loader} light blur radius>
                                <div className="w-100">
                                    {cotizacion_cargada ? (
                                        <DetalleVentaCotizacion
                                            mostrar_alerta_inventario={true}
                                            seleccionados={seleccionados}
                                            cotizacion_cargada={
                                                cotizacion_cargada
                                            }
                                            desplegarItem={desplegarItem}
                                            quitar={quitar}
                                            sumar={sumar}
                                            restar={restar}
                                            ingresoCantidad={ingresoCantidad}
                                            venta_permitir_descuento={
                                                venta_permitir_descuento
                                            }
                                            pais={pais}
                                            cambiarDescuento={cambiarDescuento}
                                            cambiarPrecio={cambiarPrecio}
                                            cambiarPrecioFinal={
                                                cambiarPrecioFinal
                                            }
                                            conf_porcentaje_precio={
                                                conf_porcentaje_precio
                                            }
                                        />
                                    ) : (
                                        <DetalleVenta
                                            mostrar_alerta_inventario={true}
                                            seleccionados={seleccionados}
                                            desplegarItem={desplegarItem}
                                            quitar={quitar}
                                            sumar={sumar}
                                            restar={restar}
                                            ingresoCantidad={ingresoCantidad}
                                            venta_permitir_descuento={
                                                venta_permitir_descuento
                                            }
                                            pais={pais}
                                            conf_venta_precio_editable={
                                                conf_venta_precio_editable
                                            }
                                            cambiarDescuento={cambiarDescuento}
                                            cambiarPrecio={cambiarPrecio}
                                            cambiarPrecioFinal={
                                                cambiarPrecioFinal
                                            }
                                            cambiarPrecioEditable={
                                                cambiarPrecioEditable
                                            }
                                            editable={true}
                                            conf_porcentaje_precio={
                                                conf_porcentaje_precio
                                            }
                                        />
                                    )}

                                    {/* SECCION TOTAL Y ACCIONES */}
                                    <div
                                        className={`align-items-center mt0 padding-5 d-flex justify-content-around border-radius-top-squa border-radius-buttom-squa flex-column  ${
                                            !this.isMobile() ? "bg-blanco" : ""
                                        } ${
                                            this.isMobile() &&
                                            seleccionados.length <= 0
                                                ? "grid-container-mob"
                                                : "bg-blanco"
                                        }`}
                                    >
                                        {!ver_cobro && (
                                            <div className="padding-10 p-lg-0 d-flex flex-column display-flex-width-100 justify-content-between d-lg-none d-xl-none">
                                                <div
                                                    className={`${
                                                        this.isMobile() &&
                                                        seleccionados.length > 0
                                                            ? "buttons-venta"
                                                            : ""
                                                    }`}
                                                >
                                                    <div
                                                        className={`text-center flex1 mb-2 ${
                                                            this.isMobile &&
                                                            seleccionados.length >
                                                                0
                                                                ? "mr-1"
                                                                : ""
                                                        }`}
                                                    >
                                                        <button
                                                            className={`${
                                                                this.isMobile &&
                                                                seleccionados.length <=
                                                                    0
                                                                    ? "btn btn-secondary btn-lg btn-block"
                                                                    : "btn btn-secondary btn-lg btn-block mb-1 fnt-sm-10"
                                                            }`}
                                                            type="button"
                                                            onClick={() => {
                                                                cambioBuscador();
                                                            }}
                                                        >
                                                            <em className="fa fa-plus fa-lg mr"></em>
                                                            Agregar Producto
                                                        </button>
                                                    </div>
                                                    <br></br>
                                                    <div
                                                        className={`text-center flex1 mb-2 ${
                                                            this.isMobile &&
                                                            seleccionados.length >
                                                                0
                                                                ? ""
                                                                : ""
                                                        }`}
                                                    >
                                                        <button
                                                            className={`${
                                                                this.isMobile &&
                                                                seleccionados.length <=
                                                                    0
                                                                    ? "btn btn-outline-tertiary btn-lg btn-block"
                                                                    : "btn btn-outline-tertiary btn-lg btn-block mb-1 fnt-sm-10"
                                                            }`}
                                                            style={{
                                                                position:
                                                                    "relative",
                                                            }}
                                                        >
                                                            <span className="mr">
                                                                Otras acciones
                                                            </span>
                                                            <em className="ml-2 fa fa-caret-down"></em>
                                                            {dropdownItems.length >
                                                                0 && (
                                                                <DropDownMenu
                                                                    items={
                                                                        dropdownItems
                                                                    }
                                                                    onClick={
                                                                        this
                                                                            .onClickOtrasAcciones
                                                                    }
                                                                    direction={
                                                                        DropDownDirection.UpLeft
                                                                    }
                                                                    className="fnt-10"
                                                                />
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {this.isMobile() &&
                                            seleccionados.length > 0 && (
                                                <div
                                                    className={`w-100 d-flex ${
                                                        conf_cotizacion
                                                            ? "flex-column"
                                                            : "flex-lg-row flex-column "
                                                    }`}
                                                >
                                                    {conf_cotizacion ? (
                                                        <div className="flex-row d-flex w-100 padding-bottom-10 mt-2">
                                                            <h3 className="flex1 ml">
                                                                <b>Total</b>
                                                            </h3>
                                                            <h4 className="flex1 content-total mr-2 text-right">
                                                                <b>
                                                                    <RenderCurrency
                                                                        value={
                                                                            total
                                                                        }
                                                                    />
                                                                </b>
                                                            </h4>
                                                        </div>
                                                    ) : (
                                                        <div className="flex-row d-flex d-lg-none w-100 padding-bottom-10 mt-2">
                                                            <h3 className="flex1 ml">
                                                                <b>Total</b>
                                                            </h3>
                                                            <h4 className="flex1 content-total mr-2 text-right">
                                                                <b>
                                                                    <RenderCurrency
                                                                        value={
                                                                            total
                                                                        }
                                                                    />
                                                                </b>
                                                            </h4>
                                                        </div>
                                                    )}

                                                    {/* BOTONES */}
                                                    <div className="venta-btns mt0 container-botones-venta d-flex align-items-center justify-content-center">
                                                        <div className="flex1 d-flex flex-row align-items-center justify-content-center">
                                                            <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                <button
                                                                    style={
                                                                        !conf_cotizacion
                                                                            ? {
                                                                                  minHeight:
                                                                                      "67px",
                                                                                  flex: 1,
                                                                              }
                                                                            : {
                                                                                  minHeight:
                                                                                      "67px",
                                                                                  width: "72px",
                                                                              }
                                                                    }
                                                                    className="rounded white-space-normal btn btn-primary d-flex flex-column align-items-center justify-content-center"
                                                                    type="submit"
                                                                    onClick={() => {
                                                                        this.openModalCliente();
                                                                    }}
                                                                >
                                                                    <img
                                                                        className="m-0 mb-1"
                                                                        src={
                                                                            icons.punto_venta_caja
                                                                        }
                                                                    />
                                                                    Enviar a
                                                                    caja
                                                                </button>
                                                            </div>
                                                            {conf_cotizacion && (
                                                                <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                    <button
                                                                        style={{
                                                                            width: "72px",
                                                                        }}
                                                                        className="rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center"
                                                                        type="submit"
                                                                        onClick={() => {
                                                                            this.openModalCliente(
                                                                                true
                                                                            );
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className="m-0 mb-1"
                                                                            src={
                                                                                icons.guardar
                                                                            }
                                                                        />
                                                                        {cotizacion_cargada !=
                                                                        null ? (
                                                                            <span>
                                                                                {" "}
                                                                                Actualizar
                                                                                Cotización{" "}
                                                                            </span>
                                                                        ) : (
                                                                            <span>
                                                                                {" "}
                                                                                Guardar
                                                                                Cotización{" "}
                                                                            </span>
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            )}
                                                            {conf_cotizacion && (
                                                                <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                    <button
                                                                        style={{
                                                                            width: "72px",
                                                                        }}
                                                                        className="rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center"
                                                                        type="submit"
                                                                        onClick={() => {
                                                                            pushRouter(
                                                                                "/cotizaciones"
                                                                            );
                                                                        }}
                                                                    >
                                                                        <img
                                                                            className="m-0 mb-1"
                                                                            src={
                                                                                icons.abrir
                                                                            }
                                                                        />
                                                                        Abrir
                                                                        cotización
                                                                    </button>
                                                                </div>
                                                            )}
                                                            {/* {conf_cotizacion && ( */}
                                                            <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                <button
                                                                    style={{
                                                                        minHeight:
                                                                            "67px",
                                                                        width: "72px",
                                                                    }}
                                                                    className={`rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center ${
                                                                        !conf_cotizacion &&
                                                                        "flex-1"
                                                                    }`}
                                                                    type="submit"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        limpiarDetalles();
                                                                    }}
                                                                >
                                                                    <img
                                                                        className="m-0 mb-1"
                                                                        src={
                                                                            icons.anular_cotizacion
                                                                        }
                                                                    />
                                                                    Cancelar
                                                                </button>
                                                            </div>
                                                            {/* )} */}
                                                        </div>
                                                    </div>

                                                    {!conf_cotizacion && (
                                                        <div className="flex-column d-none d-lg-flex w-100 padding-bottom-10 mt-2 pr-1">
                                                            <h3 className="flex1 mr-2 text-right">
                                                                <b>Total</b>
                                                            </h3>
                                                            <h5 className="flex1 content-total mr-2 text-right">
                                                                <b>
                                                                    <RenderCurrency
                                                                        value={
                                                                            total
                                                                        }
                                                                    />
                                                                </b>
                                                            </h5>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                        {!this.isMobile() && 1 == 1 && (
                                            <div
                                                className={`w-100 d-flex ${
                                                    conf_cotizacion
                                                        ? "flex-column"
                                                        : "flex-lg-row flex-column "
                                                }`}
                                            >
                                                {conf_cotizacion ? (
                                                    <div className="flex-row d-flex w-100 padding-bottom-10 mt-2">
                                                        <h3 className="flex1 ml">
                                                            <b>Total</b>
                                                        </h3>
                                                        <h4 className="flex1 content-total mr-2 text-right">
                                                            {total >= 2500 &&
                                                                this.props
                                                                    .utiliza_fel && (
                                                                    <React.Fragment>
                                                                        <Tooltip
                                                                            placement="bottom"
                                                                            overlay={
                                                                                <div>
                                                                                    <strong>
                                                                                        En
                                                                                        ventas
                                                                                        mayores
                                                                                        a{" "}
                                                                                        <RenderCurrency
                                                                                            value={
                                                                                                2500
                                                                                            }
                                                                                        />
                                                                                        <br />
                                                                                        no
                                                                                        se
                                                                                        pueden
                                                                                        facturar
                                                                                        con
                                                                                        CF
                                                                                    </strong>
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <em className="text-danger fa fa-exclamation-triangle opacityPulse-css" />
                                                                        </Tooltip>
                                                                        &nbsp;
                                                                        &nbsp;
                                                                    </React.Fragment>
                                                                )}
                                                            <b>
                                                                <RenderCurrency
                                                                    value={
                                                                        total
                                                                    }
                                                                />
                                                            </b>
                                                        </h4>
                                                    </div>
                                                ) : (
                                                    <div className="flex-row d-flex d-lg-none w-100 padding-bottom-10 mt-2">
                                                        <h3 className="flex1 ml">
                                                            <b>Total</b>
                                                        </h3>
                                                        <h4 className="flex1 content-total mr-2 text-right">
                                                            <b>
                                                                <RenderCurrency
                                                                    value={
                                                                        total
                                                                    }
                                                                />
                                                            </b>
                                                        </h4>
                                                    </div>
                                                )}
                                                {/* BOTONES */}
                                                <div className="venta-btns mt0 container-botones-venta d-flex align-items-center justify-content-center">
                                                    <div className="flex1 d-flex flex-row align-items-center justify-content-center">
                                                        <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                            <button
                                                                style={
                                                                    !conf_cotizacion
                                                                        ? {
                                                                              minHeight:
                                                                                  "67px",
                                                                              flex: 1,
                                                                          }
                                                                        : {
                                                                              minHeight:
                                                                                  "67px",
                                                                              width: "72px",
                                                                          }
                                                                }
                                                                className="rounded white-space-normal btn btn-primary d-flex flex-column align-items-center justify-content-center"
                                                                type="submit"
                                                                onClick={() => {
                                                                    this.openModalCliente();
                                                                }}
                                                            >
                                                                <img
                                                                    className="m-0 mb-1"
                                                                    src={
                                                                        icons.punto_venta_caja
                                                                    }
                                                                />
                                                                Enviar a caja
                                                            </button>
                                                        </div>
                                                        {conf_cotizacion && (
                                                            <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                <button
                                                                    style={{
                                                                        width: "72px",
                                                                    }}
                                                                    className="rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center"
                                                                    type="submit"
                                                                    onClick={() => {
                                                                        this.openModalCliente(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        className="m-0 mb-1"
                                                                        src={
                                                                            icons.guardar
                                                                        }
                                                                    />
                                                                    {cotizacion_cargada !=
                                                                    null ? (
                                                                        <span>
                                                                            {" "}
                                                                            Actualizar
                                                                            Cotización{" "}
                                                                        </span>
                                                                    ) : (
                                                                        <span>
                                                                            {" "}
                                                                            Guardar
                                                                            Cotización{" "}
                                                                        </span>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        )}
                                                        {conf_cotizacion && (
                                                            <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                                <button
                                                                    style={{
                                                                        width: "72px",
                                                                    }}
                                                                    className="rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center"
                                                                    type="submit"
                                                                    onClick={() => {
                                                                        pushRouter(
                                                                            "/cotizaciones"
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        className="m-0 mb-1"
                                                                        src={
                                                                            icons.abrir
                                                                        }
                                                                    />
                                                                    Abrir
                                                                    cotización
                                                                </button>
                                                            </div>
                                                        )}
                                                        {/* {conf_cotizacion && ( */}
                                                        <div className="d-flex m-1 flex1 align-items-center justify-content-center">
                                                            <button
                                                                style={{
                                                                    minHeight:
                                                                        "67px",
                                                                    width: "72px",
                                                                }}
                                                                className={`rounded white-space-normal btn btn-outline-secondary d-flex flex-column align-items-center justify-content-center ${
                                                                    !conf_cotizacion &&
                                                                    "flex-1"
                                                                }`}
                                                                type="submit"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    limpiarDetalles();
                                                                }}
                                                            >
                                                                <img
                                                                    className="m-0 mb-1"
                                                                    src={
                                                                        icons.anular_cotizacion
                                                                    }
                                                                />
                                                                Cancelar
                                                            </button>
                                                        </div>
                                                        {/* )} */}
                                                    </div>
                                                </div>

                                                {!conf_cotizacion && (
                                                    <div className="flex-column d-none d-lg-flex w-100 padding-bottom-10 mt-2 pr-1">
                                                        <h3 className="flex1 mr-2 text-right">
                                                            <b>Total</b>
                                                        </h3>
                                                        <h5 className="flex1 content-total mr-2 text-right">
                                                            {total >= 2500 &&
                                                                this.props
                                                                    .utiliza_fel && (
                                                                    <React.Fragment>
                                                                        <Tooltip
                                                                            placement="bottom"
                                                                            overlay={
                                                                                <div>
                                                                                    <strong>
                                                                                        En
                                                                                        ventas
                                                                                        mayores
                                                                                        a{" "}
                                                                                        <RenderCurrency
                                                                                            value={
                                                                                                2500
                                                                                            }
                                                                                        />
                                                                                        <br />
                                                                                        no
                                                                                        se
                                                                                        pueden
                                                                                        facturar
                                                                                        con
                                                                                        CF
                                                                                    </strong>
                                                                                </div>
                                                                            }
                                                                        >
                                                                            <em className="text-danger fa fa-exclamation-triangle opacityPulse-css" />
                                                                        </Tooltip>
                                                                        &nbsp;
                                                                        &nbsp;
                                                                    </React.Fragment>
                                                                )}
                                                            <b>
                                                                <RenderCurrency
                                                                    value={
                                                                        total
                                                                    }
                                                                />
                                                            </b>
                                                        </h5>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        {/* MODAL DE PRODUCTO */}
                                        <Modal
                                            showCloseIcon={false}
                                            open={this.state.modal_producto}
                                            onClose={this.closeModalProducto}
                                            classNames={{ modal: "modal-sm" }}
                                        >
                                            <div
                                                className="container-fluid"
                                                style={{
                                                    padding: "1.2rem",
                                                    maxWidth: "700px",
                                                }}
                                            >
                                                {this.state
                                                    .producto_detalle && (
                                                    <div className="row">
                                                        <div className="col-12 grid-titulo">
                                                            Detalle Producto
                                                        </div>
                                                        <div className="d-flex flex-column flex-sm-row" style={{width: "100%"}}>
                                                            <div className="col-sm-6 content-drop-zone">
                                                                <img
                                                                    src={
                                                                        this
                                                                            .state
                                                                            .producto_detalle
                                                                            .producto
                                                                            .imagen
                                                                    }
                                                                    width="200"
                                                                    height="200"
                                                                />
                                                            </div>
                                                            <div className="p-2 col-sm-6 col-12">
                                                                <div className="d-flex">
                                                                    <h4 className="">
                                                                        {
                                                                            this
                                                                                .state
                                                                                .producto_detalle
                                                                                .producto
                                                                                .nombre
                                                                        }
                                                                    </h4>
                                                                </div>
                                                                <p className="m-1">
                                                                    <b>
                                                                        Descripcion:{" "}
                                                                    </b>
                                                                    {this.state
                                                                        .producto_detalle &&
                                                                        this
                                                                            .state
                                                                            .producto_detalle
                                                                            .producto
                                                                            .descripcion}
                                                                </p>
                                                                <hr className="m-1"></hr>

                                                                {this.state
                                                                    .producto_detalle
                                                                    .producto
                                                                    .kilataje && (
                                                                    <React.Fragment>
                                                                        <p className="m-1">
                                                                            <b>
                                                                                Kilataje:{" "}
                                                                            </b>
                                                                            {this
                                                                                .state
                                                                                .producto_detalle
                                                                                .producto &&
                                                                                this
                                                                                    .state
                                                                                    .producto_detalle
                                                                                    .producto
                                                                                    .kilataje}
                                                                        </p>
                                                                        <hr className="m-1"></hr>
                                                                    </React.Fragment>
                                                                )}

                                                                {this.state
                                                                    .producto_detalle
                                                                    .producto
                                                                    .peso && (
                                                                    <React.Fragment>
                                                                        <p className="m-1">
                                                                            <b>
                                                                                Peso:{" "}
                                                                            </b>
                                                                            {this
                                                                                .state
                                                                                .producto_detalle
                                                                                .producto &&
                                                                                this
                                                                                    .state
                                                                                    .producto_detalle
                                                                                    .producto
                                                                                    .peso}
                                                                        </p>
                                                                        <hr className="m-1"></hr>
                                                                    </React.Fragment>
                                                                )}

                                                                <p className="m-1">
                                                                    <b>
                                                                        Familia:{" "}
                                                                    </b>
                                                                    {this.state
                                                                        .producto_detalle
                                                                        .producto
                                                                        .familia &&
                                                                        this
                                                                            .state
                                                                            .producto_detalle
                                                                            .producto
                                                                            .familia
                                                                            .nombre}
                                                                </p>
                                                                <hr className="m-1"></hr>

                                                                <p className="m-1">
                                                                    <b>
                                                                        Marca:{" "}
                                                                    </b>
                                                                    {this.state
                                                                        .producto_detalle &&
                                                                        this
                                                                            .state
                                                                            .producto_detalle
                                                                            .producto
                                                                            .marca}
                                                                </p>
                                                                <hr className="m-1"></hr>

                                                                <p className="m-1">
                                                                    <b>
                                                                        Precio:{" "}
                                                                    </b>
                                                                    <RenderCurrency
                                                                        value={
                                                                            this
                                                                                .state
                                                                                .producto_detalle
                                                                                .precio
                                                                        }
                                                                    />
                                                                </p>
                                                                <hr className="m-1"></hr>

                                                                <p className="m-1">
                                                                    <b>
                                                                        Existencias:{" "}
                                                                    </b>
                                                                    {this.state
                                                                        .producto_detalle &&
                                                                        this
                                                                            .state
                                                                            .producto_detalle
                                                                            .inventario_calculado}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </Modal>

                                        {/* MODAL DE CLIENTE */}
                                        <Modal
                                            showCloseIcon={false}
                                            open={this.state.modal_cliente}
                                            onClose={this.closeModalCliente}
                                            classNames={{ modal: "modal-sm" }}
                                        >
                                            <div
                                                style={{
                                                    padding: "1.2rem",
                                                    maxWidth: "400px",
                                                }}
                                            >
                                                <div className="row">
                                                    <div className="col-12 grid-titulo">
                                                        Datos cliente
                                                    </div>
                                                    <ClienteCotizacionForm
                                                        {...this.props}
                                                        loader={loader}
                                                        loader_cliente={
                                                            loader_cliente
                                                        }
                                                        onSubmit={
                                                            this
                                                                .closeModalCliente
                                                        }
                                                        close={
                                                            this
                                                                .closeModalCliente
                                                        }
                                                        leerCliente={
                                                            leerCliente2
                                                        }
                                                        changeDatosClientes={
                                                            changeDatosClientes
                                                        }
                                                        es_cotizacion={
                                                            this.state
                                                                .es_cotizacion
                                                        }
                                                        initialValues={
                                                            datos_cliente_cotizacion
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Modal>

                                        {/* region Modal Existencia por sucursal */}
                                        <Modal
                                            showCloseIcon={false}
                                            open={this.state.modal_existencia}
                                            onClose={this.closeModalExistencias}
                                            classNames={{
                                                modal: "modal-bienvenida",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    padding: "0  0 1.2rem 0",
                                                }}
                                            >
                                                <div className="row">
                                                    <div className="w-100 d-flex align-items-center justify-content-end">
                                                        <button
                                                            className="btn btn-cerrar-modal"
                                                            style={{
                                                                borderTopLeftRadius:
                                                                    "0",
                                                                borderTopRightRadius:
                                                                    "10px",
                                                                borderBottomRightRadius:
                                                                    "0",
                                                                borderBottomLeftRadius:
                                                                    "10px",
                                                                fontSize: "1em",
                                                            }}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                this.closeModalExistencias();
                                                            }}
                                                        >
                                                            <em className="fa fa-times"></em>
                                                        </button>
                                                    </div>
                                                    <ExistenciaSucursal
                                                        search_venta={
                                                            search_productos
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Modal>
                                    </div>
                                    {/* FIN SECCION TOTAL Y ACCIONES */}
                                </div>
                            </LoadMask>
                        </div>
                    </div>
                ) : (
                    <div className="slide-container row w-100 d-flex justify-content-center">
                        {print_state.flag && <PrintComponent />}
                        <div className="col-12 col-lg-8 container-vista-previa">
                            <div
                                style={{ backgroundColor: "transparent" }}
                                className="w-100 p-2 container-vista-previa-head d-flex flex-column"
                            >
                                <div className="w-100 text-center">
                                    <h5>Vista previa cotización</h5>
                                    <div className="w-100 pl-4 pr-4 d-flex align-items-center justify-content-center">
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.props.print(
                                                    "ticketCotizacion"
                                                );
                                                //printActions.print('ticketCotizacion');
                                            }}
                                            style={{ width: "150px" }}
                                            className="btn mr-1 btn-secondary mt-lg"
                                            type={"button"}
                                        >
                                            <em className="fa fa-print" />{" "}
                                            Imprimir
                                        </button>
                                        <button
                                            style={{ width: "150px" }}
                                            className="btn ml-1 btn-outline-secondary mt-lg"
                                            type="button"
                                            onClick={() => {
                                                finalizarCotizacion();
                                            }}
                                        >
                                            <em className="fa fa-check" />{" "}
                                            Finalizar cotización
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="container-vista-previa-body">
                                <div className="d-flex flex-row justify-content-center w100">
                                    <PrintContainer name="ticketCotizacion">
                                        {venta_cotizacion && (
                                            <TicketCotizacion
                                                cotizacion={venta_cotizacion}
                                            />
                                        )}
                                    </PrintContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

import { connect } from 'react-redux';
import { actions } from "../../../../../redux/modules/reportes/movimientos";
import DetalleMovimientos from "./DetalleMovimientos";
import { goBack } from "react-router-redux";


const ms2p = (state) => {

    return {
        ...state.reporte_movimientos,
        conf_tipo_negocio:
        state.configuracion && state.configuracion.config
            ? state.configuracion.config.conf_tipo_negocio
            : false, // Verificación añadida
    };
};

const md2p = { 
    ...actions,
};

export default connect(ms2p, md2p)(DetalleMovimientos);
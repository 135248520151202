import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./inventario.css";
import { icons } from "icons";
import { renderStaticSelect } from "Utils/renderField/renderField";

class ToolbarInventario extends Component {
  render() {
    const {
      buscar,
      search,
      estado_descarga,
      descarga_excel,
      filtroValue,
      onFiltroChange,
      familiaOptions,
      familiaValue,
      onFamiliaChange,
      kilatajeOptions,
      kilatajeValue,
      onKilatajeChange,
      total_inventario,
      total_costo,
      total_peso,
      total_productos,
      joyeria
    } = this.props;


    return (
      <div className="react-bs-table-tool-bar">
        <div className="toolbar-inventario-container">
          <div className="d-flex flex-wrap align-items-end gap-2 w-100 mb-3">
            {/* Select Filtro */}
            <div className="toolbar-item">
              <label className="mb-1 d-block fw-medium">Filtrar por</label>
              <div className="toolbar-select">
                {renderStaticSelect({
                  input: {
                    value: filtroValue,
                    onChange: onFiltroChange
                  },
                  options: [
                    { value: "todos", label: "Todos" },
                    { value: "con_inventario", label: "Con inventario" },
                    { value: "sin_inventario", label: "Sin inventario" },
                    { value: "inventario_negativo", label: "Inventario negativo" }
                  ],
                  valueKey: "value",
                  labelKey: "label"
                })}
              </div>
            </div>

            {/* Select Kilataje */}
            {joyeria && <div className="toolbar-item">
              <label className="mb-1 d-block fw-medium">Kilataje</label>
              <div className="toolbar-select">
                {renderStaticSelect({
                  input: {
                    value: kilatajeValue,
                    onChange: onKilatajeChange
                  },
                  options: kilatajeOptions,
                  valueKey: "value",
                  labelKey: "label"
                })}
              </div>
            </div>}

            {/* Select Familia */}
            <div className="toolbar-item">
              <label className="mb-1 d-block fw-medium">Fam</label>
              <div className="toolbar-select">
                {renderStaticSelect({
                  input: {
                    value: familiaValue,
                    onChange: onFamiliaChange
                  },
                  options: familiaOptions,
                  valueKey: "value",
                  labelKey: "label"
                })}
              </div>
            </div>

            {/* Barra de búsqueda */}
            <div className="toolbar-item flex-grow-1">
              <div className="toolbar-search">
                <input
                  type="text"
                  placeholder="Buscar"
                  value={search}
                  onChange={(e) => buscar(e.target.value)}
                  className="form-control"
                />
                <span className="fa fa-search icon-search" />
              </div>
            </div>

            {/* Botones */}
            <div className="toolbar-actions ms-auto">
              <button
                className="btn btn-toolbar-action"
                onClick={descarga_excel}
                disabled={estado_descarga}
                title="Descargar"
              >
                <i className={`fa ${estado_descarga ? "fa-spinner fa-pulse" : "fa-download"}`} />
              </button>

              <button
                className="btn btn-toolbar-action"
                onClick={() => this.props.onImprimir()}
                title="Imprimir"
              >
                <i className="fa fa-print" />
              </button>
            </div>
          </div>

          {/* Resumen de Totales */}
          <div className="totals">
            <div>
              <div className="toolbar-summary-box mb-2">
                <div className="summary-label">Total Producto</div>
                <div className="summary-value">{total_productos}</div>
              </div>
              <div className="toolbar-summary-box">
                <div className="summary-label">Total de Inventario</div>
                <div className="summary-value">{total_inventario}</div>
              </div>
            </div>
            <div>
              <div className="toolbar-summary-box mb-2">
                <div className="summary-label">Total de Costo</div>
                <div className="summary-value">Q{total_costo}</div>
              </div>
              {joyeria &&
                <div className="toolbar-summary-box">
                  <div className="summary-label">Total en Peso Gramos</div>
                  <div className="summary-value">{total_peso} grs.</div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ToolbarInventario.propTypes = {
  buscar: PropTypes.func.isRequired,
  search: PropTypes.string,
  estado_descarga: PropTypes.bool,
  descarga_excel: PropTypes.func,
  filtroValue: PropTypes.any,
  onFiltroChange: PropTypes.func.isRequired,
  familiaOptions: PropTypes.array.isRequired,
  familiaValue: PropTypes.any,
  onFamiliaChange: PropTypes.func.isRequired,
  kilatajeOptions: PropTypes.array.isRequired,
  kilatajeValue: PropTypes.any,
  onKilatajeChange: PropTypes.func.isRequired,
  onImprimir: PropTypes.func.isRequired,
  total_inventario: PropTypes.number,
  total_costo: PropTypes.number,
  total_peso: PropTypes.number,
  total_productos: PropTypes.number
};

export default ToolbarInventario;
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ExistenciaRealModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    rowData: PropTypes.object,
    onConfirm: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  state = {
    inputValue: ''
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleConfirm();
    }
  };

  handleConfirm = () => {
    const { inputValue } = this.state;
    const value = parseFloat(inputValue);
    
    if (inputValue === '' || isNaN(value)) {
      this.props.onConfirm(null, 'Debe ingresar un valor numérico');
      return;
    }
    
    this.props.onConfirm(value);
    this.setState({ inputValue: '' });
  };

  render() {
    const { show, onHide, rowData, error } = this.props;
    const { inputValue } = this.state;

    if (!show) return null;

    const productoNombre = rowData && rowData.producto 
      ? rowData.producto.nombre 
      : "Sin nombre";
    const existenciaSistema = rowData && rowData.inventario 
      ? rowData.inventario 
      : 0;


    const parsedInput = parseFloat(inputValue);
    const diferencia = !isNaN(parsedInput) ? parsedInput - existenciaSistema : 0;
  
    return (
      <div className="modal-backdrop" style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}>
        <div className="modal-content" style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          minWidth: '400px',
          maxWidth: '600px',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
        }}>
          <h3 style={{ marginBottom: '15px' }}>
            Asignar existencia real para {productoNombre}
          </h3>
          <p style={{ marginBottom: '10px', color: '#666' }}>
            Existencia en sistema: {existenciaSistema}
          </p>
          <p style={{ marginBottom: '10px', color: '#666' }}>
            Diferencia: {diferencia}
          </p>
          <input
            type="number"
            value={inputValue}
            onChange={this.handleInputChange}
            onKeyDown={this.handleKeyDown}
            className="form-control"
            style={{ marginBottom: '10px' }}
            autoFocus
          />
          
          {error && (
            <div className="text-danger mb-2" style={{ fontSize: '0.9em' }}>
              {error}
            </div>
          )}
          
          <div className="modal-actions" style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '10px'
          }}>
            <button
              onClick={onHide}
              className="btn btn-secondary"
            >
              Cancelar
            </button>
            <button
              onClick={this.handleConfirm}
              className="btn btn-primary"
            >
              Asignar
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ExistenciaRealModal;
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./ProtectedRoute";
import { ToastContainer, ToastStore } from "react-toasts";
import { Login, Recuperar, Resetear } from "./common/components/Login";

import Dashboard from "./common/components/Dashboard";

/* CAJAS */
import CajasListado from "./common/components/Cajas/Listado/CajasListadoContainer";
import CajasListadoPaginado from "./common/components/Cajas/ListadoPaginado";
import CajasCrear from "./common/components/Cajas/Crear/CajasCrearContainer";
import CajasEditar from "./common/components/Cajas/Editar/CajasEditarContainer";
import Apertura from "./common/components/Cajas/Apertura/AperturaContainer";
import Cierre from "./common/components/Cajas/Cierre/Cierre/CierreContainer";
import UltimoCierre from "./common/components/Cajas/Cierre/Impresion/ImpresiónContainer";
import Activa from "./common/components/Cajas/Detalle/DetalleCajaContainer";

/* SUCURSALES */
import SucursalesListado from "./common/components/Sucursales/Listado/SucursalesListadoContainer";
import SucursalesCrear from "./common/components/Sucursales/Crear/SucursalesCrearContainer";
import SucursalesEditar from "./common/components/Sucursales/Editar/SucursalesEditarContainer";
import SucursalesDetalle from "./common/components/Sucursales/Detalle/SucursalesDetalleContainer";

/* CONFIGURACION */
import ConfiguracionEditar from "./common/components/Configuracion/Editar/ConfiguracionEditarContainer";
import {
    Listado as ListadoDestinosSalidaProductos,
    Crear as CrearDestinoSalidaProducto,
    Editar as EditarDestinoSalidaProducto,
} from "./common/components/Configuracion/DestinosSalidaProducto";

import { Listado as LiastadoAdicionalesNotaFel, Create as CreateAdicionalNotaFel } from "./common/components/Configuracion/AdicionalesNotaFEL"
/*  PRODUCTOS  */
import ProductoListado from "./common/components/Producto/Listado/ProductoListadoContainer";
import ProductoCrear from "./common/components/Producto/Crear/ProductoCrearContainer";
import ProductoEditar from "./common/components/Producto/Editar/ProductoEditarContainer";
import ProductoDetalle from "./common/components/Producto/Detalle/ProductoDetalleContainer";
import ImprimirCodigoProducto from "./common/components/Producto/ImprimirCodigo";

import "../assets/fonts/fonts.css";

/* BODEGA */
import {
    Listado as BodegaListado,
    Reajuste as BodegaReajuste,
    Bajas as BodegaBajas,
    ReporteIngreso as BodegaReporteIngreso,
    Ingreso as BodegaIngreso,
    ImpresionProductos as BodegaImpresionProducto,
    SalidaProductos as BodegaSalidaProductos,
} from "./common/components/Bodega";

/* CLIENTE VENTA */
import { Cliente as CrearCliente } from "./common/components/Ventas";

/* VENTAS */
import { Venta } from "./common/components/Ventas";

/* DEVOLUCIONES */
import {
    CodigoFactura,
    Eleccion as EleccionProductosDevolucion,
    Motivos as MotivosDevolucion,
    VistaPrevia,
    Vale,
} from "./common/components/Devoluciones";

/* BITACORA */
import { DetalleBitacora, ListadoBitacora } from "./common/components/Bitacora";

/* USUARIOS */
import {
    Listado as UsuariosListado,
    Crear as UsuariosCrear,
    Editar as UsuariosEditar,
} from "./common/components/Configuracion/Usuarios";

/* CLIENTES */
import {
    Listado as ClientesListado,
    Crear as ClientesCrear,
    Editar as ClientesEditar,
} from "./common/components/Clientes";

/* PROVEEDORES */
import {
    Listado as ProveedoresListado,
    Crear as ProveedoresCrear,
    Editar as ProveedoresEditar,
} from "./common/components/Proveedores";

/* FAMILIARES 

 */
import {
    Listado as FamiliaresListado,
    Crear as FamiliaresCrear,
    Editar as FamiliaresEditar,
} from "./common/components/Familiares";

/* ORDENES ONLINE */
import {
    Listado as ListadoOrdenOnline,
    Detalle as DetalleOrdenOnline,
    Editar as EditarOrdenOnline,
} from "./common/components/OrdenOnline";

/* GASTOS */
import {
    Listado as ListadoGastos,
    Crear as CrearGasto,
    Detalle as DetalleGasto,
} from "./common/components/Gastos";

/* REPORTES */
import ReporteGastos from "./common/components/Reportes/Gastos";
import {
    BalanceGeneral,
    ListadoAperturaCierre,
    ListadoVentas,
    ListadoCompras,
    DetalleVenta,
    DetalleOrdenCompraR,
    MejoresClientes,
    ReporteBodega,
    Inversion,
    ResumenReporteBodega,
    ListadoDevoluciones,
    DetalleDevolucion,
    GananciaBruta,
    ListadoReporteDespachos,
    DetalleReporteDespacho,
    HistorialPrecios,
    DetalleMovimientos,
    DetalleMovimientosGeneral,
    HistorialBajasSalidas,
    ReporteInventario
} from "./common/components/Reportes";
import ResumenReporteGasto from "./common/components/Reportes/Gastos/Resumen";
import ProductosMasVendidos from "./common/components/Reportes/ProductosMasVendidos";

/* PERFIL */
import Perfil from "./common/components/Perfil";

/* CUENTA POR COBRAR */
import {
    ListadoCuentaCobrar,
    ReciboCuentaCobrar,
} from "./common/components/CuentaCobrar";

import {
    ListadoCuentaCobrar as ReporteCuentaCobrar,
    DetalleCuentaCobrar,
} from "./common/components/Reportes/CuentaCobrar";

/* PUNTO DE VENTA */
import PuntoVenta from "./common/components/PuntoVenta";
import PuntoVentaMostrador from "./common/components/PuntoVenta/Mostrador";
import PuntoVentaCaja from "./common/components/PuntoVenta/Caja";

/* DESPACHOS */
import {
    ListadoDespacho,
    CrearDespacho,
    NotaEnvio,
    DetalleDespacho,
    ListadoRecepcionDespacho,
    CrearRecepcionDespacho,
    NotaRecepcionDespacho,
    DetalleRecepcionDespacho,
} from "./common/components/Despacho";

/* COTIZACION */
import { ListadoCotizacion } from "./common/components/Cotizacion";

/* ORDENES DE COMPRA */
import {
    Listado as ListadoOrdenCompra,
    Crear as CrearOrdenCompra,
    TipoPago,
    DetalleOC,
} from "./common/components/OrdenCompra";
import {
    ListadoRecepcionOrdenCompra,
    RecepcionOrdenCompra,
    DetalleRecepcionOrdenCompra,
} from "./common/components/Despacho/RecepcionOrdenCompra";
import { ListadoDescuentoRecargo } from "./common/components/Reportes/DescuentoRecargo"

import ListadoInventario from "./common/components/Inventario/Listado/ListadoContainer";

import Blank from "./common/components/Blank";
module.exports = (
    <div className="container__content">
        <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute
                noBreadcrumbs={true}
                path="/sin_permisos"
                component={Blank}
            />
            <PrivateRoute
                noBreadcrumbs={true}
                path="/dashboard"
                component={Dashboard}
            />
            {/* CAJAS */}
            <PrivateRoute exact path="/abrir_caja" component={CajasListado} />
            <PrivateRoute
                exact
                path="/configuracion/cajas"
                component={CajasListadoPaginado}
            />
            <PrivateRoute
                exact
                path="/configuracion/cajas/crear"
                component={CajasCrear}
            />
            <PrivateRoute
                exact
                path="/cajas/detalle_cierre/:id"
                component={UltimoCierre}
            />
            <PrivateRoute
                exact
                path="/configuracion/cajas/editar/:id"
                component={CajasEditar}
            />
            <PrivateRoute
                exact
                path="/cajas/apertura/:id"
                component={Apertura}
            />
            <PrivateRoute exact path="/caja_activa/" component={Activa} />
            <PrivateRoute exact path="/caja_activa/cierre" component={Cierre} />
            {/* CLIENTES */}

            {/* <PrivateRoute
                exact
                path="/ventas"
                component={CrearCliente}
            /> */}
            {/* GASTOS */}
            <PrivateRoute exact path="/gastos" component={ListadoGastos} />
            <PrivateRoute
                exact
                path="/gastos/registrar"
                component={CrearGasto}
            />
            <PrivateRoute
                exact
                path="/gastos/detalle/:id"
                component={DetalleGasto}
            />

            {/* VENTAS */}
            {/* <PrivateRoute
                exact
                path="/ventas/"
                component={Venta}
            /> */}

            {/* CUENTAS POR COBRAR */}
            <PrivateRoute
                exact
                path="/cuentas_por_cobrar"
                component={ListadoCuentaCobrar}
            />
            <PrivateRoute
                exact
                path="/cuentas_por_cobrar/detalle/:id"
                component={ReciboCuentaCobrar}
            />

            {/* DEVOLUCIONES */}
            <PrivateRoute exact path="/devolucion" component={CodigoFactura} />
            <PrivateRoute
                exact
                path="/devolucion/seleccion_de_producto/:id"
                component={EleccionProductosDevolucion}
            />
            <PrivateRoute
                exact
                path="/devolucion/seleccion_de_producto/:id/motivos"
                component={MotivosDevolucion}
            />
            <PrivateRoute
                exact
                path="/devolucion/seleccion_de_producto/:id/motivos/vale"
                component={VistaPrevia}
            />
            <PrivateRoute
                exact
                path="/devolucion/seleccion_de_producto/:id/motivos/vale/:id_devolucion"
                component={Vale}
            />

            {/* BODEGAS*/}
            <PrivateRoute exact path="/bodega" component={BodegaListado} />
            <PrivateRoute
                exact
                path="/bodega/ingreso_de_mercaderia"
                component={BodegaIngreso}
            />
            <PrivateRoute
                exact
                path="/bodega/resumen_de_ingreso/:id"
                component={BodegaReporteIngreso}
            />
            <PrivateRoute
                exact
                path="/bodega/inventario"
                component={ListadoInventario}
            />
            <PrivateRoute
                exact
                path="/bodega/reportar_baja"
                component={BodegaBajas}
            />
            <PrivateRoute
                exact
                path="/bodega/salida_de_producto"
                component={BodegaSalidaProductos}
            />
            <PrivateRoute
                exact
                path="/bodega/impresion_productos"
                component={BodegaImpresionProducto}
            />

            {/*SUCURSALES*/}
            <PrivateRoute
                exact
                path="/configuracion/sucursales"
                component={SucursalesListado}
            />
            <PrivateRoute
                exact
                path="/sucursales/editar/:id"
                component={SucursalesEditar}
            />
            <PrivateRoute
                exact
                path="/sucursales/detalle/:id"
                component={SucursalesDetalle}
            />

            {/*CONFIGURACION*/}
            <PrivateRoute
                exact
                path="/configuracion/general"
                component={ConfiguracionEditar}
            />
            {/* DESTINOS SALIDA PRODUCTOS */}
            <PrivateRoute
                exact
                path="/configuracion/destinos_salida_de_productos"
                component={ListadoDestinosSalidaProductos}
            />
            <PrivateRoute
                exact
                path="/configuracion/destinos_salida_de_productos/crear"
                component={CrearDestinoSalidaProducto}
            />
            <PrivateRoute
                exact
                path="/configuracion/destinos_salida_de_productos/editar/:id"
                component={EditarDestinoSalidaProducto}
            />
            {/* ADICIONALES NOTA FEL */}
            <PrivateRoute
                exact
                path="/configuracion/adicionales_nota_fel"
                component={LiastadoAdicionalesNotaFel}
            />
            <PrivateRoute
                exact
                path="/configuracion/adicionales_nota_fel/crear"
                component={CreateAdicionalNotaFel}
            />
            <PrivateRoute
                exact
                path="/configuracion/adicionales_nota_fel/editar/:id"
                component={CreateAdicionalNotaFel}
            />

            {/* USUARIOS */}
            <PrivateRoute
                exact
                path="/configuracion/usuarios"
                component={UsuariosListado}
            />
            <PrivateRoute
                exact
                path="/configuracion/usuarios/crear"
                component={UsuariosCrear}
            />
            <PrivateRoute
                exact
                path="/configuracion/usuarios/editar/:id"
                component={UsuariosEditar}
            />
            <PrivateRoute
                exact
                path="/configuracion/usuarios/detalle/:id"
                component={SucursalesDetalle}
            />

            {/* BITACORA */}
            <PrivateRoute exact path="/bitacora" component={ListadoBitacora} />
            <PrivateRoute
                exact
                path="/bitacora/detalle/:id"
                component={DetalleBitacora}
            />

            {/* CLIENTES */}
            <PrivateRoute
                exact
                path="/configuracion/clientes"
                component={ClientesListado}
            />
            <PrivateRoute
                exact
                path="/configuracion/clientes/crear"
                component={ClientesCrear}
            />
            <PrivateRoute
                exact
                path="/configuracion/clientes/editar/:id"
                component={ClientesEditar}
            />

            {/* PROVEEDORES */}
            <PrivateRoute
                exact
                path="/configuracion/proveedores"
                component={ProveedoresListado}
            />
            <PrivateRoute
                exact
                path="/configuracion/proveedores/crear"
                component={ProveedoresCrear}
            />
            <PrivateRoute
                exact
                path="/configuracion/proveedores/editar/:id"
                component={ProveedoresEditar}
            />

            {/* FAMILIAS */}
            <PrivateRoute
                exact
                path="/configuracion/familias"
                component={FamiliaresListado}
            />
            <PrivateRoute
                exact
                path="/configuracion/familias/crear"
                component={FamiliaresCrear}
            />
            <PrivateRoute
                exact
                path="/configuracion/familias/editar/:id"
                component={FamiliaresEditar}
            />




            {/* PRODUCTOS */}
            <PrivateRoute exact path="/productos" component={ProductoListado} />
            <PrivateRoute
                exact
                path="/productos/crear"
                component={ProductoCrear}
            />
            <PrivateRoute
                exact
                path="/productos/editar/:id"
                component={ProductoEditar}
            />
            <PrivateRoute
                exact
                path="/productos/detalle/:id"
                component={ProductoDetalle}
            />
            <PrivateRoute
                exact
                path="/productos/imprimir_codigos/:id"
                component={ImprimirCodigoProducto}
            />

            {/* REPORTES */}
            <PrivateRoute
                exact
                path="/reporte_balance_general"
                component={BalanceGeneral}
            />
            <PrivateRoute
                exact
                path="/reporte_gastos"
                component={ReporteGastos}
            />
            <PrivateRoute
                exact
                path="/reporte_devoluciones"
                component={ListadoDevoluciones}
            />
            <PrivateRoute
                exact
                path="/reporte_devoluciones/detalle/:id"
                component={DetalleDevolucion}
            />
            <PrivateRoute
                exact
                path="/reporte_gastos/:id"
                component={ResumenReporteGasto}
            />
            <PrivateRoute
                exact
                path="/reporte_ventas"
                component={ListadoVentas}
            />
            <PrivateRoute
                exact
                path="/reporte_ventas/:id"
                component={DetalleVenta}
            />
            <PrivateRoute
                exact
                path="/reporte_compras"
                component={ListadoCompras}
            />
            <PrivateRoute
                exact
                path="/reporte_compras/:id"
                component={DetalleOrdenCompraR}
            />
            <PrivateRoute
                exact
                path="/reporte_apertura_cierre_caja"
                component={ListadoAperturaCierre}
            />
            <PrivateRoute
                exact
                path="/reporte_apertura_cierre_caja/:id"
                component={UltimoCierre}
            />

            <PrivateRoute
                exact
                path="/reporte_productos_mas_vendidos"
                component={ProductosMasVendidos}
            />
            <PrivateRoute
                exact
                path="/inversion_en_bodega"
                component={Inversion}
            />
            <PrivateRoute
                exact
                path="/reporte_mejores_clientes"
                component={MejoresClientes}
            />
            <PrivateRoute
                exact
                path="/reporte_bodega"
                component={ReporteBodega}
            />

            <PrivateRoute
                exact
                path="/reporte_bodega/:id"
                component={ResumenReporteBodega}
            />
            <PrivateRoute
                exact
                path="/reporte_ganancia_bruta"
                component={GananciaBruta}
            />
            <PrivateRoute
                exact
                path="/reporte_de_despachos"
                component={ListadoReporteDespachos}
            />
            <PrivateRoute
                exact
                path="/reporte_de_despachos/visualizacion/:id"
                component={DetalleReporteDespacho}
            />
            <PrivateRoute
                exact
                path="/reporte_historial_precios"
                component={HistorialPrecios}
            ></PrivateRoute>
            <PrivateRoute
                path="/historial_de_inventario"
                component={DetalleMovimientos}
            />
            <PrivateRoute
                path="/historial_general_de_inventario"
                component={DetalleMovimientosGeneral}
            />
            <PrivateRoute
                path="/reporte_historial_precios"
                component={HistorialPrecios}
            ></PrivateRoute>
            <PrivateRoute
                path="/bajas_y_salidas_por_productos"
                component={HistorialBajasSalidas}
            ></PrivateRoute>
            <PrivateRoute
                exact
                path="/reporte_descuentos_y_recargos"
                component={ListadoDescuentoRecargo}
            ></PrivateRoute>
            <PrivateRoute
                exact
                path="/reporte_descuentos_y_recargos/:id"
                component={DetalleVenta}
            />
            <PrivateRoute
                exact
                path="/reporte_inventario"
                component={ReporteInventario}
            />

            {/* PERFIL */}
            <PrivateRoute exact path="/perfil" component={Perfil} />

            {/* CUENTA POR COBRAR */}
            <PrivateRoute
                exact
                path="/reporte_cuenta_por_cobrar"
                component={ReporteCuentaCobrar}
            />
            <PrivateRoute
                exact
                path="/reporte_cuenta_por_cobrar/:id"
                component={DetalleCuentaCobrar}
            />

            {/* PUNTO DE VENTA */}
            <PrivateRoute exact path="/punto_de_venta" component={PuntoVenta} />

            <PrivateRoute
                exact
                path="/punto_de_venta/venta/:id"
                component={DetalleVenta}
            />
            <PrivateRoute
                exact
                path="/punto_de_venta/mostrador"
                component={PuntoVentaMostrador}
            />
            <PrivateRoute
                exact
                path="/punto_de_venta/caja"
                component={PuntoVentaCaja}
            />

            {/* DESPACHOS */}
            <PrivateRoute exact path="/despachos" component={ListadoDespacho} />
            <PrivateRoute
                exact
                path="/despachos/crear"
                component={CrearDespacho}
            />
            <PrivateRoute
                exact
                path="/despachos/nota_de_envio/:id"
                component={NotaEnvio}
            />
            <PrivateRoute
                exact
                path="/despachos/visualizacion/:id"
                component={DetalleDespacho}
            />

            {/* RECEPCION DESPACHOS */}
            <PrivateRoute
                exact
                path="/recepciones_de_despachos"
                component={ListadoRecepcionDespacho}
            />
            <PrivateRoute
                exact
                path="/recepciones_de_despachos/recibir/:id"
                component={CrearRecepcionDespacho}
            />

            <PrivateRoute
                exact
                path="/recepciones_de_despachos/nota_de_recepcion/:id"
                component={NotaRecepcionDespacho}
            />
            <PrivateRoute
                exact
                path="/recepciones_de_despachos/visualizacion/:id"
                component={DetalleRecepcionDespacho}
            />
            <PrivateRoute
                exact
                path="/cotizaciones"
                component={ListadoCotizacion}
            />

            <PrivateRoute
                exact
                path="/ordenes_de_compra"
                component={ListadoOrdenCompra}
            />

            <PrivateRoute
                exact
                path="/ordenes_de_compra/nueva"
                component={CrearOrdenCompra}
            />
            <PrivateRoute
                exact
                path="/ordenes_de_compra/nueva/tipo_de_pago"
                component={TipoPago}
            />

            <PrivateRoute
                exact
                path="/ordenes_de_compra/:id"
                component={DetalleOC}
            />

            {/* RECEPCION OC */}
            <PrivateRoute
                exact
                path="/recepciones_de_ordenes_de_compra"
                component={ListadoRecepcionOrdenCompra}
            />
            <PrivateRoute
                exact
                path="/recepciones_de_ordenes_de_compra/recibir/:id"
                component={RecepcionOrdenCompra}
            />

            {/* <PrivateRoute
                exact
                path="/recepciones_de_despachos/nota_de_recepcion/:id"
                component={NotaRecepcionDespacho}
            />*/}
            <PrivateRoute
                exact
                path="/recepciones_de_ordenes_de_compra/visualizacion/:id"
                component={DetalleRecepcionOrdenCompra}
            />
            {/* PROVEEDORES */}
            <PrivateRoute
                exact
                path="/ordenes_online"
                component={ListadoOrdenOnline}
            />
            <PrivateRoute
                exact
                path="/ordenes_online/detalle/:id"
                component={DetalleOrdenOnline}
            />
            <PrivateRoute
                exact
                path="/ordenes_online/editar/:id"
                component={EditarOrdenOnline}
            />

            <Route path="/recuperar_contraseña" component={Recuperar} />
            <Route
                path="/reestablecer_contraseña/:token"
                component={Resetear}
            />
            <Route path="*" component={Login} />
        </Switch>
        <ToastContainer
            position={ToastContainer.POSITION.TOP_RIGHT}
            lightBackground
            store={ToastStore}
        />
    </div>
);

import React from "react";
import { RenderNumber } from "Utils/renderField/renderReadField";
import { icons } from "icons";


export function formatoNombre(cell, row) {
    return (
        <div>
            <b>{row.producto.nombre}</b>
            <div>{row.nombre}</div>
        </div>
    );
}
export function formatoMarca(cell, row) {
    return (
        <div>
            {row.producto.marca}
        </div>
    );
}

export function formatoPeso(cell, row) {
    return (
        <div>
            {row.producto.peso}
        </div>
    );
}

export function formatoKilataje(cell, row) {
    return (
        <div>
            {row.producto.kilataje}
        </div>
    );
}

export function formatoExistencia(cell, row) {
    return <RenderNumber value={row.inventario} />
}

export function formatoAcciones(props) {
    return (cell, row) => {
        return (
            <div className="w-100 d-flex align-items-center justify-content-end">
                <img className="icon-img" style={{marginRight: "10px"}} onClick={(e) => {
                    e.persist();
                    props.agregar(row);
                    props.cambio_buscador();
                }} src={icons.agregar} alt="Agregar"/>
            </div>
        );
    }
}

export function buscar(props) {
    return () => {
        let search = "";
        return (
            <input
                placeholder={"Buscar"}
                type={"text"}
                className="form-control"
                ref={node => {
                    search = node;
                    if (search) {
                        search.value = props.search;
                    }
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        if(search.value){
                            props.agregarPrimer(search.value);
                            search.value = "";
                        }
                    }
                }}
                onChange={() => {
                    if (search) {
                        props.buscar(search.value);
                    }
                }}
            />
        );
    }
}

export function formatoProducto(cell, row) {
    return (
        <div>
            <b>{row.producto.nombre}</b>
            <div><small>{row.nombre}</small></div>
        </div>
    );
}

export function formatoAvatar(cell, row) {
    if (row.producto.img_cropped !== ""){
      return <img className="imagen-sm" src={row.producto.img_cropped} alt="" style={{width:"45px", height:"45px"}}/>
    }
    else{
      return <img className="imagen-sm" src={icons.img_default} alt="" style={{width:"40px", height:"40px"}}/>
    }
}

export function formatoAvatarMovil(cell, row) {
    if (row.producto.img_cropped !== ""){
      return <img className="imagen-sm" src={row.producto.img_cropped} alt="" style={{width:"70px", height:"70px", border:"2px solid black"}}/>
    }
    else{
      return <img className="imagen-sm" src={icons.img_default} alt="" style={{width:"70px", height:"70px"}}/>
    }
}

export function formatoInventario(cell, producto) {
    return (
        <div>
            <RenderNumber value={producto.inventario} />
        </div>
    );
}

import moment from "moment";
import { icons } from "icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker from "Utils/DatePicker";
import Toolbar from "Utils/Toolbar/Toolbar";
import LoadMask from "Utils/LoadMask/LoadMask";
import { tableOptions, BreakLine } from "Utils/tableOptions";
import { RenderReadCurrency } from "../../Utils/renderField/renderFieldsCurrency";
import {
    RenderMoneda,
    RenderNumero,
    RenderPorcentaje,
} from "Utils/renderField/renderTableField";

function formatoAvatar(cell, row) {
    return (
        <div>
            {row.fraccion.producto.img_cropped ? (
                <img
                    className="imagen-sm-50"
                    src={row.fraccion.producto.img_cropped}
                    alt=""
                    style={{ width: "50px", height: "50px" }}
                />
            ) : (
                <img
                    className="imagen-sm-50"
                    src={icons.img_default}
                    alt=""
                    style={{ width: "50px", height: "50px" }}
                />
            )}
        </div>
    );
}

class GananciaBruta extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.getListGanancia(this.props.page);
    }

    render() {
        //  state
        const {
            loader,
            data,
            fecha_inicial,
            fecha_final,
            estado_descarga,
            conf_tipo_negocio,
        } = this.props;
        //  Bind
        const { descargarListado } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.onSortChange = this.props.sortChange;
        options.page = this.props.page;
        options.onPageChange = this.props.getListGanancia;

        return (
            <div>
                <div className="row">
                    <div className="col-12">
                        <div className="grid-container">
                            <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                <div style={{ flex: "1" }}>
                                    <h3>
                                        <b>Historial ganancia bruta</b>
                                    </h3>
                                </div>
                                <div
                                    className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start"
                                    style={{ flex: "3" }}
                                >
                                    <div>
                                        <label>Fecha Inicio</label>
                                        <DatePicker
                                            value={fecha_inicial}
                                            maxDate={new Date()}
                                            onChange={(date) => {
                                                this.props.setFecha(
                                                    "Inicial",
                                                    date
                                                );
                                            }}
                                        />
                                    </div>
                                    &nbsp; &nbsp;
                                    <div>
                                        <label>Fecha Fin</label>
                                        <DatePicker
                                            value={fecha_final}
                                            minDate={
                                                new Date(
                                                    moment(
                                                        fecha_inicial,
                                                        "YYYY-MM-DD"
                                                    )
                                                )
                                            }
                                            onChange={(date) => {
                                                this.props.setFecha(
                                                    "Final",
                                                    date
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* <div className="d-flex flex-row w-100 mt-10">
                                    <button
                                        className="mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                        disabled={estado_descarga}
                                        onClick={() => {
                                            descargarListado();
                                        }}
                                    >
                                        <em
                                            className={`fa ${
                                                estado_descarga
                                                    ? "fa-spinner fa-pulse"
                                                    : "fa-download"
                                            }`}
                                        ></em>
                                        &nbsp;Descargar Listado
                                    </button>
                                </div> */}
                            </div>
                            {/* LISTADO */}
                            <h4 className="d-flex flex-column flex-sm-row justify-content-end pr-15 align-items-end mb-3">
                                <div>Monto total de ganancia</div>
                                <div className="ml-3">
                                    <b>
                                        <RenderReadCurrency
                                            value={data.total ? data.total : 0}
                                        />
                                    </b>
                                </div>
                            </h4>
                            <hr />
                            <div className="grid-titulo">
                                <Toolbar
                                    titulo=""
                                    buscar={this.props.buscar}
                                    search={this.props.search}
                                    estado_descarga={estado_descarga}
                                    descarga_excel={descargarListado}
                                />
                            </div>
                            <LoadMask loading={loader} dark blur>
                                <BootstrapTable
                                    data={loader ? [] : data.results}
                                    remote
                                    pagination
                                    hover
                                    fetchInfo={{ dataTotalSize: data.count }}
                                    options={options}
                                >
                                    <TableHeaderColumn
                                        dataField="id"
                                        isKey
                                        dataFormat={formatoAvatar}
                                        width="80px"
                                    ></TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataFormat={(cell) =>
                                            cell.codigo_barras
                                                ? cell.codigo_barras
                                                : ""
                                        }
                                        dataSort={true}
                                        dataField="fraccion"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                    >
                                        Código
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="fraccion__producto__nombre"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataSort
                                        dataFormat={(cell, row) => {
                                            return (
                                                <div>
                                                    <b>
                                                        {
                                                            row.fraccion
                                                                .producto.nombre
                                                        }
                                                    </b>
                                                    <div>
                                                        {row.fraccion.nombre}
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    >
                                        Nombre
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataFormat={(cell, row) =>
                                            row.fraccion.producto.marca
                                                ? row.fraccion.producto.marca
                                                : ""
                                        }
                                        dataField="fraccion__producto__marca"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataSort
                                    >
                                        Marca
                                    </TableHeaderColumn>
                                    {conf_tipo_negocio === "Joyeria" && (
                                        <TableHeaderColumn
                                            dataFormat={(cell, row) =>
                                                row.fraccion.producto.kilataje
                                                    ? row.fraccion.producto.kilataje
                                                    : ""
                                            }
                                            dataField="fraccion__producto__kilataje"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataSort
                                        >
                                            Kilataje
                                        </TableHeaderColumn>
                                    )}
                                    {conf_tipo_negocio === "Joyeria" && (
                                        <TableHeaderColumn
                                            dataFormat={(cell, row) =>
                                                row.fraccion.producto.peso
                                                    ? row.fraccion.producto.peso
                                                    : ""
                                            }
                                            dataField="fraccion__producto__peso"
                                            tdStyle={BreakLine}
                                            thStyle={BreakLine}
                                            dataSort
                                        >
                                            Peso
                                        </TableHeaderColumn>
                                    )}

                                    <TableHeaderColumn
                                        dataAlign="right"
                                        dataFormat={(cell) => (
                                            <RenderMoneda monto={cell} />
                                        )}
                                        dataField="costo_promedio"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataSort
                                    >
                                        Costo Prom.
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataAlign="right"
                                        dataFormat={(cell) => (
                                            <RenderMoneda monto={cell} />
                                        )}
                                        dataField="precio_promedio"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataSort
                                    >
                                        Precio Prom.
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataAlign="right"
                                        dataFormat={(cell) => (
                                            <RenderNumero monto={cell} />
                                        )}
                                        dataField="total_cantidad"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataSort
                                    >
                                        Ventas
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataAlign="right"
                                        dataFormat={(cell) => (
                                            <RenderMoneda monto={cell} />
                                        )}
                                        dataField="total_costo"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataSort
                                    >
                                        Subtotal Costo
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataAlign="right"
                                        dataFormat={(cell) => (
                                            <RenderMoneda monto={cell} />
                                        )}
                                        dataField="total_precio"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataSort
                                    >
                                        Subtotal Ventas
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataAlign="right"
                                        dataFormat={(cell) => (
                                            <RenderPorcentaje
                                                monto={cell}
                                                decimalScale={2}
                                            />
                                        )}
                                        dataField="total_margen"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataSort
                                    >
                                        Margen de Ganancia
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataAlign="right"
                                        dataFormat={(cell) => (
                                            <RenderMoneda monto={cell} />
                                        )}
                                        dataField="total_gancia"
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataSort
                                    >
                                        Ganancia
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

GananciaBruta.propTypes = {
    data: PropTypes.object.isRequired,
    fecha_inicial: PropTypes.string.isRequired,
    fecha_final: PropTypes.string.isRequired,
};

export default GananciaBruta;

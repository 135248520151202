import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadMask from "Utils/LoadMask/LoadMask";
import Select, { Async } from "react-select";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { api } from "api";
import {
    RenderDateTime,
    RenderMoneda,
    RenderNumero,
} from "Utils/renderField/renderTableField";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { BreakLine, tableOptions } from "Utils/tableOptions";
import DatePicker from "Utils/DatePicker";
import moment from "moment/moment";
import _ from "lodash";

class DetalleVenta extends Component {
    static propTypes = {
        loader: PropTypes.bool.isRequired,
        joyeria: PropTypes.bool.isRequired,
        sucursal_general: PropTypes.object,
        data_general: PropTypes.object.isRequired,
        fecha_inicial_general: PropTypes.instanceOf(Date),
        fecha_final_general: PropTypes.instanceOf(Date),
        estado_descarga: PropTypes.bool,
        conf_tipo_negocio: PropTypes.string,
        setSucursalGeneral: PropTypes.func.isRequired,
        setFechaGeneral: PropTypes.func.isRequired,
        descargarListadoGeneral: PropTypes.func.isRequired,
        listarGeneral: PropTypes.func.isRequired,
        sortChangeGeneral: PropTypes.func.isRequired,
        page_general: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
        };
    }

    onChangeSucursal = (sucursal) => {
        this.props.setSucursalGeneral(sucursal);
    };

    renderContent = (showFilters = true) => {
        const {
            loader,
            sucursal_general,
            data_general,
            fecha_inicial_general,
            fecha_final_general,
            estado_descarga,
            conf_tipo_negocio,
        } = this.props;

        const options = _.cloneDeep(tableOptions);
        options.noDataText = loader ? "Cargando..." : options.noDataText;
        options.page = this.props.page_general;
        options.onPageChange = this.props.listarGeneral;
        options.onSortChange = this.props.sortChangeGeneral;

        return (
            <div>
                {showFilters && (
                    <div className="row mb-2">
                        <div className="col-12">
                            <div className="grid-container">
                                <div className="mb-1 mt-1">
                                    <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                        <h3><b>Historial general de inventario</b></h3>
                                        <div className="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start" style={{ flex: "3" }}>
                                            <div style={{ flex: "0.2" }}>
                                                <label>Fecha Inicio</label>
                                                <DatePicker
                                                    value={fecha_inicial_general}
                                                    maxDate={new Date()}
                                                    onChange={(date) => this.props.setFechaGeneral("Inicial", date)}
                                                />
                                            </div>
                                            <div style={{ flex: "0.2" }}>
                                                <label>Fecha Fin</label>
                                                <DatePicker
                                                    value={fecha_final_general}
                                                    minDate={new Date(moment(fecha_inicial_general, "YYYY-MM-DD"))}
                                                    onChange={(date) => this.props.setFechaGeneral("Final", date)}
                                                />
                                            </div>
                                            <div style={{ flex: "0.2" }}>
                                                <label>Sucursal</label>
                                                <Async
                                                    className="form-control"
                                                    placeholder="Seleccionar sucursal"
                                                    onChange={this.onChangeSucursal}
                                                    searchPromptText="Escriba para buscar"
                                                    valueKey={"id"}
                                                    labelKey={"nombre"}
                                                    loadOptions={search => api.get("sucursales", { search }).then(data => ({ options: data.results }))}
                                                    value={sucursal_general}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {sucursal_general && (
                    <div className="row mb-2">
                        <div className="col-12">
                            <div className="grid-container">
                                <div className="padding-15 d-flex flex-column flex-sm-column mb-1">
                                    <div className="d-flex flex-column flex-md-row flex-lg-row justify-content-between mb-2" style={{ flex: "1" }}>
                                        <h3><b>Listado</b></h3>
                                        <button
                                            className="ml-3 mt-3 mt-md-0 mt-lg-0 btn btn-secondary mb-sm-10 align-self-center"
                                            disabled={estado_descarga}
                                            onClick={this.props.descargarListadoGeneral}
                                        >
                                            <em className={`fa ${estado_descarga ? "fa-spinner fa-pulse" : "fa-download"}`}></em>
                                            &nbsp;Descargar Listado
                                        </button>
                                    </div>

                                    <div className="row d-flex align-items-stretch justify-content-between mt-3 mb-4">
                                        {[
                                            { label: 'Total Entrada', value: data_general.total_entrada, render: RenderNumero },
                                            { label: 'Total Salida', value: data_general.total_salida, render: RenderNumero },
                                            { label: 'Total PC', value: data_general.total_pc, render: RenderMoneda },
                                            { label: 'Total PV', value: data_general.total_pv, render: RenderMoneda },
                                            { label: 'Total PC Sin IVA', value: data_general.total_pc_sin_iva, render: RenderMoneda },
                                            { label: 'Total Importe', value: data_general.total_importe, render: RenderMoneda },
                                            { label: 'Saldo', value: data_general.saldo_inicial, render: RenderNumero }
                                        ].map(({ label, value, render: Render }) => (
                                            <div className="col-6 col-sm-4 col-md-auto px-3 mb-2 flex-grow-1 d-flex justify-content-center align-items-center" key={label}>
                                                <div className="text-right py-1 px-2 h-100" style={{
                                                    backgroundColor: '#FFDB6E',
                                                    borderRadius: '4px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    minWidth: '80px',
                                                    maxWidth: '150px',
                                                }}>
                                                    <div className="small font-weight-normal">{label}</div>
                                                    <div className="font-weight-bold">
                                                        <Render monto={value || 0} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <LoadMask loading={loader} light blur>
                                        <BootstrapTable
                                            data={loader ? [] : data_general.results}
                                            remote
                                            pagination
                                            hover
                                            fetchInfo={{ dataTotalSize: data_general.count }}
                                            options={options}
                                        >
                                            <TableHeaderColumn
                                                dataSort
                                                isKey
                                                dataField="creado"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="center"
                                                dataFormat={(cell) => <RenderDateTime fecha={cell} />}
                                            >
                                                Fecha
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                dataField="fraccion__producto__nombre"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="center"
                                            >
                                                Producto
                                            </TableHeaderColumn>
                                            {conf_tipo_negocio === "Joyeria" && (
                                                <TableHeaderColumn
                                                    dataSort
                                                    dataField="fraccion__producto__kilataje"
                                                    tdStyle={BreakLine}
                                                    thStyle={BreakLine}
                                                    dataAlign="center"
                                                >
                                                    Kilataje
                                                </TableHeaderColumn>
                                            )}
                                            {conf_tipo_negocio === "Joyeria" && (
                                                <TableHeaderColumn
                                                    dataSort
                                                    dataField="fraccion__producto__peso"
                                                    tdStyle={BreakLine}
                                                    thStyle={BreakLine}
                                                    dataAlign="center"
                                                >
                                                    Peso
                                                </TableHeaderColumn>
                                            )}
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="entrada"
                                                dataFormat={(cell, row) => row.a_granel ? cell.toFixed(3) : cell.toFixed(2)}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                Entrada
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="salida"
                                                dataFormat={(cell, row) => row.a_granel ? cell.toFixed(3) : cell.toFixed(2)}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                Salida
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                dataField="motivo"
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="center"
                                            >
                                                Movimiento
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="pc"
                                                dataFormat={cell => <RenderMoneda monto={cell} />}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                PC
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="pv"
                                                dataFormat={cell => <RenderMoneda monto={cell} />}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                PV
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="pc_sin_iva"
                                                dataFormat={cell => <RenderMoneda monto={cell} />}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                PC sin IVA
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="importe"
                                                dataFormat={cell => <RenderMoneda monto={cell} />}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                Importe
                                            </TableHeaderColumn>
                                            <TableHeaderColumn
                                                dataSort
                                                width="100px"
                                                dataField="existencia_final"
                                                dataFormat={(cell, row) => row.a_granel ? cell.toFixed(3) : cell.toFixed(2)}
                                                tdStyle={BreakLine}
                                                thStyle={BreakLine}
                                                dataAlign="right"
                                            >
                                                Saldo
                                            </TableHeaderColumn>
                                        </BootstrapTable>
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    render() {
        if (this.props.conf_tipo_negocio !== "Joyeria") {
            return this.renderContent(true);
        }

        return (
            <div className="row mt">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="row mb-3">
                        <div className="col-lg-7 col-md-6">
                            <Tabs
                                selectedIndex={this.state.tabIndex}
                                onSelect={tabIndex => this.setState({ tabIndex })}
                            >
                                <TabList className="tab_list d-flex">
                                    <Tab
                                        className="content-tab-venta flex-grow-1 text-center fnt-14"
                                        selectedClassName="content-tab-venta-active"
                                    >
                                        HISTORIAL GENERAL
                                    </Tab>
                                    <Tab
                                        className="content-tab-venta flex-grow-1 text-center fnt-14"
                                        selectedClassName="content-tab-venta-active"
                                    >
                                        PESO
                                    </Tab>
                                </TabList>
                            </Tabs>
                        </div>
                        
                        <div className="col-lg-5 col-md-6 d-flex align-items-end justify-content-end">
                            <div className="d-flex align-items-center flex-grow-1">
                                <div className="d-flex">
                                    <button className="btn btn-secondary mr-2">Ayuda</button>
                                    <button className="btn btn-primary">Exportar</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Tabs
                        selectedIndex={this.state.tabIndex}
                        onSelect={tabIndex => this.setState({ tabIndex })}
                    >
                        <TabPanel>{this.renderContent(true)}</TabPanel>
                        <TabPanel>{this.renderContent(false)}</TabPanel>
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default DetalleVenta;
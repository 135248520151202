import { handleActions } from "redux-actions";
import _ from "lodash";
import { push } from "react-router-redux";
import Swal from "sweetalert2";
import { api } from "api";
import uuidv1 from "uuid/v1";
import { ToastStore } from "react-toasts";
import { actions as printActions } from "../print/print";

// ------------------------------------
// Constants
// ------------------------------------

export const PRODUCTOS = "BODEGA_PRODUCTOS";
export const SELECCIONADOS = "BODEGA_SELECCIONADOS";
export const MOVIMIENTO = "BODEGA_MOVIMIENTO";
export const PAGE = "BODEGA_PAGE";
export const BUSCADOR = "BODEGA_BUSCADOR";
export const SORT = "BODEGA_SORT";
export const LOADER = "BODEGA_LOADER";
export const PAGINAS_IMPRESION = "BODEGA_PAGINAS_IMPRESION";
export const UUID_REQ_PRODUCTOS = "VENTA_UUID_REQ_PRODUCTOS";
export const ESTADO_DESCARGA = "BODEGA_ESTADO_DESCARGA";
export const FILTRO = "BODEGA_FILTRO";
export const FAMILIAS = "BODEGA_FAMILIAS";
export const TOTALES = "BODEGA_TOTALES";
export const SET_IMPRESION = "BODEGGA_SET_IMPRESION";
export const DATA_PRINT = "BODEGA_DATA_PRINT";
export const constants = {
    PRODUCTOS,
    SELECCIONADOS,
    MOVIMIENTO,
    PAGE,
    BUSCADOR,
    SORT,
    LOADER,
};

const endpoint = "bodegas";

// ------------------------------------
// Actions
// ------------------------------------

export const listarFamilias = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get("/familia")
        .then((response) => {
            dispatch({ type: FAMILIAS, payload: response.results });
        })
        .catch((error) => {
            console.error("Error cargando familias:", error);
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};


export const listaProductosImpresion = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const producto = store.bodega;
    const { ordering, search, filtro } = producto;

    //  Variables para las paginas
    const cant_por_pagina = 15;
    let paginas = 0;
    let listado_pagina = [];
    api.get(`${endpoint}/lista_productos_all`, { ordering, search, filtro })
        .catch((err) => { })
        .then((data) => {
            if (data) {
                const resultado = data.results;
                const total_productos = resultado.length;
                paginas = Math.ceil(total_productos / cant_por_pagina);

                //  Construccion por pagina
                for (let i = 0; i < paginas; i++) {
                    const pagina_impresion = {
                        no_pagina: i + 1,
                        productos: [],
                    };
                    listado_pagina.push(pagina_impresion);
                }

                //  Asignacion de productos para cada pagina
                resultado.forEach((producto, index) => {
                    const no_pagina = Math.ceil((index + 1) / cant_por_pagina);
                    listado_pagina[no_pagina - 1].productos.push(producto);
                });

                //  Se agrega en redux el listado
                dispatch(
                    setPaginasImpresion({
                        paginas,
                        listado_pagina,
                    })
                );
            }
            dispatch(setLoader(false));
        });
};

export const listar = (page = 1, filtros) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const producto = store.bodega;
    let { ordering } = producto;
    const { search, filtro } = producto;

    // GENERAR EL UUID
    const uuid = uuidv1();
    dispatch(setUuidReqProductos(uuid));
    if (!ordering) {
        ordering = "-creado";
    }
    const params = { page, ordering, search, filtro };
    if (filtros) {
        if (filtros.familia){
            params.familia = filtros.familia;
        }
        else if (filtros.kilataje){
            params.kilataje = filtros.kilataje;
        }
    }
    api.get(endpoint, params)
        .catch((err) => { })
        .then((data) => {
            if (data) {
                const totales = data.results.totales || {};
                dispatch(setTotales(totales));
                data.results = data.results.listado;
                const otroUuid = getStore().bodega.uuid_req_productos;
                if (otroUuid === uuid) dispatch(setProductos(data));
            }
            dispatch(setPage(page));
            dispatch(setLoader(false));
        });
};

export const buscar = (search) => (dispatch) => {
    dispatch(setBuscador(search));
    dispatch(listar());
};

export const sortChange = (sortName, sortOrder) => (dispatch, getStore) => {
    const ordering = sortOrder === 'desc' ? `-${sortName}` : sortName;
    const store = getStore();
    dispatch(setSort(ordering));
    dispatch(listar(store.bodega.page));
};

export const agregar = (producto) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = store.bodega.seleccionados;
    if (_.find(seleccionados, { id: producto.id })) {
        // dispatch(buscar(""));
        dispatch(listar());
    } else {
        let producto_data = _.cloneDeep(producto);
        producto_data.id_unidad_de_medida = producto.producto.a_granel
            ? producto.unidad_de_medida.id
            : null;
        producto_data.costo =
            producto_data.ultimo_costo != "" &&
                producto_data.ultimo_costo != null
                ? producto_data.ultimo_costo
                : 0;
        producto_data.cantidad = 1;

        dispatch(setSeleccionados([producto_data, ...seleccionados]));

        const page = store.bodega.page;
        // dispatch(buscar(""));
        dispatch(listar(page));
    }
};

export const agregarPrimer = (search) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const store = getStore();
    const producto = store.bodega;
    const { ordering } = producto;
    api.get(endpoint, { ordering, search })
        .catch((err) => { })
        .then((data) => {
            if (data) {
                if (data.count > 0) {
                    const seleccionados = store.bodega.seleccionados;
                    const producto = data.results[0];
                    if (!_.find(seleccionados, { id: producto.id })) {
                        // TODO configuracion de ver si agrega o solo filtra
                        dispatch(
                            setSeleccionados([
                                data.results[0],
                                ...seleccionados,
                            ])
                        );
                    }
                }
            }
        })
        .finally(() => {
            dispatch(buscar(""));
            dispatch(setLoader(false));
        });
};

export const quitar = (producto, index) => (dispatch, getStore) => {
    const store = getStore();
    const seleccionados = _.cloneDeep(store.bodega.seleccionados);
    const item = _.find(seleccionados, { id: producto.id });
    if (item) {
        seleccionados.splice(index, 1);
        dispatch(setSeleccionados(seleccionados));
    }
};

export const descargarInventario = () => (dispatch, getStore) => {
    const store = getStore();
    const producto = store.bodega;
    const { ordering, search, filtro } = producto;
    dispatch(setEstadoDescarga(true));
    api.get(`${endpoint}/descargar_excel_existencia_producto`, {
        ordering,
        search,
        filtro,
    })
        .catch((err) => {
            Swal.fire(
                "¡Error al descargar!",
                "Ocurrió un error al descargar el archivo. Por favor intenté más tarde.",
                "error"
            );
            dispatch(setEstadoDescarga(false));
        })
        .then((data) => {
            if (data) {
                Swal.fire(
                    "¡Descarga en proceso!",
                    "La descarga comenzará en un momento. Por favor no recargue la página hasta que se haya descargado el archivo",
                    "info"
                );
                dispatch(setEstadoDescarga(true));
                dispatch(esperarDescarga(data.id));
            }
        });
};

const esperarDescarga = (id) => (dispatch) => {
    let intervalPromise;

    //  VOLVER A HACER LA PETICIÓN PARA VERIFICAR QUE YA TERMINO LA DESCARGA

    function listener() {
        api.get("archivos/estado_descarga", { id })
            .catch((err) => {
                let msg =
                    "Ocurrió un error al descargar el archivo. Por favor intenta más tarde";
                if (err.estado) {
                    msg = err.observaciones;
                }
                dispatch(setEstadoDescarga(false));
                clearInterval(intervalPromise);
                Swal("Error al descargar!", msg, "error");
            })
            .then((resp) => {
                if (resp.estado === 10) {
                    // PROCESANDO
                    dispatch(setEstadoDescarga(true));
                } else if (resp.estado === 20) {
                    // TERMINADO
                    clearInterval(intervalPromise);
                    let nombre = resp.archivo
                        ? resp.archivo.split("/media/archivos/")[1]
                        : "Bajas_salidas_por_producto.xlsx";
                    const context = {
                        name: name,
                        url: resp.archivo,
                    };

                    dispatch(setEstadoDescarga(false));
                    dispatch(descargaArchivo(context));
                }
            });
    }

    listener();
    intervalPromise = setInterval(listener, 1000);
};

const descargaArchivo = (context) => (dispatch) => {
    let elem = document.createElement("a");
    elem.href = context.url;
    elem.download = context.name;
    elem.target = "hiddenIframe";
    elem.click();
    dispatch(setEstadoDescarga(false));
    ToastStore.success("Archivo descargado exitosamente");
};

export const filtroChange = (filtro) => (dispatch) => {
    dispatch(_setFiltroChange(filtro));
    dispatch(listar());
};

export const setVentaImpresion = (venta_impresion) => ({
    type: SET_IMPRESION,
    venta_impresion,
});

export const impresionBodega = () => (dispatch, getStore) => {
    dispatch(setLoader(true));
    dispatch(setVentaImpresion(null));
    const store = getStore();
    const producto = store.bodega;
    const { ordering, search, filtro } = producto;
    
    api.get(endpoint, {todos: true, ordering, search, filtro })
        .catch((err) => { })
        .then((data) => {
            if (data && data.listado) {
                const data_print = data.listado;
                dispatch(setDataPrint(data_print));
            } else {
                dispatch(setDataPrint([]));
            }
        })
        .finally(() => {
            dispatch(setLoader(false));
            dispatch(printActions.print('printBodega'));
        });
};

// ------------------------------------
// PureActions
// ------------------------------------
export const setProductos = (data) => ({
    type: PRODUCTOS,
    data,
});
export const setPage = (page) => ({
    type: PAGE,
    page,
});
export const setBuscador = (search) => ({
    type: BUSCADOR,
    search,
});
export const setSort = (ordering) => ({
    type: SORT,
    ordering,
});
export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});
export const setSeleccionados = (seleccionados) => ({
    type: SELECCIONADOS,
    seleccionados,
});

export const setMovimiento = (movimiento) => ({
    type: MOVIMIENTO,
    movimiento,
});

export const setPaginasImpresion = (paginas_impresion) => ({
    type: PAGINAS_IMPRESION,
    paginas_impresion,
});

export const setUuidReqProductos = (uuid_req_productos) => ({
    type: UUID_REQ_PRODUCTOS,
    uuid_req_productos,
});

const setEstadoDescarga = (estado_descarga) => ({
    type: ESTADO_DESCARGA,
    estado_descarga,
});

export const _setFiltroChange = (filtro) => ({
    type: FILTRO,
    filtro,
});

export const setTotales = (totales) => ({
    type: TOTALES,
    totales,
});
export const setDataPrint = (data_print) => ({
    type: DATA_PRINT,
    data_print,
});

export const actions = {
    listar,
    buscar,
    sortChange,
    agregar,
    quitar,
    filtroChange,
    agregarPrimer,
    setSeleccionados,
    descargarInventario,
    listaProductosImpresion,
    listarFamilias,
    impresionBodega,
    setSort 
};

// ------------------------------------
// Reducers
// ------------------------------------
export const reducers = {
    [PRODUCTOS]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [DATA_PRINT]: (state, { data_print }) => ({
        ...state,
        data_print,
    }),
    [FAMILIAS]: (state, { payload }) => ({
        ...state,
        familias: payload,
      }),
    [SELECCIONADOS]: (state, { seleccionados }) => {
        return {
            ...state,
            seleccionados,
        };
    },
    [MOVIMIENTO]: (state, { movimiento }) => {
        return {
            ...state,
            movimiento,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [BUSCADOR]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [SORT]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [PAGINAS_IMPRESION]: (state, { paginas_impresion }) => {
        return {
            ...state,
            paginas_impresion,
        };
    },
    [UUID_REQ_PRODUCTOS]: (state, { uuid_req_productos }) => {
        return {
            ...state,
            uuid_req_productos,
        };
    },
    [ESTADO_DESCARGA]: (state, { estado_descarga }) => {
        return {
            ...state,
            estado_descarga,
        };
    },
    [FILTRO]: (state, { filtro }) => {
        return {
            ...state,
            filtro,
        };
    },
    [TOTALES]: (state, { totales }) => ({
        ...state,
        totales,
    }),
};

// ------------------------------------
// InitialState
// ------------------------------------

export const initialState = {
    data: {},
    seleccionados: [],
    movimiento: {},
    page: 1,
    search: "",
    ordering: "",
    loader: false,
    paginas_impresion: {
        paginas: 0,
        listado_pagina: [],
    },
    uuid_req_productos: "",
    estado_descarga: false,
    filtro: "todos",
    familias: [],
    totales: {}, 
    data_print: [],
};

export default handleActions(reducers, initialState);

import React from "react";
import { RenderMoneda, RenderNumero, RenderDateTime } from "Utils/renderField/renderTableField";

const MovimientosPDF = (props) => {
  const {
    conf_tipo_negocio,
    conteos_procesados = [],
    fracciones_no_conteadas = []
  } = props;

  const totales = conteos_procesados.reduce((acc, item) => {
    const diferencia = item.cantidad_antes - item.cantidad_fisica;
    const precio = (item.fraccion && item.fraccion.precio !== undefined && item.fraccion.precio !== null)
      ? item.fraccion.precio
      : 0;
    const precioDiferencia = diferencia * precio;
    
    acc.totalDiferencia += diferencia;
    acc.totalPrecioDiferencia += precioDiferencia;
    acc.totalPrecioUnitario += precio;
    
    return acc;
  }, { totalDiferencia: 0, totalPrecioDiferencia: 0, totalPrecioUnitario: 0 });

  const fechaConteo = (conteos_procesados &&
      conteos_procesados[0] &&
      conteos_procesados[0].actualizado_en)
      ? conteos_procesados[0].actualizado_en
      : new Date();

  return (
    <div className="table-responsive p-3">
      <div className="card shadow-sm mb-4">
        <div className="card-header bg-primary text-white">
          <h5 className="card-title mb-0">Reporte de Conteo de Inventario</h5>
        </div>
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-md-6">
              <p className="mb-1">
                <strong>Fecha de conteo:</strong>{" "}
                <RenderDateTime fecha={fechaConteo} />
              </p>
            </div>
            <div className="col-md-6">
              <p className="mb-1">
                <strong>Total productos conteados:</strong> {conteos_procesados.length}
              </p>
            </div>
          </div>

          {/* Tabla de productos conteados */}
          <div className="card shadow-sm mb-4">
            <div className="card-header bg-secondary text-white">
              <h6 className="card-title mb-0">Productos Contados</h6>
            </div>
            <div className="card-body">
              <table className="table table-bordered">
                <thead className="table-info">
                  <tr>
                    <th className="text-wrap">Producto</th>
                    <th className="text-nowrap">Código</th>
                    {conf_tipo_negocio === "Joyeria" && <th>Kilataje</th>}
                    {conf_tipo_negocio === "Joyeria" && <th>Peso</th>}
                    <th>Existencia Sistema</th>
                    <th>Existencia Real</th>
                    <th>Diferencia</th>
                    <th>Precio Unitario</th>
                    <th>Precio Diferencia</th>
                  </tr>
                </thead>
                <tbody>
                  {conteos_procesados.map((item) => {
                    const diferencia = item.cantidad_antes - item.cantidad_fisica;
                    console.log(item)
                    console.log('diferencia: ',diferencia)
                    const precio = (item.fraccion && item.fraccion.precio !== undefined && item.fraccion.precio !== null)
                      ? item.fraccion.precio
                      : 0;
                    const precioDiferencia = diferencia * precio;
                    const productoNombre =
                      item.fraccion && item.fraccion.producto && item.fraccion.producto.nombre
                        ? item.fraccion.producto.nombre
                        : "Sin nombre";
                    const codigoBarras =
                      item.fraccion && item.fraccion.codigo_barras
                        ? item.fraccion.codigo_barras
                        : "---";
                    const kilataje = (conf_tipo_negocio === "Joyeria" &&
                      item.fraccion && item.fraccion.producto && item.fraccion.producto.kilataje)
                        ? item.fraccion.producto.kilataje
                        : "---";
                    const peso = (conf_tipo_negocio === "Joyeria" &&
                      item.fraccion && item.fraccion.producto && item.fraccion.producto.peso)
                        ? item.fraccion.producto.peso
                        : "---";
                    return (
                      <tr key={item.id}>
                        <td className="text-wrap">{productoNombre}</td>
                        <td className="text-nowrap">{codigoBarras}</td>
                        
                        {conf_tipo_negocio === "Joyeria" && (
                          <td className="text-nowrap">{kilataje}</td>
                        )}
                        
                        {conf_tipo_negocio === "Joyeria" && (
                          <td className="text-nowrap">{peso}</td>
                        )}
                        
                        <td><RenderNumero monto={item.cantidad_antes} /></td>
                        <td><RenderNumero monto={item.cantidad_fisica} /></td>
                        <td><RenderNumero monto={diferencia} /></td>
                        <td><RenderMoneda monto={precio} /></td>
                        <td><RenderMoneda monto={precioDiferencia} /></td>
                      </tr>
                    );
                  })}
                </tbody>
                
                <tfoot>
                  <tr className="table-info">
                    <td colSpan={conf_tipo_negocio === "Joyeria" ? 6 : 4} className="text-end">
                      <strong>Totales:</strong>
                    </td>
                    <td><RenderNumero monto={totales.totalDiferencia} /></td>
                    <td><RenderMoneda monto={totales.totalPrecioUnitario} /></td>
                    <td><RenderMoneda monto={totales.totalPrecioDiferencia} /></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>

          {/* Sección de productos no conteados */}
          {fracciones_no_conteadas.length > 0 && (
            <div className="card shadow-sm mt-4">
              <div className="card-header bg-warning text-dark">
                <h6 className="card-title mb-0">
                  Productos No Contados ({fracciones_no_conteadas.length})
                </h6>
              </div>
              <div className="card-body">
                <table className="table table-bordered">
                  <thead className="table-info">
                    <tr>
                      <th className="text-wrap">Producto</th>
                      <th>Código</th>
                      {conf_tipo_negocio === "Joyeria" && <th>Kilataje</th>}
                      {conf_tipo_negocio === "Joyeria" && <th>Peso</th>}
                      <th>Existencia Sistema</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fracciones_no_conteadas.map((fraccion) => (
                      <tr key={fraccion.id}>
                        <td className="text-wrap">
                          {(fraccion.producto && fraccion.producto.nombre)
                            ? fraccion.producto.nombre
                            : "Sin nombre"}
                        </td>
                        <td>{fraccion.codigo_barras ? fraccion.codigo_barras : "---"}</td>
                        
                        {conf_tipo_negocio === "Joyeria" && (
                          <td>
                            {(fraccion.producto && fraccion.producto.kilataje)
                              ? fraccion.producto.kilataje
                              : "---"}
                          </td>
                        )}
                        
                        {conf_tipo_negocio === "Joyeria" && (
                          <td>
                            {(fraccion.producto && fraccion.producto.peso)
                              ? fraccion.producto.peso
                              : "---"}
                          </td>
                        )}
                        
                        <td>
                          <RenderNumero monto={fraccion.total_inventario ? fraccion.total_inventario : 0} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MovimientosPDF;